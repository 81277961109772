import React from 'react'
import clsx from 'clsx'
import { makeStyles, CircularProgress, Fade } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  dimmed: {
    backgroundColor: 'rgba(100, 100, 100, 0.4)'
  },
  overlay: {
    position: 'absolute',
    zIndex: 1000,
    top: 75,
    height: '100%',
    width: '100%'
  },
  progress: {
    position: 'absolute',
    top: 'calc(50% - 75px)',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  }
}))
const LoadingOverlay = ({ loading }) => {
  const classes = useStyles()
  return (
    <Fade in={loading}>
      <div className={clsx(classes.dimmed, classes.overlay)}>
        <div className={classes.progress}>
          <CircularProgress />
        </div>
      </div>
    </Fade>
  )
}

export default LoadingOverlay
