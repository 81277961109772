import _ from 'lodash'
import DISALLOWED_ROUTES from './allowed'
import PATTERNS from './pattern'

const isAllowed = (role, pathname) => {
  if (!_.has(DISALLOWED_ROUTES, role)) {
    throw new Error('Invalid user role ' + role)
  }
  const patternsToTest = _.values(_.omit(PATTERNS, DISALLOWED_ROUTES[role]))
  return _.reduce(
    patternsToTest,
    (result, pattern) => {
      return pattern.test(pathname) || result
    },
    false
  )
}

export default isAllowed
