import React from 'react'
import Autocomplete, {
  createFilterOptions
} from '@material-ui/lab/Autocomplete'
import { TextField } from '@material-ui/core'
import Chip from '@material-ui/core/Chip'
import _ from 'lodash'

const TagInput = ({
  value = [],
  onChange,
  options = [],
  getOptionLabel = (a) => a,
  label = 'Select tag',
  error,
  className,
  fullWidth,
  helperText,
  multiple = true,
  filterSelectedOptions = false,
  renderTags,
  ...rest
}) => {
  const newGetOptionLabel = (val) => getOptionLabel(val) || ''
  const filterOptions = createFilterOptions({
    matchFrom: 'start',
    stringify: newGetOptionLabel
  })
  if (!renderTags) {
    renderTags = (value, getTagProps) =>
      value.map((option, index) => (
        <Chip
          key={index}
          label={newGetOptionLabel(option)}
          {...getTagProps({ index })}
        />
      ))
  }
  return (
    <div className={className}>
      <Autocomplete
        multiple={multiple}
        variant="outlined"
        value={value}
        onChange={(event, value) => onChange(value)}
        options={options}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            variant="outlined"
            helperText={helperText}
            error={error}
          />
        )}
        filterSelectedOptions={filterSelectedOptions}
        filterOptions={(option, state) => {
          let filtered = filterOptions(option, state)
          if (filterSelectedOptions) {
            filtered = _.differenceBy(filtered, value, newGetOptionLabel)
          }
          return filtered
        }}
        getOptionLabel={newGetOptionLabel}
        renderTags={renderTags}
        {...rest}
      />
    </div>
  )
}

export default TagInput
