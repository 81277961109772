import React from 'react'
import { makeStyles, Tooltip, IconButton, Typography } from '@material-ui/core'
import { Link } from 'react-router-dom'
import AddIcon from '@material-ui/icons/Add'
import EditIcon from '@material-ui/icons/Edit'
import UserContainer from '@/containers/UserContainer'
const useStyles = makeStyles((theme) => ({
  itemRoot: {
    padding: theme.spacing(1),
    display: 'flex',
    alignItems: 'center'
  },
  title: {
    flexGrow: 10
  }
}))

const Item = ({ category: { name, id }, onClick, totalCount }) => {
  const classes = useStyles()
  return (
    <div className={classes.itemRoot} onClick={onClick}>
      <Typography className={classes.title} variant="h4">
        {name}
      </Typography>
      {UserContainer.isAllowed('/product-categories/update') && (
        <div>
          <Tooltip title="Update">
            <IconButton
              component={Link}
              to={`/product-categories/update/${id}`}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Add a subcategory. You can only create at most 100 categories">
            <span>
              <IconButton
                component={Link}
                disabled={totalCount >= 100}
                to={`/product-categories/create?parent=${id}`}
              >
                <AddIcon />
              </IconButton>
            </span>
          </Tooltip>
        </div>
      )}
    </div>
  )
}

export default Item
