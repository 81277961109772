import requester from '../requester'
import { pullErrorMessage } from '../error'

export const getAllTags = async ({ page, sort, limit = 10 } = { page: 0 }) => {
  const offset = page * limit
  const config = { params: { _start: offset, _limit: limit, _sort: sort } }
  const { data } = await requester()
    .get('post-tags/', config)
    .catch(pullErrorMessage)
  return data
}

export const searchTagsContainName = async ({ name }) => {
  if (!name) {
    name = null
  }
  const body = { _limit: 50, name_contains: name }
  const { data } = await requester()
    .get('post-tags/', { params: body })
    .catch(pullErrorMessage)
  return data
}

export const countPostTag = async ({ name }) => {
  if (!name) {
    name = null
  }
  const body = { name_contains: name }
  const { data } = await requester()
    .get('post-tags/count', { params: body })
    .catch(pullErrorMessage)
  return data
}

export const getPostTagBySlug = async (slug) => {
  const { data } = await requester()
    .get(`post-tags/${slug}`)
    .catch(pullErrorMessage)
  return data
}

export const updatePostTag = async (data) => {
  const { id } = data
  await requester().put(`post-tags/${id}`, data).catch(pullErrorMessage)
}

export const createPostTag = async (postTagData) => {
  const { data } = await requester()
    .post('post-tags/', postTagData)
    .catch(pullErrorMessage)
  return data
}

export const deletePostTag = async (id) => {
  const { data } = requester()
    .delete(`post-tags/${id}`)
    .catch(pullErrorMessage)
  return data
}
