import SnackbarContainer from '../../containers/SnackbarContainer'
import React from 'react'
import Snackbar from '@material-ui/core/Snackbar'
import { Subscribe } from 'unstated'
import SnackbarContentWrapper from './SnackbarContent'

const SnackbarComponent = () => {
  return (
    <Subscribe to={[SnackbarContainer]}>
      {(snackbarState) => (
        <Snackbar
          open={!!snackbarState.state.message}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        >
          {snackbarState.state.message ? (
            <SnackbarContentWrapper
              message={snackbarState.state.message}
              severity={snackbarState.state.severity}
              onClose={snackbarState.close}
            />
          ) : null}
        </Snackbar>
      )}
    </Subscribe>
  )
}
export default SnackbarComponent
