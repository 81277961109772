import { formatCurrency } from '@/helpers'

export const columns = [
  {
    name: 'id',
    label: 'ID',
    options: {
      filter: false,
      sort: true
    }
  },
  {
    name: 'name',
    label: 'Name',
    options: {
      filter: false,
      sort: true
    }
  },
  {
    name: 'price',
    label: 'Price',
    options: {
      filter: false,
      sort: true
    }
  },
  {
    name: 'brand',
    label: 'Brand',
    options: {
      filter: true,
      sort: true
    }
  }
]

export function productToRow (data) {
  return [
    data.id,
    data.name,
    formatCurrency(data.price),
    data.brand ? data.brand.name : 'None'
  ]
}
