import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import { useParams, useHistory } from 'react-router-dom'
import { PostTagDetail } from './components'
import { withSnackbar } from '@/containers/SnackbarContainer'
import { NotFoundError, ValidateError } from '@/apis/error'
import api from '@/apis'
import ConfirmModal from '@/components/ConfirmModal'
import slugify from 'slugify'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4)
  }
}))

const PostTagSingle = ({ snackbar }) => {
  const classes = useStyles()
  const history = useHistory()
  const { slug, mode } = useParams()

  const [postTag, setPostTag] = useState({
    name: '',
    slug: ''
  })
  const [error, setError] = useState({ field: '', message: '' })
  const [loading, setLoading] = useState(true)
  const [autoSlug, setAutoSlug] = useState(true)
  const [showModal, setShowModal] = useState(false)

  const updateField = (field) => (value) => {
    const res = { ...postTag, [field]: value }
    if (autoSlug && field === 'name') {
      res.slug = slugify(value.toLowerCase())
    }
    setPostTag(res)
    if (error.message !== '') {
      setError({
        message: '',
        field: ''
      })
    }
  }

  const toggleAutoSlug = () => {
    if (!autoSlug) {
      updateField('slug')(slugify(postTag.name.toLowerCase()))
    }
    setAutoSlug(!autoSlug)
  }

  const handleSubmit = async () => {
    if (mode === 'update') {
      return handleUpdate()
    }
    return handleCreate()
  }

  const handleCreate = async () => {
    try {
      api.postTag.validate.validatePostTagData(postTag)
      await api.postTag.http.createPostTag(postTag)
      await snackbar.showMessage('Created new post tag', 'success')
      // history.push(`/post-tags/update/${res.slug}`);
      history.replace('/post-tags')
    } catch (err) {
      if (err instanceof ValidateError) {
        setError(err)
        return snackbar.showMessage(err.message, 'error')
      }
      throw err
    }
  }

  const handleUpdate = async () => {
    try {
      api.postTag.validate.validatePostTagData(postTag)
      await api.postTag.http.updatePostTag(postTag)
      await snackbar.showMessage('Updated post tag', 'success')
      history.replace('/post-tags')
      // history.replace(`/post-tags/update/${postTag.slug}`);
    } catch (err) {
      if (err instanceof ValidateError) {
        setError(err)
        await snackbar.showMessage(err.message, 'error')
      } else if (err instanceof NotFoundError) {
        await snackbar.showMessage("That post tag doesn't exist", 'error')
      } else {
        throw err
      }
    }
  }

  const handleDelete = async () => {
    try {
      await api.postTag.http.deletePostTag(postTag.id)
      await snackbar.showMessage('Deleted post tag', 'success')
    } catch (err) {
      if (err instanceof NotFoundError) {
        await snackbar.showMessage("That post tag doesn't exist", 'error')
      } else {
        throw err
      }
    } finally {
      history.replace('/post-tags')
    }
  }

  useEffect(() => {
    if (mode === 'create') {
      setLoading(false)
      return
    }
    api.postTag.http
      .getPostTagBySlug(slug)
      .then((data) => {
        setPostTag(data)
        if (slugify(data.name.toLowerCase()) !== data.slug) {
          setAutoSlug(false)
        }
        setLoading(false)
      })
      .catch((err) => {
        if (err instanceof NotFoundError) {
          history.replace('/not-found')
          return snackbar.showMessage("There's no such post tag", 'error')
        }
        throw err
      })
  }, [slug, mode, history, snackbar])

  return (
    <div className={classes.root}>
      <ConfirmModal
        show={showModal}
        handleClose={() => setShowModal(false)}
        handleConfirm={handleDelete}
        message="Are you sure you want to delete this post tag?"
      />
      <PostTagDetail
        mode={mode}
        loading={loading}
        postTag={postTag}
        handleSubmit={handleSubmit}
        handleDelete={() => setShowModal(true)}
        updateField={updateField}
        autoSlug={autoSlug}
        toggleAutoSlug={toggleAutoSlug}
        error={error}
      />
    </div>
  )
}

export default withSnackbar(PostTagSingle)
