import React from 'react'
import { Grid, Card, TextField, makeStyles } from '@material-ui/core'
import ImagePicker from '@/components/ImagePicker/ImagePicker'
import { createErrorProp } from '@/helpers'

const eventToValue = (callback) => (event) => callback(event.target.value)

const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(2)
  },
  image: {
    margin: theme.spacing(2, 0)
  }
}))

const SingleBanner = ({
  setBanner,
  banner,
  loading,
  error,
  className,
  imageClassName
}) => {
  const classes = useStyles()
  const updateField = (field) => (value) => {
    setBanner({ ...banner, [field]: value })
  }
  return (
    <Card className={classes.card + ' ' + className} elevation={3}>
      <Grid container spacing={1} direction="column">
        <Grid item xs={12}>
          <TextField
            disabled={loading}
            fullWidth
            label="Title"
            name="title"
            onChange={eventToValue(updateField('title'))}
            value={banner.title}
            variant="outlined"
            {...createErrorProp(error, ['title'], 'Input the banner title')}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            disabled={loading}
            fullWidth
            label="Subtitle"
            name="subtitle"
            onChange={eventToValue(updateField('subtitle'))}
            value={banner.subtitle}
            variant="outlined"
            {...createErrorProp(
              error,
              ['subtitle'],
              'Input the banner subtitle'
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            disabled={loading}
            fullWidth
            label="URL"
            name="url"
            onChange={eventToValue(updateField('url'))}
            value={banner.url}
            variant="outlined"
            {...createErrorProp(error, ['url'], 'Input the banner url')}
          />
        </Grid>
        <Grid item xs={12}>
          <ImagePicker
            loading={loading}
            paperProps={{ elevation: 5 }}
            setImage={updateField('image')}
            title="Select an image for this banner"
            className={classes.image + ' ' + imageClassName}
            image={banner.image}
          />
        </Grid>
      </Grid>
    </Card>
  )
}

export default SingleBanner
