import React from 'react'
import ImagePicker from '@/components/ImagePicker/ImagePicker'
import { Grid, Typography, makeStyles } from '@material-ui/core'
const useStyles = makeStyles((theme) => ({
  title: {
    margin: theme.spacing(2)
  },
  topBanner: {
    height: 200
  }
}))
const TopBanner = ({ setBanner, banner, loading }) => {
  const classes = useStyles()
  return (
    <Grid item xs={12}>
      <Typography align="center" variant="h1" className={classes.title}>
        Top Banner
      </Typography>
      <ImagePicker
        className={classes.topBanner}
        setImage={setBanner}
        image={banner}
        loading={loading}
        title="Set top banner"
      />
    </Grid>
  )
}

export default TopBanner
