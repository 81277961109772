import React from 'react'
import { useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core'
import MUIDataTable from 'mui-datatables'

const columns = [
  {
    name: 'id',
    label: 'ID',
    options: {
      filter: false,
      sort: true
    }
  },
  {
    name: 'name',
    label: 'Name',
    options: {
      filter: false,
      sort: true
    }
  },
  {
    name: 'username',
    label: 'Username',
    options: {
      filter: false,
      sort: true
    }
  },
  {
    name: 'Roles',
    label: 'Roles',
    options: {
      filter: true,
      sort: true
    }
  }
]

const useStyles = makeStyles({
  row: {
    cursor: 'pointer'
  }
})

const UserTable = ({ users }) => {
  const history = useHistory()
  const classes = useStyles()
  const options = {
    download: false,
    print: false,
    viewColumns: false,
    onRowClick: (rowData) => {
      return history.push('/users/' + rowData[0])
    },
    setRowProps: () => {
      return {
        className: classes.row
      }
    },
    selectableRows: 'none',
    selectableRowsHeader: false
  }
  return (
    <MUIDataTable
      title={'Users list'}
      data={users}
      columns={columns}
      options={options}
    />
  )
}

export default UserTable
