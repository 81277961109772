import React, { useState, useEffect } from 'react'
import { Paper, makeStyles } from '@material-ui/core'
import api from '@/apis'
import _ from 'lodash'
import TreeView from '@material-ui/lab/TreeView'
import parseCategory from './parseCategory'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import CategoryEntry from '../CategoryEntry'

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2)
  }
}))

const CategoryTree = ({ count }) => {
  const classes = useStyles()
  const [categories, setCategories] = useState([])
  const [ids, setIds] = useState([])
  useEffect(() => {
    api.productCategory.http.getAllProductCategories().then((categories) => {
      setIds(categories.map((x) => `${x.id}`))
      setCategories(parseCategory(categories))
    })
  }, [])

  const toggleExpanded = (id) => () => {
    id = String(id)
    if (_.includes(ids, id)) {
      const s = _.without(ids, id)
      setIds(s)
    } else {
      setIds([...ids, id])
    }
  }

  return (
    <Paper className={classes.paper}>
      <TreeView
        defaultCollapseIcon={<ExpandMoreIcon />}
        expanded={ids}
        onNodeToggle={(event, value) => setIds(value)}
        defaultExpandIcon={<ChevronRightIcon />}
      >
        {categories.map((category) => (
          <CategoryEntry
            totalCount={count}
            category={category}
            key={category.id}
            toggleExpanded={toggleExpanded}
          />
        ))}
      </TreeView>
    </Paper>
  )
}

export default CategoryTree
