import _ from 'lodash'

const controls = [
  ['undo', 'redo', 'separator'],
  ['font-size', 'line-height', 'letter-spacing', 'separator'],
  ['text-color', 'bold', 'italic', 'underline', 'strike-through', 'separator'],
  ['superscript', 'subscript', 'remove-styles', 'emoji', 'separator'],
  ['text-indent', 'text-align', 'separator'],
  ['headings', 'list-ul', 'list-ol', 'blockquote', 'code', 'separator'],
  ['link', 'separator', 'hr', 'separator'],
  ['media', 'separator'],
  ['table', 'separator'],
  ['clear']
].reduce((all, c) => _.concat(all, c), [])

export const minimalControls = [
  ['text-color', 'bold', 'italic', 'underline']
].reduce((all, c) => _.concat(all, c), [])

export default controls
