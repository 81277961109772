import React from 'react'
import SingleBanner from '../SingleBanner'
import { Typography, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  promotion: {
    height: '30vw'
  },
  title: {
    margin: theme.spacing(3)
  }
}))

const Promotion = ({ loading, banner, setBanner }) => {
  const classes = useStyles()
  return (
    <>
      <Typography variant="h1" className={classes.title} align="center">
        Promotion
      </Typography>
      <SingleBanner
        banner={banner}
        setBanner={setBanner}
        imageClassName={classes.promotion}
        loading={loading}
      />
    </>
  )
}

export default Promotion
