import { parseTime, trimText } from "@/helpers";

export const columns = [
  {
    name: "id",
    label: "ID",
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: "name",
    label: "Main name",
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: "product",
    label: "ID Product",
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: "description",
    label: "Description",
    options: {
      filter: false,
      sort: false,
    },
  },
  {
    name: "rating",
    label: "Rating",
    options: {
      filter: true,
      sort: true,
    },
  },
  {
    name: "created_at",
    label: "Created At",
    options: {
      filter: false,
      sort: true,
    },
  },
  {
    name: "updated_at",
    label: "Updated at",
    options: {
      filter: false,
      sort: true,
    },
  },
];
export function ingredientToRow(data) {
  return [
    data.id,
    data.names?.[0]?.name,
    data.product,
    trimText(data.description),
    data.rating,
    parseTime(data.created_at),
    parseTime(data.updated_at),
  ];
}
