import { ValidateError } from '@/apis/error'

export const createErrorProp = (error, fields, normalHelperText = '') => {
  fields = [...fields, 'all']
  if (!error || !(error instanceof ValidateError)) {
    return {
      helperText: normalHelperText,
      error: false
    }
  }
  const isError = fields.includes(error.field)
  return {
    error: isError,
    helperText: isError ? error.message : normalHelperText
  }
}
