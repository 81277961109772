import React from 'react'
import { makeStyles } from '@material-ui/styles'

import { PostTagToolbar, postTagToRow } from './components'

import api from '@/apis'
import { useHistory } from 'react-router-dom'
import DataTable from '@/components/DataTable/DataTable'
import { columns } from '@/views/PostTag/PostTagList/components'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}))

const PostTagList = () => {
  const classes = useStyles()
  const history = useHistory()

  const options = {
    download: false,
    print: false,
    viewColumns: false,
    onRowClick: (rowData) => {
      return history.push('/post-tags/update/' + rowData[2])
    },
    selectableRows: 'none',
    selectableRowsHeader: false
  }

  const handleCount = async ({ query }) => {
    return api.postTag.http.countPostTag({ name: query })
  }

  const handleSearch = async ({ query }) => {
    return api.postTag.http.searchTagsContainName({ name: query })
  }

  return (
    <div className={classes.root}>
      <PostTagToolbar />
      <div className={classes.content}>
        <DataTable
          columns={columns}
          options={options}
          title="Post Tags"
          handleCount={handleCount}
          handleFetchPage={api.postTag.http.getAllTags}
          elementToRow={postTagToRow}
          handleSearch={handleSearch}
        />
      </div>
    </div>
  )
}

export default PostTagList
