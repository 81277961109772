import React from "react";
import { useHistory } from "react-router-dom";
import api from "@/apis";
import { ingredientToRow, columns } from "./tableFormat";
import DataTable from "@/components/DataTable/DataTable";

const IngredientTable = () => {
  const history = useHistory();

  const onRowClick = (rowData) => {
    if (rowData) {
      return history.push("/ingredient/update/" + rowData[0]);
    }
  };

  const options = {
    onRowClick,
    filter: false,
    download: false,
    print: false,
    viewColumns: true,
  };
  const handleSearch = async ({ query, limit, page }) => {
    const names = query.split(";");
    return api.ingredient.http.searchIngredientsByNames({
      names,
      limit,
      page,
    });
  };
  const handleCount = async ({ query = "" } = {}) => {
    if (!query) {
      return api.ingredient.http.countIngredients();
    }
    const names = query.split(";");
    return api.ingredient.http.countIngredients(null, {
      names,
    });
  };
  return (
    <DataTable
      title="Ingredient"
      onRowClick={onRowClick}
      columns={columns}
      handleFetchPage={api.ingredient.http.getIngredients}
      handleCount={handleCount}
      handleSearch={handleSearch}
      elementToRow={ingredientToRow}
      options={options}
    />
  );
};

export default IngredientTable;
