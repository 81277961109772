import Paper from '@material-ui/core/Paper'
import React from 'react'
import FileList from './FileList/'
import Preview from './Preview'
import { makeStyles } from '@material-ui/styles'
import { CircularProgress } from '@material-ui/core'

const useStyle = makeStyles(() => ({
  preview: {
    minHeight: 100
  },
  loading: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)'
  },
  container: {
    position: 'relative'
  }
}))

const ImagePicker = ({
  className,
  title,
  image,
  setImage,
  alt,
  loading,
  paperProps,
  titleSize,
  fileSizeLimitInMB = 1.5
}) => {
  const classes = useStyle()

  if (loading) {
    return (
      <Paper className={className + ' ' + classes.container} {...paperProps}>
        <div className={classes.loading}>
          <CircularProgress />
        </div>
      </Paper>
    )
  }
  return image ? (
    <Preview
      image={image}
      alt={alt}
      className={className + ' ' + classes.preview + ' ' + classes.container}
      setImage={setImage}
      paperProps={paperProps}
    />
  ) : (
    <FileList
      fileSizeLimitInMB={fileSizeLimitInMB}
      title={title}
      titleSize={titleSize}
      setImage={setImage}
      className={className + ' ' + classes.container}
      paperProps={paperProps}
    />
  )
}

export default ImagePicker
