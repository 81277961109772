import React, { useState, useMemo, useEffect } from 'react'
import TextField from '@material-ui/core/TextField'
import CircularProgress from '@material-ui/core/CircularProgress'
import Autocomplete from '@material-ui/lab/Autocomplete'
import _ from 'lodash'
const identity = (a) => a
export default function ServerSideTagInput ({
  value,
  error,
  helperText,
  className,
  multiple = false,
  label,
  getOptionLabel = identity,
  onChange,
  idField = 'id',
  fetchOptions = async () => ['123', '456']
}) {
  const [options, setOptions] = useState([])
  const [input, setInput] = useState('')
  const [loading, setLoading] = useState(false)

  const fetchNewOptions = useMemo(
    () =>
      _.debounce((input) => {
        fetchOptions({ name: input })
          .then(setOptions)
          .then(() => setLoading(false))
      }, 1000),
    [setOptions, setLoading]
  )

  useEffect(() => {
    fetchNewOptions()
  }, [fetchNewOptions])

  const handleInputChange = (value) => {
    setInput(value)
    setLoading(true)
    fetchNewOptions(value)
  }
  const filterExistingOptions = (options) => {
    const existingIds = value.map((value) => value[idField])
    options = _.filter(
      options,
      (option) => !existingIds.includes(option[idField])
    )
    return options
  }
  return (
    <div className={className}>
      <Autocomplete
        value={value}
        multiple={multiple}
        options={options}
        onChange={(event, value) => onChange(value)}
        onInputChange={(event, value) => handleInputChange(value)}
        getOptionLabel={(option) => {
          return getOptionLabel(option) || ''
        }}
        filterOptions={multiple ? filterExistingOptions : identity}
        inputValue={input}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            variant="outlined"
            fullWidth
            helperText={helperText}
            error={error}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              )
            }}
          />
        )}
      />
    </div>
  )
}
