export const ROLES = {
  owner: 'owner',
  moderator: 'moderator',
  content_maker: 'content_maker',
  collaborator: 'collaborator',
  chemist: 'chemist',
  product_manager: 'product_manager'
}

export default ROLES
