import { ValidateError } from '../error'

export const validateNewUser = (userData) => {
  if (!userData.name) {
    throw new ValidateError('No name', 'name')
  }
  if (!userData.username) {
    throw new ValidateError('No username', 'username')
  }
  if (!userData.password || userData.password.length < 8) {
    throw new ValidateError(
      'Password must be at least 8 characters long',
      'password'
    )
  }
  if (!userData.role) {
    throw new ValidateError('No role specified', 'role')
  }
}

export const validateUpdateUser = (userData) => {
  if (!userData.username) {
    throw new ValidateError('No username', 'username')
  }
  if (userData.password && userData.password !== userData.passwordConfirm) {
    throw new ValidateError(
      "Password and password confirm doesn't match",
      'password'
    )
  }
  if (userData.password && userData.password.length < 8) {
    throw new ValidateError(
      'Password must be at least 8 characters long',
      'password'
    )
  }
  if (!userData.name) {
    throw new ValidateError('No name', 'name')
  }
}

export const catchNameTakenError = (err) => {
  if (err.message === 'Auth.form.error.username.taken') {
    throw new ValidateError('Username already taken', 'username')
  }
  throw err
}
