import { ValidateError } from '../error'
import { isURL } from '@/apis/utils'

export const validateProductData = (data) => {
  if (!data.name) {
    throw new ValidateError('Specify a name for this product', 'name')
  }
  if (!data.price) {
    throw new ValidateError('Specify a price for this product', 'name')
  }
  if (!data?.url?.length) {
    throw new ValidateError('Specify at least one URL for this product', 'url')
  }
  data.url.forEach((url, idx) => {
    if (!url) {
      throw new ValidateError('This URL is empty', `url[${idx}]`)
    }
    if (!isURL(url)) {
      throw new ValidateError('This URL is invalid', `url[${idx}]`)
    }
  })
}
