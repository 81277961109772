import ROUTES from "./routes";

const buildSubRoutesMatch = (subRoutes) => {
  if (subRoutes.length === 0) {
    return "";
  }
  // (/subroute1|/subroute2|/subroute3.....)
  return `(${subRoutes.map((route) => "\\/" + route).join("|")})`;
};

const buildRouteRegex = (routeName, subRoutes = [], useSlug = false) => {
  // If id === true, only allow numeric id, else allow alphanumeric slug
  let regexString;
  if (!useSlug) {
    regexString = `^\\/${routeName}${buildSubRoutesMatch(
      subRoutes
    )}(\\/\\d*)?$`;
  } else {
    regexString = `^\\/${routeName}${buildSubRoutesMatch(
      subRoutes
    )}(\\/[\\w\\d_-]*)?$`;
  }
  return new RegExp(regexString);
};

const PATTERNS = {
  [ROUTES.main]: /^\/(not-found|home|unauthorized|notifications)?$/,
  [ROUTES.user]: buildRouteRegex("users"),

  [ROUTES.postList]: buildRouteRegex("posts"),
  [ROUTES.postEdit]: buildRouteRegex("posts", ["update", "create"], true),

  [ROUTES.postTagList]: buildRouteRegex("post-tags"),
  [ROUTES.postTagEdit]: buildRouteRegex(
    "post-tags",
    ["create", "update"],
    true
  ),

  [ROUTES.ingredientList]: buildRouteRegex("ingredients"),
  [ROUTES.IngredientListPending]: buildRouteRegex("ingredientPending"),
  [ROUTES.ingredientEdit]: buildRouteRegex("ingredient", ["update", "create"]),

  [ROUTES.ingredientTagList]: buildRouteRegex("ingredient-tags"),
  [ROUTES.ingredientTagEdit]: buildRouteRegex(
    "ingredient-tags",
    ["update", "create"],
    true
  ),

  [ROUTES.productList]: buildRouteRegex("products"),
  [ROUTES.productEdit]: buildRouteRegex("products", ["update", "create"]),

  [ROUTES.productCategoryList]: buildRouteRegex("product-categories"),
  [ROUTES.productCategoryEdit]: buildRouteRegex("product-categories", [
    "create",
    "update",
  ]),

  [ROUTES.brandList]: buildRouteRegex("brands"),
  [ROUTES.brandEdit]: buildRouteRegex("brands", ["update", "create"]),

  [ROUTES.bannerList]: buildRouteRegex("banners"),
  [ROUTES.bannerEdit]: buildRouteRegex("banners", ["update", "create"]),

  [ROUTES.layout]: /^\/layouts\/(home|other|insta)$/,
};

export default PATTERNS;
