import React, { useState } from 'react'
import {
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  TextField,
  AppBar,
  Tabs,
  Tab,
  Box,
  RootRef
} from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import Autocomplete, {
  createFilterOptions
} from '@material-ui/lab/Autocomplete'
import throttle from 'lodash/throttle'
import axios from 'axios'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import requester from '@/apis/requester'
import IngredientInput from './IngredientInput'

const filter = createFilterOptions()

function TabPanel (props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
}

function a11yProps (index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper
  }
}))

export default function SimpleTabs ({ ingredients, onChange,productId }) {

  const classes = useStyles()
  const [value, setValue] = React.useState(0)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }
  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Tabs value={value} onChange={handleChange} variant="fullWidth">
          <Tab label="Edit Ingredients" {...a11yProps(0)} />
          <Tab label="Sort Ingredients" {...a11yProps(1)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <IngredientList ingredients={ingredients} onChange={onChange} productId={productId} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <SortIngredients ingredients={ingredients} onChange={onChange} />
      </TabPanel>
    </div>
  )
}

function IngredientList ({ ingredients, onChange, productId }) {
  const [onIndexEdit, setOnIndexEdit] = useState(-1)
  const [options, setOptions] = useState([])
  const [inputValue, setInputValue] = useState('')
  // eslint-disable-next-line
  const [value, setValue] = React.useState(null);
  // eslint-disable-next-line
  const [isDefault, setDefault] = useState(false);
  const onClickEditBtn = (index) => {
    if (index === onIndexEdit) {
      setOnIndexEdit(-1)
      return
    }
    setOnIndexEdit(index)
    // Format data
    const options = []
    const names = ingredients[index].names
    if (_.isArray(names)) {
      _.forEach(names, (ing) => {
        options.push({
          data: ingredients[index],
          name: ing?.name || ''
        })
      })
    }
    setDefault(true)
    setOptions(options)
  }

  const fetch = React.useMemo(
    () =>
      throttle((url) => {
        return axios.get(url)
      }, 100),
    []
  )

  React.useEffect(() => {
    let active = true

    if (inputValue === '') {
      setOptions(value ? [value] : [])
      return undefined
    }
    fetch(
      'https://callmeduy.com/_api/chemicals?_limit=-1&' +
        inputValue
          .split(' ')
          .map((v, i) => {
            if (i === 0) {
              return v !== '' ? `names.name_contains=${v}` : ''
            }
            if (i > 0) {
              return v.length > 2 ? `names.name_contains=${v}` : ''
            }
          })
          .join('&')
    ).then((res) => {
      if (active) {
        const options = []
        res.data.forEach((v) => {
          let disable = false
          if (_.find(ingredients, { id: v.id })) disable = true
          const names = v.names
          if (_.isArray(names)) {
            _.forEach(names, (ing) => {
              options.push({
                name: ing?.name || '',
                data: v,
                disable
              })
            })
          }
        })
        setOptions(options)
      }
    })
    return () => {
      active = false
    }
  }, [value, inputValue, fetch])

  const onChangeHandle = async (index, newValue) => {
    if (_.isNull(newValue)) return
    if (isDefault) setDefault(false)
    const newIngs = [...ingredients]
    if (newValue && newValue.inputValue) {
      const result = (
        await requester().post('chemicals', {
          names: [newValue.inputValue],
          rating: 3,
          tags: [
            {
              id: 144
            }
          ],
          // product:{
          //   // id: productId
          // }
        })
      ).data
      newIngs[index] = result
      setOptions([...options, result])
    } else {
      newIngs[index] = { ...newValue.data }
    }
    onChange(newIngs)
  }
  const onClickDeleteBtn = (index) => {
    setOnIndexEdit(-1)
    const res = []
    if (_.isArray(ingredients)) {
      ingredients.forEach((v, i) => {
        if (i !== index) return res.push(v)
      })
    }

    onChange(res)
  }
  return (
    ingredients && (
      <>
        <List>
          {ingredients.map((item, index) => (
            <ListItem key={index}>
              {onIndexEdit === index ? (
                <Autocomplete
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params)
                    if (
                      params.inputValue !== '' &&
                      _.find(
                        options,
                        (o) =>
                          o.name.toLowerCase() ===
                          params.inputValue.toLowerCase()
                      ) === undefined
                    ) {
                      filtered.push({
                        name: `Add "${params.inputValue}"`,
                        inputValue: params.inputValue
                      })
                    }
                    return filtered
                  }}
                  style={{ width: 300 }}
                  defaultValue={isDefault && options[0]}
                  getOptionLabel={(option) => {
                    return `${option?.data?.id || ""} ${option.name}`
                  }}
                  options={options}
                  getOptionDisabled={(option) => {
                    return option.disable
                  }}
                  onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue)
                  }}
                  onChange={(value, newValue) => {
                    onChangeHandle(index, newValue)
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Ingredient"
                      variant="outlined"
                      fullWidth
                    />
                  )}
                />
              ) : (
                <ListItemText primary={`${item?.names?.[0]?.name || ''}`} />
              )}
              <ListItemSecondaryAction>
                <IconButton onClick={() => onClickEditBtn(index)}>
                  <EditIcon />
                </IconButton>
                <IconButton onClick={() => onClickDeleteBtn(index)}>
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
        <IngredientInput ingredients={ingredients} onChange={onChange} productId={productId}/>
      </>
    )
  )
}

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)
  return result
}

const getItemStyle = (isDragging, draggableStyle) => ({
  // styles we need to apply on draggables
  ...draggableStyle,

  ...(isDragging && {
    background: 'rgb(235,235,235)'
  })
})

const onDragEnd = (result, ingredients, onChange) => {
  if (!result.destination) {
    return
  }

  const items = reorder(
    ingredients,
    result.source.index,
    result.destination.index
  )
  onChange(items)
}

function SortIngredients ({ ingredients, onChange }) {
  return (
    ingredients && (
      <DragDropContext
        onDragEnd={(result) => onDragEnd(result, ingredients, onChange)}
      >
        <Droppable droppableId="droppable">
          {(provided) => (
            <RootRef rootRef={provided.innerRef}>
              <List>
                {ingredients.map((item, index) => (
                  <Draggable
                    key={item.id}
                    draggableId={`item-${item.id}`}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <ListItem
                        ContainerComponent="li"
                        ContainerProps={{ ref: provided.innerRef }}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(
                          snapshot.isDragging,
                          provided.draggableProps.style
                        )}
                      >
                        <ListItemText primary={`${item?.names?.[0]?.name || ''}`} />
                        <ListItemSecondaryAction />
                      </ListItem>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </List>
            </RootRef>
          )}
        </Droppable>
      </DragDropContext>
    )
  )
}
