import React from 'react'
import { makeStyles } from '@material-ui/styles'

import { IngredientToolbar, IngredientTable } from './components'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}))

const IngredientList = () => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <IngredientToolbar />
      <div className={classes.content}>
        <IngredientTable />
      </div>
    </div>
  )
}

export default IngredientList
