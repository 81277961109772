import React from 'react'
import MuiAlert from '@material-ui/lab/Alert'

function Alert (props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />
}

const SnackbarContentWrapper = (props, ref) => {
  const { message, onClose, severity } = props
  return (
    <Alert severity={severity} onClose={onClose} ref={ref}>
      {message}
    </Alert>
  )
}

export default React.forwardRef(SnackbarContentWrapper)
