import { Container, Subscribe } from 'unstated'
import React from 'react'

const defaultState = {
  message: '',
  severity: ''
}

export default class SnackbarContainer extends Container {
  state = defaultState;
  timer = null;
  close = async () => {
    this.timer = null
    await this.setState(defaultState)
  };

  showMessage = async (message, severity = 'info') => {
    await this.setState({ message, severity })
    if (this.timer) {
      clearTimeout(this.timer)
    }
    this.timer = setTimeout(this.close, 3000)
  };
}

export const withSnackbar = (Component) => {
  const ComponentWithSnackbar = (props) => {
    const renderProp = (snackbarState) => (
      <Component {...props} snackbar={snackbarState} />
    )
    return <Subscribe to={[SnackbarContainer]}>{renderProp}</Subscribe>
  }
  return ComponentWithSnackbar
}
