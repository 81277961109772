import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { AppBar, IconButton, Toolbar } from '@material-ui/core'
import UserContainer from '@/containers/UserContainer/'
import InputIcon from '@material-ui/icons/Input'
import { isEmpty } from 'lodash'

const useStyles = makeStyles(() => ({
  root: {
    boxShadow: 'none'
  },
  flexGrow: {
    flexGrow: 1
  },
  logo: {
    height: 40,
    margin: 2
  }
}))

const Topbar = (props) => {
  const { className, ...rest } = props

  const classes = useStyles()
  const { token, userInfo } = UserContainer.state
  const isAuth = !isEmpty(token) && !isEmpty(userInfo)

  return (
    <AppBar
      {...rest}
      className={clsx(classes.root, className)}
      color="primary"
      position="fixed"
    >
      <Toolbar>
        <RouterLink to="/">
          <img
            alt="Logo"
            src="/images/logos/logo_header.png"
            className={classes.logo}
          />
        </RouterLink>
        <div className={classes.flexGrow} />
        {isAuth && (
          <IconButton
            className={classes.signOutButton}
            color="inherit"
            onClick={UserContainer.logout}
          >
            <InputIcon />
          </IconButton>
        )}
      </Toolbar>
    </AppBar>
  )
}

Topbar.propTypes = {
  className: PropTypes.string
}

export default Topbar
