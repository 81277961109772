import React, { Component } from 'react'
import { Router } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import { ThemeProvider } from '@material-ui/styles'

import theme from './theme'
import 'react-perfect-scrollbar/dist/css/styles.css'
import './assets/scss/index.scss'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import 'callmeduy-braft-editor/dist/index.css'
import Routes from './Routes'
import { Provider } from 'unstated'
import SnackbarContainer, {
  withSnackbar
} from './containers/SnackbarContainer'
import SnackbarComponent from './components/Snackbar/Snackbar'

const browserHistory = createBrowserHistory()

class ErrorBoundary extends React.Component {
  state = {
    error: ''
  };

  static getDerivedStateFromError (error) {
    // Update state so the next render will show the fallback UI.
    return { error: error.message }
  }

  componentDidCatch (error, info) {
    // Display fallback UI
    return this.props.snackbar.showMessage(
      `Unknown error ${error.message}`,
      'error'
    )
  }

  render () {
    return this.props.children
  }
}

// eslint-disable-next-line no-class-assign
ErrorBoundary = withSnackbar(ErrorBoundary)

export default class App extends Component {
  render () {
    return (
      <ThemeProvider theme={theme}>
        <Provider inject={[SnackbarContainer]}>
          <SnackbarComponent />
          <ErrorBoundary>
            <Router history={browserHistory}>
              <Routes />
            </Router>
          </ErrorBoundary>
        </Provider>
      </ThemeProvider>
    )
  }
}
