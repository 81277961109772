import React, { createRef, useEffect, useState } from 'react'
import BraftEditor from 'callmeduy-braft-editor'
import Paper from '@material-ui/core/Paper'
import api from '@/apis/'

import { filesToFinderListing, patchRemoveFunc } from './finder'

import { makeStyles } from '@material-ui/styles'
import { withSnackbar } from '@/containers/SnackbarContainer'

import Table from './extensions/table'
import './extensions/table/style.scss'

require('./style.css')

const media = {
  uploadFn: async (params) => {
    try {
      const data = await api.file.http.uploadOne(params.file)
      params.success(filesToFinderListing([data])[0])
    } catch (err) {
      params.error(err.message)
    }
  }
}

const useStyle = makeStyles(() => ({
  editor: {
    fontFamily: 'Roboto, Arial, sans-serif !important'
  }
}))

BraftEditor.use([
  Table({
    defaultRows: 4,
    withDropdown: true,
    columnResizable: true,
    exportAttrString: ' border="1" style="border-collapse: collapse" '
  })
])

const Editor = ({
  className,
  editorClassName,
  paperProps,
  snackbar,
  ...rest
}) => {
  const classes = useStyle()
  const editorRef = createRef()
  const [files, setFiles] = useState([])
  useEffect(() => {
    api.file.http.getAll().then((files) => {
      const finderListing = filesToFinderListing(files)
      setFiles(finderListing)
    })
  }, [])

  useEffect(() => {
    if (editorRef.current && editorRef.current.braftFinder) {
      editorRef.current.braftFinder.setItems(files)
      patchRemoveFunc(editorRef.current.braftFinder, snackbar)
    }
  }, [files, editorRef])
  return (
    <Paper className={className} {...paperProps}>
      <BraftEditor
        ref={editorRef}
        language="en"
        className={editorClassName + ' ' + classes.editor}
        media={media}
        fixPlaceholder={true}
        allowInsertLinkText={true}
        triggerChangeOnMount={false}
        imageResizable={true}
        imageEqualRatio={true}
        {...rest}
      />
    </Paper>
  )
}

export default withSnackbar(Editor)
