import React, { useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import clsx from "clsx";
import axios from "axios";
const columns = [
  {
    name: "id",
    label: "Id",
    options: {
      display: false,
    },
  },
  {
    name: "content",
    label: "Comment",
  },
  {
    name: "user.name",
    label: "Author",
  },
  {
    name: "type",
    label: "Type",
  },
  {
    name: "created_date",
    label: "Time",
  },
  {
    name: "permalink_url",
    label: "Permalink Url",
    options: {
      display: false,
    },
  },
  {
    name: "isRead",
    label: "Read",
    options: {
      display: false,
    },
  },
];

const useStyles = makeStyles({
  row: {
    cursor: "pointer",
  },
  unread: {
    backgroundColor: "rgba(0, 0, 0, 0.15)",
  },
});

const UserTable = ({
  count,
  users,
  setNumberOfPage,
  setPage,
  setNotification,
}) => {
  const classes = useStyles();
  const requestReadNotification = useCallback(async (id) => {
    await axios.post(
      "https://admin.callmeduy.com/webhook/comments/read",
      {
        id,
      }
    );
  }, []);
  const options = useMemo(
    () => ({
      count,
      download: false,
      print: false,
      viewColumns: false,
      search: false,
      filter: false,
      sort: false,
      onRowClick: async (rowData, { dataIndex }) => {
        window.open(rowData[5]);
        await requestReadNotification(rowData[0]);
        const tmpNotification = [...users];
        tmpNotification[dataIndex].isRead = true;
        setNotification(tmpNotification);
      },
      onChangeRowsPerPage: (numberOfRows) => {
        setNumberOfPage(numberOfRows);
      },
      onChangePage: (currentPage) => {
        setPage(currentPage);
      },
      setRowProps: (row, dataIndex, rowIndex) => {
        return {
          className: clsx(classes.row, row[6] === false && classes.unread),
        };
      },
      selectableRows: "none",
      selectableRowsHeader: false,
    }),
    [count, users]
  );
  return (
    <MUIDataTable
      title={"Facebook comment list"}
      data={users}
      columns={columns}
      options={options}
    />
  );
};

export default UserTable;
