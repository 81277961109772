import React from 'react'
import { makeStyles } from '@material-ui/styles'

import { ProductToolbar, columns, productToRow } from './components'

import { useHistory } from 'react-router-dom'
import DataTable from '@/components/DataTable/DataTable'
import api from '@/apis'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}))

const ProductList = () => {
  const classes = useStyles()

  const history = useHistory()
  const options = {
    download: false,
    print: false,
    viewColumns: false,
    onRowClick: (rowData) => {
      return history.push('/products/update/' + rowData[0])
    },
    selectableRows: 'none',
    selectableRowsHeader: false
  }

  const handleSearch = async ({ query }) => {
    return api.product.http.searchProductByName({ name: query })
  }

  const handleCount = async ({ query }) => {
    return api.product.http.countProduct({ name: query })
  }

  return (
    <div className={classes.root}>
      <ProductToolbar />
      <div className={classes.content}>
        <DataTable
          title="Product list"
          columns={columns}
          options={options}
          elementToRow={productToRow}
          handleSearch={handleSearch}
          handleCount={handleCount}
          handleFetchPage={api.product.http.getAllProducts}
        />
      </div>
    </div>
  )
}

export default ProductList
