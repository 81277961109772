import _ from "lodash";

export const setElement = (array, id, setArray) => (value) => {
  const newArray = _.concat(array.slice(0, id), [value], array.slice(id + 1));
  setArray(newArray);
};

export const createArray = (len, val) => {
  if (typeof len !== "number") {
    throw TypeError("len must be a number");
  }
  if (!(val instanceof Function)) {
    val = () => val;
  }
  return Array.from(Array(len)).map(val);
};

const defaultBanner = { title: "", image: null, url: "", subtitle: "" };
export const createDefaultBanner = () => {
  return _.clone(defaultBanner);
};

export const createDefaultBannerArray = (len) => {
  return createArray(len, createDefaultBanner);
};

const isBannerEmpty = (banner) => {
  return _.isEqual(banner, defaultBanner);
};

export const nullifyEmptyBanner = (banner) =>
  isBannerEmpty(banner) ? null : banner;

export const nullifyEmptyBannerArray = (banners) => {
  return banners.map(nullifyEmptyBanner);
};

export const unNullifyBanner = (banner) =>
  _.isNull(banner) ? createDefaultBanner() : banner;

export const unNullifyBannerArray = (banners) => {
  return banners.map(unNullifyBanner);
};

export const formatFeatureProduct = (featureProduct) => {
  return featureProduct
    .filter((v) => v.name)
    .map((v) => ({ url: v.url, name: v.name, id: v.id }));
};
