import React from 'react'
import { TagInput } from '@/components'
import { Chip } from '@material-ui/core'
import _ from 'lodash'
import { withSnackbar } from '@/containers/SnackbarContainer'
import { nextLevel, dangerColor } from '@/apis/ingredientTag/color'

const IngredientTag = ({
  tags,
  onChange,
  options,
  loading,
  snackbar,
  ...rest
}) => {
  const handleChange = (tags) => {
    tags = tags.map((tag) => {
      if (typeof tag === 'string') {
        const existingOption = _.find(options, (o) => o.title === tag)
        return (
          existingOption || {
            title: tag,
            isNew: true,
            danger: 'unknown'
          }
        )
      }
      return tag
    })
    onChange(tags)
  }

  const switchTagType = (id) => () => {
    tags[id].danger = nextLevel(tags[id].danger)
    onChange(tags)
  }

  const renderTags = (value, getTagProps) =>
    value.map((option, index) => {
      return (
        <Chip
          style={{
            backgroundColor: dangerColor[option.danger],
            color: 'white'
          }}
          onClick={option.isNew ? switchTagType(index) : () => {}}
          key={index}
          label={option.title}
          {...getTagProps({ index })}
        />
      )
    })
  return (
    <TagInput
      label="Tags"
      value={tags}
      onChange={handleChange}
      options={options}
      filterSelectedOptions
      getOptionLabel={(tag) => tag.title}
      disabled={loading}
      autoSelect
      renderTags={renderTags}
      freeSolo
      {...rest}
    />
  )
}
export default withSnackbar(IngredientTag)
