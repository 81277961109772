import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import { Button, Tooltip } from '@material-ui/core'
import { Link } from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  searchInput: {
    marginRight: theme.spacing(1)
  }
}))

const Toolbar = (props) => {
  const { className, count, handleBtnAddNewUser, ...rest } = props
  const classes = useStyles()

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <div className={classes.row}>
        <span className={classes.spacer} />
        <Tooltip title="You can create at most 100 categories">
          <span>
            <Button
              color="primary"
              variant="contained"
              component={Link}
              disabled={count >= 100}
              to="/product-categories/create"
            >
              Add Root Category
            </Button>
          </span>
        </Tooltip>
      </div>
    </div>
  )
}

Toolbar.propTypes = {
  className: PropTypes.string
}

export default Toolbar
