import { Container } from 'unstated'
import { isEmpty } from 'lodash'
import api from '@/apis'
import { Router } from '@/helpers'
import isAllowed from '@/containers/UserContainer/isAllowed'
class UserContainer extends Container {
  state = {
    token: null,
    userInfo: null
  };

  constructor () {
    super()
    this.testLogin()
  }

  testLogin = async () => {
    if (await this.isAuth()) {
      const lastLoginTime = Number(
        window.localStorage.getItem('loginTime') || 0
      )
      const currentTime = new Date().getTime()
      if (currentTime - lastLoginTime >= 7 * 24 * 3600 * 1000) {
        // one week in ms
        this.logout()
      }
    }
  };

  login = async (loginInfo) => {
    const {
      data: { user, jwt }
    } = await api.user.auth.login(loginInfo)
    const userInfo = JSON.stringify(user)
    window.localStorage.setItem('userInfo', userInfo)
    window.localStorage.setItem('jwtToken', jwt)
    window.localStorage.setItem('loginTime', String(new Date().getTime()))
    await this.setState({ token: jwt, userInfo })
    return Router.replace('/')
  };

  logout = async () => {
    window.localStorage.removeItem('userInfo')
    window.localStorage.removeItem('jwtToken')
    window.localStorage.removeItem('loginTime')
    await this.setState({ token: null, userInfo: null })
    return Router.replace('/login')
  };

  isAuth = async () => {
    let { token, userInfo } = this.state
    if (!isEmpty(token) && !isEmpty(userInfo)) {
      return true
    }
    userInfo = window.localStorage.getItem('userInfo')
    token = window.localStorage.getItem('jwtToken')
    if (!isEmpty(token) && !isEmpty(userInfo)) {
      await this.setState({ token, userInfo: JSON.parse(userInfo) })
      return true
    }
    return false
  };

  getRole = () => {
    return this.state.userInfo && this.state.userInfo.role.type
  };

  getJwt = () => {
    return this.state.token
  };

  isOneOfRole = (...roles) => {
    return roles.includes(this.getRole())
  };

  getUserProfile = () => {
    return this.state.userInfo
  };

  isAllowed = (path) => {
    return isAllowed(this.getRole(), path)
  };
}

export default new UserContainer()
