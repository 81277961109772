import React, { useEffect, useState } from 'react'
import {
  Grid,
  Paper,
  TextField,
  CircularProgress,
  makeStyles
} from '@material-ui/core'
import api from '@/apis'

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(3)
  },
  view: {
    height: 400,
    width: '100%',
    position: 'relative'
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'contain'
  },
  progress: {
    margin: 'auto',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  }
}))
const eventToValue = (callback) => (event) => callback(event.target.value)
const InstagramLink = ({ link, setLink, loading }) => {
  const classes = useStyles()
  const [thumbnail, setThumbnail] = useState('')
  const [thumbnailLoading, setThumbnailLoading] = useState(false)
  const [error, setError] = useState('')
  useEffect(() => setError(''), [link])
  useEffect(() => {
    if (link === '') {
      setThumbnail('')
      return
    }
    setThumbnailLoading(true)
    api.layout.http
      .getInstaPost(link)
      .then(({ thumbnail_url: thumbnail }) => {
        setThumbnail(thumbnail)
        setThumbnailLoading(false)
      })
      .catch((err) => setError(err.message))
  }, [link])
  return (
    <Grid xs={12} md={6} item>
      <Paper className={classes.paper}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              disabled={loading}
              fullWidth
              label="Link"
              name="link"
              error={!!error}
              helperText={error || 'Input some link'}
              onChange={eventToValue(setLink)}
              value={link}
              variant="outlined"
            />
            <Paper className={classes.view}>
              {thumbnail ? (
                <a href={link} target="_blank" rel="noopener noreferrer">
                  <img
                    className={classes.image}
                    src={thumbnail}
                    alt="thumbnail"
                  />
                </a>
              ) : (
                thumbnailLoading && (
                  <div className={classes.progress}>
                    <CircularProgress />
                  </div>
                )
              )}
            </Paper>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  )
}

export default InstagramLink
