import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { AppBar, Toolbar, Hidden, IconButton } from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import InputIcon from '@material-ui/icons/Input'

import UserContainer from '@/containers/UserContainer/'
const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: 'none'
  },
  flexGrow: {
    flexGrow: 1
  },
  signOutButton: {
    marginLeft: theme.spacing(1)
  },
  logo: {
    height: 40,
    margin: 2
  }
}))

const Topbar = (props) => {
  const { className, onSidebarOpen, ...rest } = props

  const classes = useStyles()
  return (
    <AppBar {...rest} className={clsx(classes.root, className)}>
      <Toolbar>
        <Hidden mdDown>
          <RouterLink to="/">
            <img
              alt="Logo"
              src="/images/logos/logo_header.png"
              className={classes.logo}
            />
          </RouterLink>
          <div className={classes.flexGrow} />
          <IconButton
            className={classes.signOutButton}
            color="inherit"
            onClick={UserContainer.logout}
          >
            <InputIcon />
          </IconButton>
        </Hidden>
        <Hidden lgUp>
          <IconButton color="inherit" onClick={onSidebarOpen}>
            <MenuIcon />
          </IconButton>
          <div className={classes.flexGrow} />
          <RouterLink to="/">
            <img
              alt="Logo"
              src="/images/logos/logo_header.png"
              className={classes.logo}
            />
          </RouterLink>
        </Hidden>
      </Toolbar>
    </AppBar>
  )
}

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func
}

export default Topbar
