import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import { ProductCategoryDetail } from './components'
import { withSnackbar } from '@/containers/SnackbarContainer'
import { NotFoundError, ValidateError } from '@/apis/error'
import api from '@/apis'
import ConfirmModal from '@/components/ConfirmModal'
import { useProductCategoriesCount } from '@/apis/productCategory/hook'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4)
  }
}))

const useQuery = () => {
  return new URLSearchParams(useLocation().search)
}

const ProductCategorySingle = ({ snackbar }) => {
  const classes = useStyles()
  const history = useHistory()
  const { id, mode } = useParams()
  const query = useQuery()
  const parent = query.get('parent') || null
  const totalCount = useProductCategoriesCount()

  const [productCategory, setProductCategory] = useState({
    id,
    name: '',
    description: '',
    parent_category: parent
  })

  const [error, setError] = useState({ field: '', message: '' })
  const [loading, setLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)

  const updateField = (field) => (value) => {
    setProductCategory({ ...productCategory, [field]: value })
    if (error.message !== '') {
      setError({
        message: '',
        field: ''
      })
    }
  }

  const handleSubmit = async () => {
    if (mode === 'update') {
      return handleUpdate()
    }
    return handleCreate()
  }

  const handleCreate = async () => {
    try {
      api.productCategory.validate.validateProductCategoryData(productCategory)
      const res = await api.productCategory.http.createProductCategory(
        productCategory
      )
      await snackbar.showMessage('Created new product category', 'success')
      history.replace(`/product-categories/update/${res.id}`)
    } catch (err) {
      if (err instanceof ValidateError) {
        setError(err)
        history.replace('/product-categories/')
        return snackbar.showMessage(err.message, 'error')
      }
      throw err
    }
  }

  const handleUpdate = async () => {
    try {
      api.productCategory.validate.validateProductCategoryData(productCategory)
      await api.productCategory.http.updateProductCategory(productCategory)
      await snackbar.showMessage('Updated product category', 'success')
    } catch (err) {
      if (err instanceof ValidateError) {
        setError(err)
        await snackbar.showMessage(err.message, 'error')
      } else if (err instanceof NotFoundError) {
        await snackbar.showMessage(
          "That product category doesn't exist",
          'error'
        )
      } else {
        throw err
      }
    } finally {
      history.push('/product-categories/')
    }
  }

  const handleDelete = async () => {
    try {
      await api.productCategory.http.deleteProductCategory(id)
      await snackbar.showMessage('Deleted product category', 'success')
    } catch (err) {
      if (err instanceof NotFoundError) {
        await snackbar.showMessage(
          "That product category doesn't exist",
          'error'
        )
      } else {
        throw err
      }
    } finally {
      history.replace('/product-categories')
    }
  }

  useEffect(() => {
    if (mode === 'update') {
      setLoading(true)
      api.productCategory.http
        .getProductCategoryById(id)
        .then((productCategory) => {
          setProductCategory(productCategory)
          setLoading(false)
        })
        .catch((err) => {
          if (err instanceof NotFoundError) {
            history.replace('/not-found')
            return snackbar.showMessage(
              "There's no such product category",
              'error'
            )
          }
          throw err
        })
    } else if (totalCount >= 100) {
      snackbar
        .showMessage('You can only create at most 100 categories', 'error')
        .then(() => history.replace('/product-categories'))
    } else {
      setLoading(false)
    }
  }, [id, mode, history, snackbar, totalCount])

  return (
    <div className={classes.root}>
      <ConfirmModal
        show={showModal}
        handleClose={() => setShowModal(false)}
        handleConfirm={handleDelete}
        message="Are you sure you want to delete this product category?"
      />
      <ProductCategoryDetail
        mode={mode}
        loading={loading}
        parent={parent}
        productCategory={productCategory}
        handleSubmit={handleSubmit}
        handleDelete={() => setShowModal(true)}
        updateField={updateField}
        error={error}
      />
    </div>
  )
}

export default withSnackbar(ProductCategorySingle)
