import React, { useEffect, useState } from 'react'
import { Grid, IconButton, Modal, makeStyles } from '@material-ui/core'
import { Pagination } from '@material-ui/lab'
import SearchIcon from '@material-ui/icons/Search'
import CheckIcon from '@material-ui/icons/Check'

import api from '@/apis'

const useStyles = makeStyles((theme) => ({
  button: {
    color: 'white'
  },
  gridItem: {
    position: 'relative'
  },
  image: {
    width: '100%',
    height: 100,
    objectFit: 'contain',
    backgroundImage:
      'linear-gradient(45deg, #D0D0D0 25%, transparent 25%), linear-gradient(-45deg, #D0D0D0 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #D0D0D0 75%), linear-gradient(-45deg, transparent 75%, #D0D0D0 75%)',
    backgroundSize: '20px 20px',
    backgroundPosition: '0 0, 0 10px, 10px -10px, -10px 0px',
    border: '2px black solid'
  },
  overlay: {
    width: '100%',
    height: 100,
    position: 'absolute',
    zIndex: 10,
    transition: '0.5s',
    opacity: 0,
    backgroundColor: 'rgba(255,255,255,0)',
    '&:hover': {
      opacity: 1,
      backgroundColor: 'rgba(0,0,0,0.5)',
      transition: '0.5s'
    }
  },
  modalMedia: {
    maxWidth: '80%',
    maxHeight: '80%',
    objectFit: 'contain',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%);',
    backgroundColor: theme.palette.background.paper,
    fontFamily: 'Roboto, sans-serif',
    textAlign: 'center',
    padding: theme.spacing(4, 2),
    outline: 'none',
    '&:hover': {
      cursor: 'zoom-out'
    }
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    overflow: 'scroll',
    height: '100%',
    flexDirection: 'column'
  },
  pagination: {
    marginTop: theme.spacing(2)
  }
}))
const LIMIT = 12
const List = ({ setImage }) => {
  const classes = useStyles()
  const [files, setFiles] = useState([])
  const [previewURL, setPreviewURL] = useState(null)
  const [page, setPage] = useState(1)
  const [count, setCount] = useState(1)

  useEffect(() => {
    api.file.http.getAll({ page, limit: LIMIT }).then(setFiles)
  }, [page])

  useEffect(() => {
    api.file.http.count().then((count) => {
      setCount(Math.ceil(count / LIMIT))
    })
  }, [])

  return (
    <>
      <Modal open={!!previewURL} onClose={() => setPreviewURL(null)}>
        <img src={previewURL} alt="preview" className={classes.modalMedia} />
      </Modal>
      <div className={classes.container}>
        <Grid container spacing={1}>
          {files.map((file, idx) => (
            <Grid item xs={6} md={3} key={idx}>
              <div className={classes.gridItem}>
                <div className={classes.overlay} onClick={() => setImage(file)}>
                  <IconButton
                    className={classes.button}
                    onClick={() => setPreviewURL(file.url)}
                  >
                    <SearchIcon />
                  </IconButton>
                  <IconButton
                    className={classes.button}
                    onClick={() => setImage(file)}
                  >
                    <CheckIcon />
                  </IconButton>
                </div>
                <img className={classes.image} src={file.url} alt={file.key} />
              </div>
            </Grid>
          ))}
        </Grid>
        <Pagination
          className={classes.pagination}
          count={count}
          page={page}
          siblingCount={1}
          onChange={(event, value) => setPage(value)}
        />
      </div>
    </>
  )
}

export default List
