import { DropzoneArea } from 'material-ui-dropzone'
import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Paper from '@material-ui/core/Paper'
import { withSnackbar } from '@/containers/SnackbarContainer'

const useStyle = makeStyles((theme) => ({
  dropzoneText: {
    fontFamily: 'Roboto, Arial, sans-serif'
  },
  dropzoneClass: {
    border: 'none',
    backgroundColor: 'transparent',
    padding: theme.spacing(2),
    height: '100%'
  }
}))
const Dropzone = ({
  className,
  setImage,
  title,
  paperProps,
  fileSizeLimitInMB = 1,
  snackbar
}) => {
  const fileSizeInBytes = fileSizeLimitInMB * 1e6
  const onReject = async ([file]) => {
    if (fileSizeInBytes < file.size) {
      await snackbar.showMessage(
        `File is too big. Upload file less than ${fileSizeLimitInMB}MB. This file is ${Number(
          file.size / 1e6
        ).toFixed(2)}MB`,
        'error'
      )
    }
  }
  const classes = useStyle()
  return (
    <Paper className={className} {...paperProps}>
      <DropzoneArea
        maxFileSize={fileSizeInBytes}
        onDropRejected={onReject}
        dropzoneParagraphClass={classes.dropzoneText}
        acceptedFiles={['image/*']}
        dropzoneClass={classes.dropzoneClass}
        dropzoneText={title}
        showAlerts={false}
        filesLimit={1}
        onChange={(files) => {
          return setImage(files[0])
        }}
      />
    </Paper>
  )
}

export default withSnackbar(Dropzone)
