import React, { useState } from 'react'
import {
  Grid,
  Typography,
  Fade,
  Modal,
  Paper,
  makeStyles
} from '@material-ui/core'
import Dropzone from '../Dropzone'
import List from './List'

const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
    cursor: 'pointer'
  },
  modal: {
    width: '80vw',
    height: '75vh',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    padding: theme.spacing(2),
    overflow: 'scroll',
    outline: 'none'
  },
  dropzone: {},
  title: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  },
  grid: {
    height: '100%'
  },
  list: {
    overflow: 'hidden',
    height: 'calc(100% - 100px)',
    border: '1px solid black'
  }
}))

const Main = ({
  titleSize = 'h1',
  setImage,
  className,
  paperProps,
  fileSizeLimitInMB,
  title
}) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const handleSetImage = (image) => {
    setImage(image)
    setOpen(false)
  }

  return (
    <>
      <Modal open={open} onClose={() => setOpen(false)}>
        <Fade in={open} exit={!open}>
          <Paper className={classes.modal}>
            <Grid
              container
              spacing={2}
              className={classes.grid}
              justify="center"
            >
              <Grid item xs={false} md={9}>
                <Typography variant={titleSize} align="center">
                  Select an existing image below
                </Typography>
              </Grid>
              <Grid item xs={12} md={9} className={classes.list}>
                <List setImage={setImage} />
              </Grid>
              <Grid item xs={12} md={3}>
                <Dropzone
                  className={classes.dropzone}
                  fileSizeLimitInMB={fileSizeLimitInMB}
                  setImage={handleSetImage}
                  title="Or drag and drop your file here"
                />
              </Grid>
            </Grid>
          </Paper>
        </Fade>
      </Modal>
      <Paper
        className={className + ' ' + classes.container}
        {...paperProps}
        onClick={() => setOpen(true)}
      >
        <Typography variant="h1" align="center" className={classes.title}>
          {title}
        </Typography>
      </Paper>
    </>
  )
}

export default Main
