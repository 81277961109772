import React from 'react'
import { makeStyles } from '@material-ui/core'
import { CategoryTree } from './components'
import Toolbar from './components/Toolbar'
import { useProductCategoriesCount } from '@/apis/productCategory/hook'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4)
  },
  toolbar: {
    margin: theme.spacing(2)
  }
}))

const ProductCategory = (props) => {
  const classes = useStyles()
  const count = useProductCategoriesCount()
  return (
    <div className={classes.root}>
      <Toolbar className={classes.toolbar} count={count} />
      <CategoryTree count={count} />
    </div>
  )
}

export default ProductCategory
