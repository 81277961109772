import React from 'react'
import { dangerColor } from '@/apis/ingredientTag/color'
import { parseTime } from '@/helpers'

const customBodyRender = (value) => {
  if (!value) {
    return null
  }
  return <h3 style={{ color: dangerColor[value?.danger] }}>{value?.title}</h3>
}
export const columns = [
  {
    name: 'id',
    label: 'ID',
    options: {
      filter: false,
      sort: true
    }
  },
  {
    name: 'title',
    label: 'Title',
    options: {
      filter: false,
      sort: false,
      customBodyRender
    }
  },
  {
    name: 'updated_at',
    label: 'Last update',
    options: {
      filter: false,
      sort: true,
      sortDirection: 'desc'
    }
  }
]
export function ingredientTagToRow (data) {
  return [
    data.id,
    { title: data.title, danger: data.danger },
    parseTime(data.updated_at)
  ]
}
