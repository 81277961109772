import React, { useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import {
  Grid,
  Button,
  TextField,
  CircularProgress,
  Typography,
  FormHelperText
} from '@material-ui/core'
import UserContainer from '@/containers/UserContainer/'
import { PasswordInput } from '@/components'
import { ValidateError } from '@/apis/error'
import { withSnackbar } from '@/containers/SnackbarContainer'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%'
  },
  grid: {
    height: '100%'
  },
  quoteContainer: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  },
  quote: {
    backgroundColor: theme.palette.neutral,
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: 'url(/images/auth.jpg)',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  },
  quoteInner: {
    textAlign: 'center',
    flexBasis: '600px'
  },
  quoteText: {
    color: theme.palette.white
  },
  name: {
    marginTop: theme.spacing(3),
    color: theme.palette.white
  },
  bio: {
    color: theme.palette.white
  },
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  logoImage: {
    marginLeft: theme.spacing(4)
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  },
  form: {
    paddingLeft: 100,
    paddingRight: 100,
    paddingBottom: 125,
    flexBasis: 700,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  title: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2)
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  signInButton: {
    margin: theme.spacing(2, 0)
  },
  progress: {
    height: 20
  }
}))

const SignIn = ({ snackbar }) => {
  const classes = useStyles()
  const [userInput, setUserInput] = useState({ username: '', password: '' })
  const [error, setError] = useState('')
  const [loading, setLoading] = useState(false)
  const updateField = (field) => ({ target: { value } }) => {
    setError('')
    setUserInput({ ...userInput, [field]: value })
  }
  const handleSubmit = (event) => {
    event.preventDefault()
    setLoading(true)
    UserContainer.login(userInput)
      .catch((err) => {
        if (err instanceof ValidateError) {
          return setError(err.message)
        }
        throw err
      })
      .finally(() => setLoading(false))
  }
  return (
    <div className={classes.root}>
      <Grid className={classes.grid} container>
        <Grid className={classes.quoteContainer} item lg={5}>
          <div className={classes.quote}>
            <div className={classes.quoteInner}>
              <Typography className={classes.quoteText} variant="h1">
                CallMeDuy blog
              </Typography>
              <div className={classes.person}>
                <Typography className={classes.name} variant="h2">
                  by Vu Duy
                </Typography>
              </div>
            </div>
          </div>
        </Grid>
        <Grid className={classes.content} item lg={7} xs={12}>
          <div className={classes.content}>
            <div className={classes.contentBody}>
              <form className={classes.form} onSubmit={handleSubmit}>
                <Typography className={classes.title} variant="h2">
                  Sign in
                </Typography>
                {error && <FormHelperText error>{error}</FormHelperText>}
                <TextField
                  className={classes.textField}
                  fullWidth
                  label="Username"
                  name="username"
                  type="text"
                  variant="outlined"
                  disabled={loading}
                  autoFocus
                  autoComplete="new-password"
                  error={!!error}
                  value={userInput.username}
                  onChange={updateField('username')}
                />
                <PasswordInput
                  className={classes.textField}
                  fullWidth
                  label="Password"
                  name="password"
                  type="password"
                  variant="outlined"
                  autoComplete="new-password"
                  error={!!error}
                  disabled={loading}
                  value={userInput.password}
                  onChange={updateField('password')}
                />
                <Button
                  className={classes.signInButton}
                  color="primary"
                  fullWidth
                  disabled={loading}
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  {loading ? (
                    <CircularProgress className={classes.progress} />
                  ) : (
                    <span>Sign in</span>
                  )}
                </Button>
              </form>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export default withSnackbar(SignIn)
