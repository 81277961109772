import React, { useState, useEffect } from 'react'
import {
  Card,
  CardActions,
  CardContent,
  Grid,
  Button,
  makeStyles
} from '@material-ui/core'

import api from '@/apis'
import { withSnackbar } from '@/containers/SnackbarContainer'
import { HTTPError } from '@/apis/error'
import { InstagramLink } from './components'
import _ from 'lodash'
import SaveButton from '@/components/SaveButton'

const setElement = (array, id, setArray) => (value) => {
  const newArray = _.concat(array.slice(0, id), [value], array.slice(id + 1))
  setArray(newArray)
}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4)
  },
  paper: {
    margin: theme.spacing(3),
    padding: theme.spacing(1)
  }
}))

const InstaLayoutSetting = ({ snackbar }) => {
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const [links, setLinks] = useState(['', '', '', '', '', ''])

  const setData = (data) => {
    setLinks(data.links)
  }

  const onSubmit = async () => {
    setLoading(true)
    try {
      const res = await api.layout.http.updateInstaLayout({
        links
      })
      await snackbar.showMessage('Update layout successfully', 'success')
      setData(res)
    } catch (err) {
      if (err instanceof HTTPError) {
        await snackbar.showMessage(err.message, 'error')
      }
      throw err
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    setLoading(true)
    api.layout.http
      .getInstaLayout()
      .then(setData)
      .then(() => setLoading(false))
  }, [])

  return (
    <div className={classes.root}>
      <Card className={classes.paper}>
        <CardContent>
          <Grid container spacing={3}>
            {links.map((link, idx) => (
              <InstagramLink
                key={idx}
                loading={loading}
                link={link}
                setLink={setElement(links, idx, setLinks)}
              />
            ))}
          </Grid>
        </CardContent>
        <CardActions>
          <Button
            variant="contained"
            color="primary"
            onClick={onSubmit}
            disabled={loading}
          >
            Save
          </Button>
        </CardActions>
        <SaveButton onSave={onSubmit} />
      </Card>
    </div>
  )
}

export default withSnackbar(InstaLayoutSetting)
