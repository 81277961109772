/* eslint-disabled */

const zh = {
  rows: '行数',
  columns: '列数',
  cancel: '取消',
  insertTable: '插入',
  removeTable: '删除表格',
  insertColumn: '插入列',
  removeColumn: '删除列',
  insertRow: '插入行',
  removeRow: '删除行',
  mergeCells: '合并单元格',
  splitCell: '拆分单元格'
}

const zhHant = {
  rows: '行数',
  columns: '列数',
  cancel: '取消',
  insertTable: '插入',
  removeTable: '删除表格',
  insertColumn: '插入列',
  removeColumn: '删除列',
  insertRow: '插入行',
  removeRow: '删除行',
  mergeCells: '合并单元格',
  splitCell: '拆分单元格'
}

const en = {
  rows: 'Rows',
  columns: 'Columns',
  cancel: 'Cancel',
  insertTable: 'Insert',
  removeTable: 'Remove Table',
  insertColumn: 'Insert Column',
  removeColumn: 'Remove Column',
  insertRow: 'Insert Row',
  removeRow: 'Remove Row',
  mergeCells: 'Merge Cells',
  splitCell: 'Split Cell'
}

const pl = {
  rows: 'Wiersz',
  columns: 'Kolumnę',
  cancel: 'Anuluj',
  insertTable: 'Wstaw',
  removeTable: 'Usuń tabelę',
  insertColumn: 'Wstaw kolumnę',
  removeColumn: 'Usuń kolumnę',
  insertRow: 'Wstaw wiersz',
  removeRow: 'Usuń wiersz',
  mergeCells: 'Scal komórki',
  splitCell: 'Podziel komórkę'
}
const kr = {
  rows: '행수',
  columns: '열수',
  cancel: '취소',
  insertTable: '삽입',
  removeTable: '양식삭제',
  insertColumn: '열삽입',
  removeColumn: '열삭제',
  insertRow: '행삽입',
  removeRow: '행삭제',
  mergeCells: '행삽입',
  splitCell: '셀분할'
}
const jpn = {
  rows: '行数',
  columns: '列数',
  cancel: 'キャンセル',
  insertTable: '挿入する',
  removeTable: 'フォームを削除',
  insertColumn: '列を挿入',
  removeColumn: '列を削除',
  insertRow: '行を挿入',
  removeRow: '行を削除',
  mergeCells: 'セルを結合',
  splitCell: '分割セル'
}
export default { zh, zhHant, en, pl, jpn, kr }
