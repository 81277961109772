import { parseTime } from '@/helpers'

export const columns = [
  {
    name: 'id',
    label: 'ID',
    options: {
      filter: false,
      sort: true
    }
  },
  {
    name: 'name',
    label: 'Name',
    options: {
      filter: false,
      sort: true
    }
  },
  {
    name: 'description',
    label: 'Description',
    options: {
      filter: false,
      sort: true
    }
  },
  {
    name: 'url',
    label: 'Link',
    options: {
      filter: false,
      sort: false
    }
  },
  {
    name: 'updated_at',
    label: 'Last update',
    options: {
      filter: true,
      sort: true
    }
  }
]

export function brandToRow (data) {
  return [
    data.id,
    data.name,
    data.description,
    data.url,
    parseTime(data.updated_at)
  ]
}
