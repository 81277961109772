export const makeDescription = (data) =>
  data.content.toText().split(' ').slice(0, 50).join(' ')

export const prepareData = (data) => {
  const submitData = {
    ...data,
    content: data.content.toHTML()
  }
  if (!submitData.description) {
    submitData.description = makeDescription(data)
  }
  return submitData
}
