import requester from '../requester'
import { pullErrorMessage } from '../error'

export const uploadOne = async (file) => {
  const data = await upload([file])
  if (data instanceof Array) {
    return data[0]
  }
  return data
}

export const upload = async (files) => {
  const form = new FormData()
  files.forEach((file) => {
    form.append('files', file, file.name)
  })

  const { data } = await requester()
    .post('upload', form)
    .catch(pullErrorMessage)
  return data
}

export const getAll = async ({ page = 1, limit = 10 } = {}) => {
  const { data } = await requester()
    .get('upload/files', {
      params: {
        _sort: 'updated_at:DESC',
        _start: (page - 1) * limit,
        _limit: limit
      }
    })
    .catch(pullErrorMessage)
  return data
}

export const count = async () => {
  const { data } = await requester()
    .get('upload/files/count')
    .catch(pullErrorMessage)
  return parseInt(data?.count)
}

export const deleteOne = async (id) => {
  // const { data } = await requester()
  //   .delete("upload/files/" + id)
  //   .catch(pullErrorMessage);
  // return data;
  return {}
}

export const deleteMultiple = async (ids) => {
  // const { data } = await requester()
  //   .delete("upload/files/multiple?ids=" + JSON.stringify(ids))
  //   .catch(pullErrorMessage);
  // return data;
  return {}
}
