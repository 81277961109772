import { BadRequestError, ValidateError } from "../error";
import _ from "lodash";

export const validateIngredientData = ({
  main_name: mainName,
  description,
  aliases,
}) => {
  if (mainName === "") {
    throw new ValidateError("A main name is required", "main_name");
  }
  if (description === "") {
    throw new ValidateError("A description is required", "description");
  }
  if (aliases.includes(mainName)) {
    throw new ValidateError(
      "Aliases should not duplicate main name",
      "aliases"
    );
  }
  if (!_.isEqual(_.uniq(aliases), aliases)) {
    throw new ValidateError("Aliases should be unique", "aliases");
  }
};

export const checkSameNameError = (err) => {
  if (
    err instanceof BadRequestError &&
    err.message.includes("Existing name:")
  ) {
    throw new ValidateError(err.message, "names");
  }
  throw err;
};
