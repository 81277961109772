import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import { useParams, useHistory } from 'react-router-dom'
import { UserDetail } from './components'
import api from '@/apis'
import { withSnackbar } from '@/containers/SnackbarContainer'
import { ConfirmModal } from '@/components'
import { NotFoundError, ValidateError } from '@/apis/error'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4)
  }
}))

const UserSingle = ({ snackbar }) => {
  const classes = useStyles()
  const history = useHistory()
  const { id } = useParams()

  const [user, setUser] = useState({
    id,
    username: '',
    name: '',
    role: '',
    password: '',
    passwordConfirm: ''
  })
  const [roles, setRoles] = useState([])
  const [error, setError] = useState({ field: '', message: '' })
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    Promise.all([api.user.http.getUserById(id), api.user.http.getRoles()])
      .then(([userData, roleData]) => {
        setUser({
          id: userData.id,
          username: userData.username,
          name: userData.name,
          role: userData.role.id,
          password: '',
          passwordConfirm: ''
        })
        setRoles(roleData)
        setLoading(false)
      })
      .catch((err) => {
        if (err instanceof NotFoundError) {
          history.replace('/not-found')
          return snackbar.showMessage("There's no such user", 'error')
        }
        throw err
      })
  }, [id, history, snackbar])

  const updateField = (field) => ({ target: { value } }) => {
    setUser({ ...user, [field]: value })
    setError({
      message: '',
      field: ''
    })
  }

  const handleSubmit = async () => {
    try {
      api.user.validate.validateUpdateUser(user)
      await api.user.http.editUserById(user)
      await snackbar.showMessage('Updated user', 'success')
      history.push('/users')
    } catch (err) {
      if (err instanceof ValidateError) {
        setError(err)
        await snackbar.showMessage(err.message, 'error')
        return
      }
      throw err
    }
  }
  const handleDelete = async () => {
    try {
      await api.user.http.deleteUserById(id)
      await snackbar.showMessage('Deleted user', 'success')
    } catch (err) {
      if (err instanceof NotFoundError) {
        await snackbar.showMessage('No such user', 'error')
      }
    } finally {
      history.replace('/users')
    }
  }
  const [showModal, setShowModal] = useState(false)
  return (
    <div className={classes.root}>
      <ConfirmModal
        show={showModal}
        handleClose={() => setShowModal(false)}
        handleConfirm={handleDelete}
        message="Are you sure you want to delete this user?"
      />
      <UserDetail
        loading={loading}
        user={user}
        listRoles={roles.roles}
        handleSubmit={handleSubmit}
        handleDelete={() => setShowModal(true)}
        updateField={updateField}
        error={error}
      />
    </div>
  )
}

export default withSnackbar(UserSingle)
