import React from 'react'
import Slider from 'react-slick'
import { IconButton, withStyles, Typography } from '@material-ui/core'
import NavigateNextIcon from '@material-ui/icons/NavigateNext'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore'
import SingleBanner from '../SingleBanner'
import { setElement } from '../../utils'
import * as PropTypes from 'prop-types'

const useStyles = withStyles((theme) => ({
  banner: {
    margin: theme.spacing(2)
  },
  slider: {
    width: '90%',
    marginBottom: theme.spacing(2)
  },
  container: {
    display: 'flex',
    flexFlow: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: theme.spacing(4)
  },
  bannerImage: {
    height: '30vw'
  },
  title: {
    margin: theme.spacing(3)
  }
}))

class MainBannerList extends React.Component {
  constructor (props) {
    super(props)
    this.slider = null
  }

  render () {
    const { banners, setBanners, classes, loading } = this.props
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      arrows: false,
      slidesToShow: 1,
      slidesToScroll: 1
    }

    return (
      <>
        <Typography variant="h1" align="center" className={classes.title}>
          Main banner
        </Typography>
        <div className={classes.container}>
          <IconButton
            disabled={loading}
            onClick={() => this.slider.slickPrev()}
            color="primary"
          >
            <NavigateBeforeIcon />
          </IconButton>
          <Slider
            className={classes.slider}
            {...settings}
            ref={(slider) => (this.slider = slider)}
          >
            {banners.map((banner, idx) => {
              return (
                <SingleBanner
                  loading={loading}
                  className={classes.banner}
                  imageClassName={classes.bannerImage}
                  key={idx}
                  setBanner={setElement(banners, idx, setBanners)}
                  banner={banner}
                />
              )
            })}
          </Slider>
          <IconButton
            disabled={loading}
            onClick={() => this.slider.slickNext()}
            color="primary"
          >
            <NavigateNextIcon />
          </IconButton>
        </div>
      </>
    )
  }
}

MainBannerList.propTypes = {
  banners: PropTypes.any,
  setBanners: PropTypes.any,
  loading: PropTypes.bool
}
export default useStyles(MainBannerList)
