import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { Avatar, Typography } from '@material-ui/core'
import UserContainer from '@/containers/UserContainer/'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 'fit-content'
  },
  avatar: {
    width: 60,
    height: 60,
    backgroundColor: theme.palette.primary.main
  },
  name: {
    marginTop: theme.spacing(1)
  }
}))

const Profile = (props) => {
  const { className, ...rest } = props

  const classes = useStyles(props)

  const { name, avatar, role } = UserContainer.getUserProfile()

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Avatar
        alt={`${name}-avatar`}
        className={classes.avatar}
        component={RouterLink}
        src={avatar}
        to="/users"
      >
        {name[0].toUpperCase()}
      </Avatar>
      <Typography className={classes.name} variant="h4">
        {name}
      </Typography>
      <Typography variant="body2">{role.description}</Typography>
    </div>
  )
}

Profile.propTypes = {
  className: PropTypes.string
}

export default Profile
