import React from "react";
import { Switch, Redirect } from "react-router-dom";

import { RouteWithLayout, withAuthSync } from "./components";
import { Main as MainLayout, Minimal as MinimalLayout } from "./layouts";

import {
  UserList as UserListView,
  SingleUser as SingleUserView,
  SignIn as SignInView,
  NotFound as NotFoundView,
  PostList as PostListView,
  PostSingle as PostSingleView,
  PostTagList as PostTagListView,
  PostTagSingle as PostTagSingleView,
  Home as HomeView,
  IngredientList as IngredientListView,
  IngredientListPending as IngredientListPendingView,
  IngredientSingle as IngredientSingleView,
  IngredientTagList as IngredientTagListView,
  IngredientTagSingle as IngredientTagSingleView,
  ProductList as ProductListView,
  ProductSingle as ProductSingleView,
  ProductCategoryList,
  ProductCategorySingle,
  BrandList as BrandListView,
  BrandSingle as BrandSingleView,
  HomeLayoutSetting,
  OtherLayoutSetting,
  InstaLayoutSetting,
  NotificationView,
} from "./views";

const Routes = () => {
  return (
    <Switch>
      <RouteWithLayout
        title="Home"
        component={HomeView}
        exact
        layout={MainLayout}
        path="/"
      />
      <RouteWithLayout
        title="Home Layout"
        component={HomeLayoutSetting}
        exact
        layout={MainLayout}
        path="/layouts/home"
      />
      <RouteWithLayout
        title="Other Layout"
        component={OtherLayoutSetting}
        exact
        layout={MainLayout}
        path="/layouts/other"
      />
      <RouteWithLayout
        title="Instagram Layout"
        component={InstaLayoutSetting}
        exact
        layout={MainLayout}
        path="/layouts/insta"
      />
      <RouteWithLayout
        title="User"
        component={SingleUserView}
        exact
        layout={MainLayout}
        path="/users/:id"
      />
      <RouteWithLayout
        title="Users"
        component={UserListView}
        exact
        layout={MainLayout}
        path="/users"
      />
      <RouteWithLayout
        title="Authenticate"
        component={SignInView}
        exact
        layout={MinimalLayout}
        path="/login"
      />
      <RouteWithLayout
        title="Posts"
        component={PostListView}
        exact
        layout={MainLayout}
        path="/posts"
      />
      <RouteWithLayout
        title="Post"
        component={PostSingleView}
        exact
        layout={MainLayout}
        path={["/posts/:mode", "/posts/:mode/:slug"]}
      />
      <RouteWithLayout
        title="Ingredients"
        component={IngredientListView}
        exact
        layout={MainLayout}
        path={"/ingredients"}
      />
      <RouteWithLayout
        title="Ingredients"
        component={IngredientListPendingView}
        exact
        layout={MainLayout}
        path={"/ingredientPending"}
      />
      <RouteWithLayout
        title="Ingredient"
        component={IngredientSingleView}
        exact
        layout={MainLayout}
        path={["/ingredient/:mode", "/ingredient/:mode/:id"]}
      />
      <RouteWithLayout
        title="Ingredient Tags"
        component={IngredientTagListView}
        exact
        layout={MainLayout}
        path="/ingredient-tags"
      />
      <RouteWithLayout
        title="Ingredient Tag"
        component={IngredientTagSingleView}
        exact
        layout={MainLayout}
        path={["/ingredient-tags/:mode", "/ingredient-tags/:mode/:id"]}
      />
      <RouteWithLayout
        title="Products"
        component={ProductListView}
        exact
        layout={MainLayout}
        path="/products"
      />
      <RouteWithLayout
        title="Product"
        component={ProductSingleView}
        exact
        layout={MainLayout}
        path={["/products/:mode", "/products/:mode/:id"]}
      />
      <RouteWithLayout
        title="Product Categories"
        component={ProductCategoryList}
        exact
        layout={MainLayout}
        path="/product-categories"
      />
      <RouteWithLayout
        title="Product Category"
        component={ProductCategorySingle}
        exact
        layout={MainLayout}
        path={["/product-categories/:mode", "/product-categories/:mode/:id"]}
      />
      <RouteWithLayout
        title="Post Tags"
        component={PostTagListView}
        exact
        layout={MainLayout}
        path="/post-tags"
      />
      <RouteWithLayout
        title="Post Tag"
        component={PostTagSingleView}
        exact
        layout={MainLayout}
        path={["/post-tags/:mode", "/post-tags/:mode/:slug"]}
      />
      <RouteWithLayout
        title="Brands"
        component={BrandListView}
        exact
        layout={MainLayout}
        path="/brands"
      />
      <RouteWithLayout
        title="Brand"
        component={BrandSingleView}
        exact
        layout={MainLayout}
        path={["/brands/:mode", "/brands/:mode/:id"]}
      />
      <RouteWithLayout
        title="Brand"
        component={NotificationView}
        exact
        layout={MainLayout}
        path={"/notifications"}
      />
      <RouteWithLayout
        title="Not Found"
        component={NotFoundView}
        exact
        layout={MinimalLayout}
        path="/not-found"
      />
      <Redirect to="/not-found" />
    </Switch>
  );
};

export default withAuthSync(Routes);
