import ROUTES from './routes'
import ROLES from '../roles'

const DISALLOWED_ROUTES = {
  [ROLES.owner]: [],
  [ROLES.moderator]: [ROUTES.user],
  [ROLES.content_maker]: [
    ROUTES.user,
    ROUTES.ingredientList,
    ROUTES.IngredientListPending,
    ROUTES.ingredientEdit,
    ROUTES.ingredientTagList,
    ROUTES.ingredientTagEdit,
    ROUTES.productList,
    ROUTES.productEdit,
    ROUTES.brandList,
    ROUTES.brandEdit,
    ROUTES.productCategoryList,
    ROUTES.productCategoryEdit
  ],
  [ROLES.collaborator]: [
    ROUTES.user,
    ROUTES.ingredientEdit,
    ROUTES.ingredientList,
    ROUTES.IngredientListPending,
    ROUTES.ingredientTagList,
    ROUTES.ingredientTagEdit,
    ROUTES.postTagList,
    ROUTES.postTagEdit,
    ROUTES.brandList,
    ROUTES.brandEdit,
    ROUTES.productEdit,
    ROUTES.productList,
    ROUTES.productCategoryEdit,
    ROUTES.productCategoryList,
    ROUTES.layout
  ],
  [ROLES.chemist]: [
    ROUTES.user,
    ROUTES.postList,
    ROUTES.postEdit,
    ROUTES.productList,
    ROUTES.productEdit,
    ROUTES.postTagList,
    ROUTES.postTagEdit,
    ROUTES.brandList,
    ROUTES.brandEdit,
    ROUTES.productCategoryList,
    ROUTES.productCategoryEdit,
    ROUTES.layout
  ],
  [ROLES.product_manager]: [
    ROUTES.user,
    ROUTES.postList,
    ROUTES.postEdit,
    ROUTES.ingredientList,
    ROUTES.IngredientListPending,
    ROUTES.ingredientEdit,
    ROUTES.brandList,
    ROUTES.brandEdit,
    ROUTES.postTagList,
    ROUTES.postTagEdit,
    ROUTES.ingredientTagList,
    ROUTES.ingredientTagEdit,
    ROUTES.layout
  ]
}
export default DISALLOWED_ROUTES
