import requester from '../requester'
import { pullErrorMessage } from '../error'
import { catchNameTakenError } from './validate'

export const getAllUser = async (cancelToken) => {
  const { data } = await requester()
    .get('users', { cancelToken })
    .catch(pullErrorMessage)
  return data
}

export const getRoles = async (cancelToken) => {
  const { data } = await requester()
    .get('users-permissions/roles', { cancelToken })
    .catch(pullErrorMessage)
  return data
}

export const addUser = async (userData, cancelToken) => {
  const { data } = await requester()
    .post('users', userData, { cancelToken })
    .catch(pullErrorMessage)
    .catch(catchNameTakenError)
  return data
}

export const getUserById = async (id, cancelToken) => {
  const { data } = await requester()
    .get(`users/${id}`, { cancelToken })
    .catch(pullErrorMessage)
  return data
}

export const editUserById = async (userData, cancelToken) => {
  if (!userData.password) {
    delete userData.password
  }
  const { data } = await requester()
    .put(`users/${userData.id}`, userData, { cancelToken })
    .catch(pullErrorMessage)
    .catch(catchNameTakenError)
  return data
}

export const deleteUserById = async (id, cancelToken) => {
  const { data } = await requester()
    .delete(`users/${id}`, { cancelToken })
    .catch(pullErrorMessage)
  return data
}
