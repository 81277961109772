import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
  Box,
  Switch,
  Typography,
  makeStyles,
  FormControl,
  InputAdornment,
  Input,
  FormHelperText,
  InputLabel,
  NativeSelect,
  FormControlLabel,
} from "@material-ui/core";

import Rating from "@material-ui/lab/Rating";
import { TagInput, Editor } from "@/components";
import { createErrorProp } from "@/helpers";
import IngredientTag from "../IngredientTag";
import { minimalControls } from "@/components/Editor/controls";

const useStyles = makeStyles((theme) => ({
  root: {},
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  deleteButton: {
    color: "red",
  },
  button: {
    margin: 10,
  },
}));

const eventToValue = (callback) => (event) => callback(event.target.value);

const IngredientDetail = ({
  className,
  ingredient: {
    aliases,
    description,
    rating,
    main_name: mainName,
    tags,
    safetyLevel,
    isAncol,
    isFlavor,
    isHighlighting,
    highlightingName,
    includeMainName,
  },
  ingredientTagList,
  handleSaveAll,
  handleSubmit,
  handleDelete,
  updateField,
  updateBoolean,
  loading,
  error,
  mode,
  ...rest
}) => {
  const classes = useStyles();
  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <form
        autoComplete="off"
        noValidate
        onSubmit={(event) => event.preventDefault()}
      >
        <CardHeader
          subheader={
            mode === "create"
              ? "Create a new ingredient"
              : "Update this ingredient"
          }
          title="Ingredient"
        />
        <Divider />
        <CardContent>
          <Grid container spacing={3} alignItems={"center"}>
            <Grid item md={6} xs={12}>
              <TextField
                disabled={loading}
                fullWidth
                label="Main name"
                name="main_name"
                onChange={eventToValue(updateField("main_name"))}
                required
                value={mainName}
                variant="outlined"
                {...createErrorProp(
                  error,
                  ["names", "main_name"],
                  "Input the main name"
                )}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TagInput
                label="Aliases"
                value={aliases}
                onChange={updateField("aliases")}
                disabled={loading}
                autoSelect
                {...createErrorProp(
                  error,
                  ["names", "aliases"],
                  "Input some aliases"
                )}
                freeSolo
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <IngredientTag
                loading={loading}
                tags={tags}
                options={ingredientTagList}
                onChange={updateField("tags")}
                {...createErrorProp(
                  error,
                  ["tags"],
                  <span>
                    Input some tags. Click on a <b>newly created</b> tag to set
                    its safety level
                  </span>
                )}
              />
            </Grid>
            <Grid item md={3} xs={12}>
              <FormControl className={classes.formControl}>
                <InputLabel shrink htmlFor="safety-native-label-placeholder">
                  Safety Level
                </InputLabel>
                <NativeSelect
                  value={safetyLevel}
                  onChange={eventToValue(updateField("safetyLevel"))}
                  inputProps={{
                    name: "safety",
                    id: "safety-native-label-placeholder",
                  }}
                >
                  <option value={0}>Safety</option>
                  <option value={1}>Low dangerous</option>
                  <option value={2}>Medium dangerous</option>
                  <option value={3}>High dangerous</option>
                </NativeSelect>
              </FormControl>
            </Grid>

            <Grid item md={4} xs={12}>
              <Box display="flex">
                <Box
                  component="fieldset"
                  mb={3}
                  borderColor="transparent"
                  m="auto"
                >
                  <Typography component="legend">Ingredient rating</Typography>
                  <Rating
                    name="rating"
                    defaultValue={2.5}
                    precision={1}
                    onChange={eventToValue(updateField("rating"))}
                    value={rating}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item md={3} xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    disabled={loading}
                    checked={isAncol}
                    onChange={() => {
                      updateField("isAncol")(!isAncol);
                    }}
                    color="primary"
                  />
                }
                label="Chất chứa cồn"
              />
              <FormControlLabel
                control={
                  <Switch
                    disabled={loading}
                    checked={isFlavor}
                    onChange={() => {
                      updateField("isFlavor")(!isFlavor);
                    }}
                    color="primary"
                  />
                }
                label="Chất hương liệu"
              />
              <FormControlLabel
                control={
                  <Switch
                    disabled={loading}
                    checked={isHighlighting}
                    onChange={() => {
                      updateField("isHighlighting")(!isHighlighting);
                    }}
                    color="primary"
                  />
                }
                label="Chất nổi bật"
              />
            </Grid>

            {isHighlighting && (
              <Grid item md={3} xs={12}>
                <FormControl>
                  <Input
                    id="highlingtingName"
                    value={highlightingName}
                    onChange={eventToValue(updateField("highlightingName"))}
                    placeholder={includeMainName ? "Name" : mainName}
                    startAdornment={
                      includeMainName ? (
                        <InputAdornment position="start">
                          {mainName} (
                        </InputAdornment>
                      ) : undefined
                    }
                    endAdornment={
                      includeMainName ? (
                        <InputAdornment position="end">)</InputAdornment>
                      ) : undefined
                    }
                    aria-describedby="highlingtingName-helper"
                    inputProps={{
                      "aria-label": "weight",
                    }}
                  />
                  <FormHelperText id="highlingtingName-helper">
                    Tên này sẽ là tên xuất hiện tại phần Filter của Client, nếu
                    để trống, tên sẽ là tên chính của thành phần
                  </FormHelperText>
                </FormControl>
                <FormControlLabel
                  control={
                    <Switch
                      checked={includeMainName}
                      onChange={() => {
                        updateField("includeMainName")(!includeMainName);
                      }}
                      color="primary"
                    />
                  }
                  label="Bao gồm tên chính"
                />
              </Grid>
            )}
            <Grid item md={9} xs={12}>
              <Editor
                value={description}
                disabled={loading}
                controls={minimalControls}
                onChange={updateField("description")}
                paperProps={{ elevation: 5 }}
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            disabled={loading}
            color="primary"
            variant="contained"
            onClick={handleSubmit}
            className={classes.button}
          >
            Save
          </Button>
          {mode === "update" && (
            <>
              <Button
                disabled={loading}
                className={classes.button}
                onClick={handleSaveAll}
                color="primary"
                variant="contained"
              >
                Save all
              </Button>
              <Button
                disabled={loading}
                className={classes.button + " " + classes.deleteButton}
                onClick={handleDelete}
                variant="contained"
              >
                Delete
              </Button>
            </>
          )}
        </CardActions>
      </form>
    </Card>
  );
};

IngredientDetail.propTypes = {
  className: PropTypes.string,
};

export default IngredientDetail;
