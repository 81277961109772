import React from 'react'
import {withRouter} from 'react-router-dom'
import BraftEditor from 'callmeduy-braft-editor'
import Card from '@material-ui/core/Card'
import slugify from 'slugify'
import {PostDetail} from './components'
import api from '@/apis'
import {
    BadRequestError,
    ForbiddenError,
    NotFoundError,
    ValidateError
} from '@/apis/error'
import {withSnackbar} from '@/containers/SnackbarContainer'
import {validatePost} from '@/apis/post/validate'
import {ConfirmModal} from '@/components'
import {prepareData} from './utils'
import withStyles from '@material-ui/core/styles/withStyles'
import SaveButton from '@/components/SaveButton'

const styles = (theme) => ({
    paper: {
        margin: theme.spacing(4),
        padding: theme.spacing(4)
    }
})

const defaultData = {
    id: '',
    content: BraftEditor.createEditorState(''),
    title: '',
    slug: '',
    description: '',
    tags: [],
    published: false,
    thumbnail: null,
    level: null,
    priority: null
}

class PostSingle extends React.Component {
    constructor(props) {
        super(props)
        this.history = props.history
        this.classes = props.classes
        this.snackbar = props.snackbar
        this.state = {
            data: defaultData,
            autoSlug: true,
            options: [],
            error: {message: '', field: ''},
            showModal: false,
            tagList: [],
            loading: false,
            switch: false
        }
    }

    redirect = async () => {
        const {slug, mode} = this.props.match.params
        if (mode !== 'update' && mode !== 'create') {
            this.history.replace('/not-found')
        }
        if (mode === 'update' && !slug) {
            this.history.replace('/not-found')
        }
    };

    fetchTag = async () => {
        const tags = await api.postTag.http.getAllTags()
        this.setState({tagList: tags})
    };

    fetchPost = async () => {
        try {
            const data = await api.post.http.getPostBySlug(
                this.props.match.params.slug
            )
            data.content = BraftEditor.createEditorState(data.content)
            this.setState({data})
            this.originalData = data
        } catch (err) {
            if (err instanceof NotFoundError) {
                this.history.replace('/not-found')
                return this.snackbar.showMessage(
                    "The post you was trying to reach doesn't exist",
                    'error'
                )
            }
            throw err
        }
    };

    setDataField = (field) => {
        return (val) => {
            const {data, autoSlug} = this.state
            const newData = {...data, [field]: val}
            if (field === 'title' && autoSlug) {
                newData.slug = slugify(val.toLowerCase())
            } else if (field === 'slug' && autoSlug) return
            this.setState({data: newData})
        }
    };

    setStateField = (field) => (val) => {
        this.setState({[field]: val})
    };

    toggleAutoSlug = () => {
        const {autoSlug, data} = this.state
        if (!autoSlug) {
            const newData = {...data, slug: slugify(data.title.toLowerCase())}
            this.setState({data: newData})
        }
        this.setState({autoSlug: !autoSlug})
    };

    handleCreate = async (data) => {
        try {
            const post = await api.post.http.createPost(data)
            await this.snackbar.showMessage('Post created successfully', 'success')
            // post.content = BraftEditor.createEditorState(post.content)
            this.setState({data: post})
            this.originalData = data
            this.history.replace(`/posts/update/${data.slug}`)
        } catch (err) {
            if (err instanceof BadRequestError) {
                if (err.data?.field) {
                    this.setState({
                        error: new ValidateError(err.message, err.data.field)
                    })
                }
                return this.snackbar.showMessage(err.message, 'error')
            }
            throw err
        }
    };

    handleUpdate = async (data) => {
        try {
            const post = await api.post.http.updatePost(data, this.state.switch)
            await this.snackbar.showMessage('Post updated successfully', 'success')
            post.content = BraftEditor.createEditorState(post.content)
            // this.setState({ data: post })
            if (post.slug !== this.originalData.slug) {
                this.history.replace(`/posts/update/${post.slug}`)
            }
        } catch (err) {
            if (err instanceof NotFoundError) {
                return this.snackbar.showMessage(
                    "The post you are trying to update doesn't exist",
                    'error'
                )
            } else if (err instanceof BadRequestError) {
                if (err.data?.field) {
                    this.setState({
                        error: new ValidateError(err.message, err.data.field)
                    })
                }
                return this.snackbar.showMessage(err.message, 'error')
            }
            throw err
        }
    };

    handleDelete = async () => {
        try {
            await api.post.http.deletePost(this.state.data.id)
            await this.snackbar.showMessage('Post deleted successfully', 'success')
            this.history.replace('/posts')
        } catch (err) {
            if (err instanceof NotFoundError) {
                return this.snackbar.showMessage(
                    "The post you are trying to delete doesn't exist",
                    'error'
                )
            } else if (err instanceof ForbiddenError) {
                return this.snackbar.showMessage("You can't do that", 'error')
            }
            throw err
        }
    };

    handleSubmit = async () => {
        const {data} = this.state
        const {mode} = this.props.match.params
        const submitData = prepareData(data)
        this.setState({loading: true})
        try {
            validatePost(submitData)
            if (mode === 'create') {
                await this.handleCreate(submitData)
            } else if (mode === 'update') {
                await this.handleUpdate(submitData)
            }
        } catch (err) {
            if (err instanceof ValidateError || err instanceof ForbiddenError) {
                this.setState({error: err})
                return this.snackbar.showMessage(err.message, 'error')
            }
            throw err
        } finally {
            this.setState({loading: false})
        }
    };

    componentDidMount = async () => {
        this.redirect()
        const {mode} = this.props.match.params
        this.setState({loading: true})
        if (mode === 'update') {
            await Promise.all([this.fetchPost(), this.fetchTag()])
        } else {
            await this.fetchTag()
        }
        this.setState({loading: false})
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps !== this.props) {
            this.redirect()
        }
    }

    setModal = (value) => () => {
        this.setState({showModal: value})
    };

    onChangePublished = () => {
        this.setState({switch: true})
    };

    render() {
        const {
            data,
            error,
            showModal,
            autoSlug,
            options,
            loading,
            tagList
        } = this.state
        return (
            <Card className={this.classes.paper}>
                <ConfirmModal
                    show={showModal}
                    handleClose={this.setModal(false)}
                    handleConfirm={this.handleDelete}
                    message="Are you sure you want to delete this post?"
                />
                <PostDetail
                    data={data}
                    tagList={tagList}
                    loading={loading}
                    setField={this.setDataField}
                    error={error}
                    mode={this.props.match.params.mode}
                    autoSlug={autoSlug}
                    toggleAutoSlug={this.toggleAutoSlug}
                    options={options}
                    handleSubmit={this.handleSubmit}
                    handleDelete={this.setModal(true)}
                    onChangePublished={this.onChangePublished}
                />

                <SaveButton onSave={this.handleSubmit}/>
            </Card>
        )
    }
}

export default withRouter(withSnackbar(withStyles(styles)(PostSingle)))
