import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";

import { UsersTable } from "./components";
import { createCanceller } from "@/apis/requester";
import axios from "axios";
import moment from "moment";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
}));

function truncate(str, n) {
  return str.length > n ? str.substr(0, n - 1) + "..." : str;
}
const UserList = ({ snackbar }) => {
  const classes = useStyles();

  const [notification, setNotification] = useState([]);
  const [count, setCount] = useState(0);

  const [page, setPage] = useState(0);
  const [numberOfPage, setNumberOfPage] = useState(10);

  useEffect(() => {
    let canceller = createCanceller();
    axios
      .get("https://admin.callmeduy.com/webhook/comments/count")
      .then((res) => {
        setCount(parseInt(res.data));
        canceller = null;
      });
    return () => {
      canceller &&
        canceller.cancel("count comment cancelled because of route change");
    };
  }, []);
  useEffect(() => {
    let canceller = createCanceller();
    axios
      .get("https://admin.callmeduy.com/webhook/comments", {
        params: {
          limit: numberOfPage,
          offset: page * numberOfPage,
        },
      })
      .then((res) => {
        setNotification([
          ...notification,
          ...res.data.map((v) => ({
            ...v,
            content: truncate(v?.content, 100),
            type: v?.type === "NEW_COMMENT" ? "New comment" : "Reply Comment",
            created_date: moment(v?.created_date).format("DD/MM/YYYY HH:mm"),
          })),
        ]);
        canceller = null;
      });
    return () => {
      canceller &&
        canceller.cancel("get Comments cancelled because of route change");
    };
  }, [numberOfPage, page]);
  return (
    <div className={classes.root}>
      <div className={classes.content}>
        {notification && (
          <UsersTable
            setPage={setPage}
            users={notification}
            count={count}
            setNumberOfPage={setNumberOfPage}
            setNotification={setNotification}
          />
        )}
      </div>
    </div>
  );
};
export default UserList;
