import React from 'react'
import { makeStyles, Grid, Typography } from '@material-ui/core'
import { setElement } from '../../utils'
import ImagePicker from '@/components/ImagePicker/ImagePicker'

const useStyles = makeStyles((theme) => ({
  container: {},
  image: {
    height: 200
  },
  title: {
    margin: theme.spacing(3)
  }
}))
const SponsorList = ({ sponsors, setSponsors, loading }) => {
  const classes = useStyles()
  return (
    <>
      <Typography variant="h1" align="center" className={classes.title}>
        Sponsors
      </Typography>
      <Grid container direction="row" spacing={2} className={classes.container}>
        {sponsors.map((sponsor, idx) => (
          <Grid item xs={6} md={2} key={idx}>
            <ImagePicker
              loading={loading}
              title="Upload sponsor image"
              className={classes.image}
              setImage={setElement(sponsors, idx, setSponsors)}
              image={sponsor}
              paperProps={{ elevation: 3 }}
            />
          </Grid>
        ))}
      </Grid>
    </>
  )
}
export default SponsorList
