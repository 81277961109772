import { STRAPI_URI } from '@/const'
import urljoin from 'url-join'

export const resolveURL = (path) => {
  if (typeof path !== 'string') {
    throw Error('Invalid argument')
  }
  if (isURL(path)) {
    return path
  }
  return urljoin(STRAPI_URI, path)
}

export const isURL = (path) => {
  const urlRegex = /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&=]*)/
  return urlRegex.test(path)
}
