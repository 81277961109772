import React, { useState, useEffect } from "react";
import MUIDataTable from "mui-datatables";
import { makeStyles } from "@material-ui/core";
import _ from "lodash";
import LoadingOverlay from "@/components/DataTable/LoadingOverlay";
import { createArray } from "@/helpers";

const useStyles = makeStyles((theme) => ({
  container: {
    position: "relative",
    overflow: "hidden",
  },
  row: {
    cursor: "pointer",
  },
}));

const DataTable = ({
  tag,
  handleFetchPage,
  title,
  handleCount,
  handleSearch,
  columns,
  elementToRow,
  pageSizeOptions = [10, 15, 20],
  options = {},
  ...rest
}) => {
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [sort, setSort] = useState(null);
  const [pageSize, setLimit] = useState(pageSizeOptions[0]);
  const [page, setPage] = useState(0);
  const [count, setCount] = useState(0);
  const [searchQuery, setSearchQuery] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    handleCount({ query: searchQuery }).then(setCount);
  }, [searchQuery]);

  useEffect(() => {
    setLoading(true);
    if (!searchQuery) {
      handleFetchPage(tag, { page, sort, limit: pageSize })
        .then(setData)
        .then(() => setLoading(false));
    } else {
      setSort(null);
      handleSearch({ page, limit: pageSize, query: searchQuery })
        .then(setData)
        .then(() => setLoading(false));
    }
  }, [page, sort, pageSize, searchQuery]);

  const tableOptions = {
    page,
    count,
    selectableRows: "none",
    serverSide: true,
    selectableRowsHeader: false,
    rowsPerPageOptions: pageSizeOptions,
    rowsPerPage: pageSize,
    searchText: searchQuery,
    setRowProps: () => ({
      className: classes.row,
    }),
    onSearchChange: _.debounce((query) => {
      setSearchQuery(query);
    }, 500),
    onColumnSortChange: _.debounce((col, dir) => {
      const sortString = `${col}:${dir
        .slice(0, -"ending".length)
        .toUpperCase()}`;
      if (sortString !== sort) {
        setSort(sortString);
      }
    }, 500),
    onTableChange: (action, tableState) => {
      if (action === "changePage") {
        setLoading(true);
        setPage(tableState.page);
      }
    },

    onChangeRowsPerPage: setLimit,
    ...options,
  };
  // The string below is actually not a space, but some kinda empty character
  const nullData = createArray(pageSize, createArray(columns.length, " "));
  return (
    <div className={classes.container}>
      <LoadingOverlay loading={loading} />
      <MUIDataTable
        title={title}
        data={loading ? nullData : data.map(elementToRow)}
        columns={columns}
        options={tableOptions}
        {...rest}
      />
    </div>
  );
};

export default DataTable;
