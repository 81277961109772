import { ValidateError } from '@/apis/error'
import { isURL } from '@/apis/utils'

export const validateBrandData = ({ name, url }) => {
  if (name === '') {
    throw new ValidateError('Specify a name for this brand', 'name')
  }
  if (url !== '' && !isURL(url)) {
    throw new ValidateError(
      'Specify a valid URL for this brand, or none at all',
      'name'
    )
  }
}
