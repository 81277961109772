import Axios from 'axios'
import * as errorTypes from './error'
export * from './error'

const handleResponseError = (err) => {
  let { data, status } = err.response
  if (data instanceof Array) {
    data = data[0]
  }

  let { message } = data
  try {
    if (message instanceof Array) {
      message = message[0].messages[0].message
    }
  } catch (parseError) {
    message = JSON.stringify(message)
  }

  switch (status) {
    case 400:
      throw new errorTypes.BadRequestError(message, data)
    case 404:
      throw new errorTypes.NotFoundError(message, data)
    case 403:
      throw new errorTypes.ForbiddenError(message, data)
    default:
      throw new Error(message)
  }
}

export const pullErrorMessage = (err) => {
  if (Axios.isCancel(err)) {
    return console.log('Request cancelled, reason: ', err.message)
  }
  if (err.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    handleResponseError(err)
  } else if (err.request) {
    // The request was made but no response was received
    // `err.request` is an instance of XMLHttpRequest in the browser and an instance of
    throw new errorTypes.UnableToConnectError()
  } else {
    // Something happened in setting up the request that triggered an Error
    throw err
  }
}
