import React, { forwardRef } from 'react'
import UserContainer from '@/containers/UserContainer/UserContainer'
import { makeStyles } from '@material-ui/core'
import InputIcon from '@material-ui/icons/Input'
import { NavLink as RouterLink } from 'react-router-dom'
import NavButton from './NavButton'
import NestedList from './NestedList'

const useStyles = makeStyles((theme) => ({
  active: {
    fontWeight: theme.typography.fontWeightMedium,
    '& *': {
      color: theme.palette.primary.main
    }
  }
}))

const CustomRouterLinkComponent = (props, ref) => {
  return (
    <div ref={ref} style={{ flexGrow: 1 }}>
      <RouterLink {...props} />
    </div>
  )
}

const LinkButton = ({ page }) => {
  if (!UserContainer.isAllowed(page.href)) {
    return null
  }
  const classes = useStyles()
  return (
    <NavButton
      activeClassName={classes.active}
      component={forwardRef(CustomRouterLinkComponent)}
      to={page.href}
      startIcon={page.icon}
      title={page.title}
    />
  )
}

const NavItem = ({ page }) => {
  return (
    <div>
      {page.children ? <NestedList page={page} /> : <LinkButton page={page} />}
    </div>
  )
}

const LogoutNavItem = () => {
  return (
    <NavButton
      startIcon={<InputIcon />}
      title="Logout"
      onClick={UserContainer.logout}
    />
  )
}

export default NavItem
export { LogoutNavItem }
