import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { List, Hidden, makeStyles } from '@material-ui/core'
import NavItem, { LogoutNavItem } from './NavItem'

const useStyles = makeStyles((theme) => ({
  root: {}
}))

const SidebarNav = (props) => {
  const { pages, className, ...rest } = props

  const classes = useStyles()

  return (
    <List {...rest} className={clsx(classes.root, className)}>
      {pages.map((page) => {
        return <NavItem key={page.title} page={page} />
      })}

      <Hidden lgUp>
        <LogoutNavItem />
      </Hidden>
    </List>
  )
}

SidebarNav.propTypes = {
  className: PropTypes.string,
  pages: PropTypes.array.isRequired
}

export default SidebarNav
