import React, { useState } from 'react'
import { InputAdornment, IconButton, TextField } from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons'

const PasswordInput = (props) => {
  const [visible, setVisible] = useState(false)
  return (
    <TextField
      {...props}
      type={visible ? 'text' : 'password'}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => setVisible(!visible)}
              onMouseDown={(event) => event.preventDefault()}
            >
              {visible ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        )
      }}
    />
  )
}

export default PasswordInput
