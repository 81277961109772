import React, { useState } from 'react'
import NavItem from './NavItem'
import { List, Collapse, makeStyles } from '@material-ui/core'
import NavButton from './NavButton'
import ExpandIcon from '@material-ui/icons/ExpandMore'
import _ from 'lodash'
import UserContainer from '@/containers/UserContainer'
const useStyles = makeStyles((theme) => ({
  drawer: {
    marginLeft: theme.spacing(3)
  },
  expanded: {
    transform: 'rotate(180deg)',
    transition: '0.5s'
  },
  collapsed: {
    transform: 'rotate(0deg)',
    transition: '0.5s'
  }
}))
const ListTitleButton = ({ page, onClick, open }) => {
  const classes = useStyles()
  const expandIcon = (
    <ExpandIcon className={open ? classes.expanded : classes.collapsed} />
  )
  return (
    <NavButton
      title={page.title}
      startIcon={page.icon}
      endIcon={expandIcon}
      onClick={onClick}
    />
  )
}

const NestedList = ({ page }) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const toggleOpen = () => setOpen(!open)
  const noneAllowed = _.every(
    page.children,
    (page) => !UserContainer.isAllowed(page.href)
  )
  if (noneAllowed) {
    return null
  }
  return (
    <div>
      <ListTitleButton onClick={toggleOpen} page={page} open={open} />
      <div className={classes.drawer}>
        {page.children && (
          <Collapse
            addEndListener={() => {}}
            in={open}
            timeout={500}
            unmountOnExit
          >
            <List>
              {page.children.map((child, idx) => {
                return <NavItem page={child} key={idx} />
              })}
            </List>
          </Collapse>
        )}
      </div>
    </div>
  )
}

export default NestedList
