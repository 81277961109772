import React from 'react'
import SingleBanner from '../SingleBanner'
import { makeStyles, Grid, Typography } from '@material-ui/core'
import { setElement } from '../../utils'

const useStyles = makeStyles((theme) => ({
  container: {},
  banner: {},
  title: {
    margin: theme.spacing(3)
  },
  image: {
    minHeight: 300
  }
}))
const SubBannerList = ({ banners, setBanners, loading }) => {
  const classes = useStyles()
  return (
    <>
      <Typography variant="h1" align="center" className={classes.title}>
        Sub banners
      </Typography>
      <Grid container direction="row" spacing={3} className={classes.container}>
        {banners.map((banner, idx) => (
          <Grid item xs={12} md={4} key={idx}>
            <SingleBanner
              loading={loading}
              className={classes.banner}
              banner={banner}
              setBanner={setElement(banners, idx, setBanners)}
              imageClassName={classes.image}
            />
          </Grid>
        ))}
      </Grid>
    </>
  )
}
export default SubBannerList
