import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
  InputAdornment,
  IconButton
} from '@material-ui/core'
import { createErrorProp } from '@/helpers'
import { Link, LinkOff } from '@material-ui/icons'

const useStyles = makeStyles((theme) => ({
  root: {},
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  deleteButton: {
    color: 'red'
  },
  button: {
    margin: 10
  }
}))

const eventToValue = (callback) => (event) => callback(event.target.value)

const PostTagDetail = ({
  postTag: { name, slug },
  className,
  handleSubmit,
  handleDelete,
  updateField,
  autoSlug,
  loading,
  error,
  toggleAutoSlug,
  mode,
  ...rest
}) => {
  const classes = useStyles()
  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <form autoComplete="off" noValidate>
        <CardHeader
          subheader={
            mode === 'create' ? 'Create a new post tag' : 'Update this post tag'
          }
          title="PostTag"
        />
        <Divider />
        <CardContent>
          <Grid container spacing={3} alignItems={'center'}>
            <Grid item md={6} xs={12}>
              <TextField
                disabled={loading}
                fullWidth
                label="Name"
                name="name"
                onChange={eventToValue(updateField('name'))}
                required
                value={name}
                variant="outlined"
                {...createErrorProp(
                  error,
                  ['name'],
                  'Specify a name for this product'
                )}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                label="Slug"
                variant="outlined"
                disabled={autoSlug}
                value={slug}
                {...createErrorProp(
                  error,
                  ['slug'],
                  'Toggle the button to specify your own slug'
                )}
                fullWidth
                onChange={eventToValue(updateField('slug'))}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position={'start'}>
                      <IconButton
                        aria-label="Auto generate slug"
                        onClick={toggleAutoSlug}
                      >
                        {autoSlug ? <LinkOff /> : <Link />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            disabled={loading}
            color="primary"
            variant="contained"
            onClick={handleSubmit}
            className={classes.button}
          >
            Save
          </Button>
          {mode === 'update' && (
            <Button
              disabled={loading}
              className={classes.button + ' ' + classes.deleteButton}
              onClick={handleDelete}
              variant="contained"
            >
              Delete
            </Button>
          )}
        </CardActions>
      </form>
    </Card>
  )
}

PostTagDetail.propTypes = {
  className: PropTypes.string
}

export default PostTagDetail
