export class HTTPError extends Error {
  constructor (message, code, data) {
    super()
    this.message = message
    this.code = code
    this.data = data
  }
}
export class NotFoundError extends HTTPError {
  constructor (message, data) {
    super(message, 404, data)
  }
}
export class BadRequestError extends HTTPError {
  constructor (message, data) {
    super(message, 400, data)
  }
}
export class ForbiddenError extends HTTPError {
  constructor (message, data) {
    super(message, 403, data)
  }
}
export class UnableToConnectError extends HTTPError {
  constructor (message, data) {
    super(message, 503, data)
  }
}
export class ValidateError extends Error {
  constructor (message, field) {
    super()
    this.message = message
    this.field = field
  }
}
