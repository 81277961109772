import Axios from 'axios'
import { STRAPI_URI } from '@/const'
import UserContainer from '@/containers/UserContainer/'
import qs from 'qs'

const requester = () => {
  return Axios.create({
    baseURL: STRAPI_URI,
    headers: {
      Authorization: 'Bearer ' + UserContainer.getJwt()
    },
    paramsSerializer: (params) => {
      return qs.stringify(params, { arrayFormat: 'repeat', skipNulls: true })
    }
  })
}

const createCanceller = () => {
  return Axios.CancelToken.source()
}

export default requester
export { createCanceller }
