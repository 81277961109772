import { parseTime } from '@/helpers'

export const columns = [
  {
    name: 'slug',
    label: 'Slug',
    options: {
      filter: false,
      sort: true
    }
  },
  {
    name: 'title',
    label: 'Title',
    options: {
      filter: false,
      sort: true
    }
  },
  {
    name: 'created_at',
    label: 'Created At',
    options: {
      filter: false,
      sort: true
    }
  },
  {
    name: 'updated_at',
    label: 'Updated at',
    options: {
      filter: false,
      sort: true
    }
  },
  {
    name: 'author',
    label: 'Author',
    options: {
      filter: true,
      sort: false
    }
  },
  {
    name: 'published',
    label: 'Status',
    options: {
      filter: true,
      sort: true
    }
  }
]
export function postToRow (postData) {
  return [
    postData.slug,
    postData.title,
    parseTime(postData.created_at),
    parseTime(postData.updated_at),
    postData.author.name,
    postData.published ? 'Published' : 'Unpublished'
  ]
}
