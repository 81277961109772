import requester from '../requester'
import { pullErrorMessage } from '../error'
import { catchTagExistError } from '@/apis/ingredientTag/error'

export const getAllIngredientTags = async (
  { page, sort, limit = 100 } = { page: 0 }
) => {
  const offset = page * limit
  const config = { params: { _start: offset, _limit: limit, _sort: sort } }
  const { data } = await requester()
    .get('chemical-tags/', config)
    .catch(pullErrorMessage)
  return data
}

export const countIngredientTags = async ({ title } = {}) => {
  if (!title) {
    title = null
  }
  const body = { title_contains: title }
  const { data } = await requester()
    .get('chemical-tags/count', { params: body })
    .catch(pullErrorMessage)
  return data
}

export const searchTagsContainTitle = async ({ title }) => {
  if (!title) {
    title = null
  }
  const body = { _limit: 50, title_contains: title }
  const { data } = await requester()
    .get('chemical-tags/', { params: body })
    .catch(pullErrorMessage)
  return data
}

export const getIngredientTagByID = async (id) => {
  const { data } = await requester()
    .get(`chemical-tags/${id}`)
    .catch(pullErrorMessage)
  return data
}

export const updateIngredientTag = async (data) => {
  const { id } = data
  await requester()
    .put(`chemical-tags/${id}`, data)
    .catch(pullErrorMessage)
    .catch(catchTagExistError)
}

export const createIngredientTag = async (ingredientTagData) => {
  const { data } = await requester()
    .post('chemical-tags/', ingredientTagData)
    .catch(pullErrorMessage)
    .catch(catchTagExistError)
  return data
}

export const deleteIngredientTag = async (id) => {
  const { data } = requester()
    .delete(`chemical-tags/${id}`)
    .catch(pullErrorMessage)
  return data
}
