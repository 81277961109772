import React from 'react'
import TreeItem from '@material-ui/lab/TreeItem'
import Item from './Item'

const CategoryEntry = ({ category, toggleExpanded, totalCount }) => {
  let { subcategories, id } = category
  if (!subcategories || !(subcategories instanceof Array)) {
    subcategories = []
  }
  return (
    <TreeItem
      label={
        <Item
          category={category}
          onClick={toggleExpanded(id)}
          totalCount={totalCount}
        />
      }
      key={id}
      nodeId={`${id}`}
    >
      {subcategories.map((sub) => {
        return sub ? (
          <CategoryEntry
            totalCount={totalCount}
            category={sub}
            key={sub.id}
            toggleExpanded={toggleExpanded}
          />
        ) : null
      })}
    </TreeItem>
  )
}

export default CategoryEntry
