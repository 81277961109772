import React from 'react'
import {
  InputAdornment,
  Paper,
  Typography,
  TextField,
  List,
  ListItem,
  Button,
  IconButton
} from '@material-ui/core'
import { Delete } from '@material-ui/icons'
import { createErrorProp } from '@/helpers'

const URLInput = ({ onChange, value, error, ...rest }) => {
  const handleChangeInput = (id) => (event) => {
    const res = [
      ...value.slice(0, id),
      event.target.value,
      ...value.slice(id + 1)
    ]
    onChange(res)
  }
  const handleAddInput = () => {
    onChange([...value, ''])
  }
  const handleRemoveInput = (id) => () => {
    onChange([...value.slice(0, id), ...value.slice(id + 1)])
  }
  const { helperText, error: isError } = createErrorProp(
    error,
    ['url'],
    'Set some links for this products'
  )
  if (!(value instanceof Array)) {
    return <Paper />
  }
  return (
    <Paper>
      <br />
      <Typography
        variant="h4"
        align="center"
        color={isError ? 'error' : 'textPrimary'}
      >
        {helperText}
      </Typography>
      <List>
        {value.map((value, idx) => (
          <ListItem key={idx}>
            <TextField
              value={value}
              {...rest}
              {...createErrorProp(
                error,
                [`url[${idx}]`],
                "Set this product's link"
              )}
              onChange={handleChangeInput(idx)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleRemoveInput(idx)}>
                      <Delete />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          </ListItem>
        ))}
        <ListItem>
          <Button onClick={handleAddInput}>Add</Button>
        </ListItem>
      </List>
    </Paper>
  )
}

export default URLInput
