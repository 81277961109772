import requester from "../requester";
import { pullErrorMessage } from "../error";
import * as file from "../file";
import _ from "lodash";

export const getAllProducts = async (
  tag,
  { page, sort, limit = 10 } = { page: 0 }
) => {
  const offset = page * limit;
  const config = { params: { _start: offset, _limit: limit, _sort: sort } };
  let { data } = await requester()
    .get("products/", config)
    .catch(pullErrorMessage);

  data = data.map((item) => ({
    ...item,
    url: JSON.parse(item.url),
  }));
  return data;
};

export const getProductById = async (id) => {
  const { data } = await requester()
    .get(`products/${id}`)
    .catch(pullErrorMessage);

  data.url = JSON.parse(data.url);
  return data;
};

export const createProduct = async (productData) => {
  if (productData.thumbnail && productData.thumbnail instanceof File) {
    productData.thumbnail = await file.http.uploadOne(productData.thumbnail);
  }
  productData.url = JSON.stringify(_.compact(productData.url));
  const { data } = await requester()
    .post("products/", productData)
    .catch(pullErrorMessage);
  data.url = JSON.parse(data.url);
  return data;
};

export const deleteProduct = async (id) => {
  const { data } = await requester()
    .delete(`products/${id}`)
    .catch(pullErrorMessage);
  if (data.thumbnail) {
    await file.http.deleteOne(data.thumbnail.id);
  }
  return data;
};

export const updateProduct = async (productData) => {
  if (productData.thumbnail && productData.thumbnail instanceof File) {
    productData.thumbnail = await file.http.uploadOne(productData.thumbnail);
  }
  productData.url = JSON.stringify(_.compact(productData.url));
  const { data } = await requester()
    .put(`products/${productData.id}`, productData)
    .catch(pullErrorMessage);
  data.url = JSON.parse(data.url);
  return data;
};

export const searchProductByName = async ({ name }) => {
  if (!name) {
    name = null;
  }
  const body = { _limit: 50, name_contains: name };
  const { data } = await requester()
    .get("products/", { params: body })
    .catch(pullErrorMessage);
  // console.log(data);
  // data.url = JSON.parse(data.url);
  return data;
};

export const countProduct = async ({ name }) => {
  if (!name) {
    name = null;
  }
  const body = { name_contains: name };
  const { data } = await requester()
    .get("products/count", { params: body })
    .catch(pullErrorMessage);
  return data;
};
