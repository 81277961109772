import React, { useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { resolveURL } from '@/apis/utils'
import { Paper, IconButton, Modal, Fade } from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear'
import SearchIcon from '@material-ui/icons/Search'
import { withSnackbar } from '@/containers/SnackbarContainer'

const useStyle = makeStyles((theme) => ({
  container: {
    position: 'relative',
    backgroundImage:
      'linear-gradient(45deg, #D0D0D0 25%, transparent 25%), linear-gradient(-45deg, #D0D0D0 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #D0D0D0 75%), linear-gradient(-45deg, transparent 75%, #D0D0D0 75%)',
    backgroundSize: '20px 20px',
    backgroundPosition: '0 0, 0 10px, 10px -10px, -10px 0px'
  },
  media: {
    height: '100%',
    width: '100%',
    position: 'absolute',
    objectFit: 'contain',
    margin: 'auto',
    borderRadius: 10,
    zIndex: 1
  },
  overlay: {
    height: '100%',
    width: '100%',
    position: 'absolute',
    zIndex: 10,
    transition: '0.5s',
    opacity: 0,
    backgroundColor: 'rgba(255,255,255,0)',
    '&:hover': {
      opacity: 1,
      cursor: 'zoom-in',
      backgroundColor: 'rgba(0,0,0,0.5)',
      transition: '0.5s'
    }
  },
  action: {
    justifyContent: 'center'
  },
  button: {
    position: 'absolute',
    right: 1,
    top: 1,
    color: 'white'
  },
  modalMedia: {
    maxWidth: '80%',
    maxHeight: '80%',
    objectFit: 'contain',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%);',
    backgroundColor: theme.palette.background.paper,
    fontFamily: 'Roboto, sans-serif',
    textAlign: 'center',
    padding: theme.spacing(4, 2),
    outline: 'none',
    '&:hover': {
      cursor: 'zoom-out'
    }
  },
  searchIcon: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%);',
    color: 'white'
  }
}))

const getUrl = (image) => {
  if (image) {
    if (image.url) {
      return resolveURL(image.url)
    } else if (image instanceof File) {
      return URL.createObjectURL(image)
    }
  }
}

const FilePreview = ({ setImage, image, alt, className, paperProps }) => {
  const classes = useStyle()
  const url = useMemo(() => getUrl(image), [image])
  const [showModal, setShowModal] = useState(false)
  return (
    <>
      <Modal open={showModal} onClose={() => setShowModal(false)}>
        <Fade in={showModal} exit={!showModal}>
          <img
            className={classes.modalMedia}
            src={url}
            alt={alt}
            onClick={() => setShowModal(false)}
          />
        </Fade>
      </Modal>
      <Paper className={className + ' ' + classes.container} {...paperProps}>
        <div className={classes.overlay} onClick={() => setShowModal(true)}>
          <IconButton onClick={() => setImage(null)} className={classes.button}>
            <ClearIcon />
          </IconButton>
          <SearchIcon fontSize="large" className={classes.searchIcon} />
        </div>
        <img className={classes.media} src={url} alt={alt} />
      </Paper>
    </>
  )
}

export default withSnackbar(FilePreview)
