import Axios from 'axios'
import { ValidateError, BadRequestError, pullErrorMessage } from '../error'
import { resolveURL } from '../utils'

export const login = async ({ username, password }) => {
  return Axios.post(resolveURL('/auth/local'), {
    identifier: username,
    password
  })
    .catch(pullErrorMessage)
    .catch((err) => {
      if (err instanceof BadRequestError) {
        throw new ValidateError('Wrong username or password', 'all')
      }
      throw err
    })
}
