import { makeStyles } from '@material-ui/styles'
export const dangerColor = {
  low: '#7ACDF6',
  medium: '#FFD585',
  high: '#ff9d85',
  unknown: '#BDBDBD'
}

const levelToId = { high: 0, medium: 1, low: 2, unknown: 3 }
export const dangerLevels = ['high', 'medium', 'low', 'unknown']

export const nextLevel = (level) => {
  const id = levelToId[level]
  return dangerLevels[(id + 1) % dangerLevels.length]
}

export const theme = {
  high: {
    backgroundColor: dangerColor.high
  },
  medium: {
    backgroundColor: dangerColor.medium
  },
  low: {
    backgroundColor: dangerColor.low
  },
  unknown: {
    backgroundColor: dangerColor.unknown
  }
}

export const useDangerLevelStyle = makeStyles(theme)
