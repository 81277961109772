import React, { useCallback, useMemo } from "react";
import { makeStyles, Grid, Typography, TextField } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import update from "immutability-helper";
import api from "@/apis";
import _ from "lodash";
const useStyles = makeStyles((theme) => ({
  container: {},
  title: {
    margin: theme.spacing(3),
  },
}));
const FeatureProduct = ({ loading, featureProduct, setFeatureProduct }) => {
  const classes = useStyles();
  const onBlur = useCallback(
    (i) => () => {
      if (!featureProduct[i]?.name) {
        setFeatureProduct(
          update(featureProduct, {
            [i]: { $merge: { error: true, name: undefined } },
          })
        );
      }
    },
    [featureProduct]
  );
  const getProdById = useCallback(async (newArray, id, i) => {
    const product = await api.product.http.getProductById(id);
    if (product?.name)
      setFeatureProduct(
        update(newArray, {
          [i]: {
            $merge: { name: product?.name, error: false, id: product?.id },
          },
        })
      );
  }, []);
  const onDebounce = useMemo(() =>
    _.debounce((i, value) => {
      try {
        const newArray = update(featureProduct, {
          [i]: { $merge: { url: value, error: false, name: "" } },
        });
        setFeatureProduct(newArray);
        const id = new URL(value).pathname.split(/san-pham\/(\d+)/)[1];
        getProdById(newArray, id, i);
      } catch (e) {}
    }, 250)
  );

  const onChange = (i) => (e) => {
    onDebounce(i, e?.target?.value);
  };

  const onClickAdd = useCallback(() => {
    setFeatureProduct(
      update(featureProduct, {
        $push: [{}],
      })
    );
  }, [featureProduct]);
  return (
    <>
      <Typography variant="h1" align="center" className={classes.title}>
        Feature Production
      </Typography>
      <Grid
        container
        direction="row"
        alignContent="center"
        spacing={2}
        className={classes.container}
      >
        {featureProduct.map((product, i) => (
          <Grid item xs={6} md={2} key={i}>
            <TextField
              defaultValue={product?.url}
              onChange={onChange(i)}
              onBlur={onBlur(i)}
              error={product?.error}
              fullWidth
              label="Link"
              name="link"
              helperText={product?.error ? "Invalid URL" : product?.name}
            />
          </Grid>
        ))}
        <IconButton onClick={onClickAdd}>
          <AddIcon />
        </IconButton>
      </Grid>
    </>
  );
};
export default FeatureProduct;
