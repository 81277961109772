import React from "react";
import { makeStyles } from "@material-ui/styles";
import { useHistory } from "react-router-dom";

import { BrandToolbar, brandToRow, columns } from "./components";

import api from "@/apis";
import DataTable from "@/components/DataTable/DataTable";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  content: {
    marginTop: theme.spacing(2),
  },
}));

const BrandList = () => {
  const classes = useStyles();
  const history = useHistory();
  const options = {
    download: false,
    print: false,
    viewColumns: false,
    onRowClick: (rowData) => {
      return history.push("/brands/update/" + rowData[0]);
    },
    selectableRows: "none",
    selectableRowsHeader: false,
  };

  const handleCount = async ({ query }) => {
    return api.brand.http.countBrands({ name: query });
  };

  const handleFetchPage = async (tag, { page, limit, sort }) => {
    return api.brand.http.getAllBrands({ page, limit, sort });
  };

  const handleSearch = async ({ query }) => {
    return api.brand.http.searchBrandsByName({ name: query });
  };

  return (
    <div className={classes.root}>
      <BrandToolbar />
      <div className={classes.content}>
        <DataTable
          title="Brands"
          elementToRow={brandToRow}
          columns={columns}
          options={options}
          handleCount={handleCount}
          handleFetchPage={handleFetchPage}
          handleSearch={handleSearch}
        />
      </div>
    </div>
  );
};

export default BrandList;
