import React from 'react'
import { makeStyles, colors, Button, ListItem } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  item: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  button: {
    color: colors.blueGrey[800],
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightMedium
  },
  icon: {
    color: theme.palette.icon,
    width: 24,
    height: 24,
    display: 'flex',
    alignItems: 'center'
  },
  title: {
    marginLeft: theme.spacing(1),
    display: 'flex',
    flexGrow: 10
  }
}))
const NavButton = ({ startIcon, endIcon, title, ...others }) => {
  const classes = useStyles()
  return (
    <ListItem className={classes.item} disableGutters>
      <Button className={classes.button} {...others}>
        <div className={classes.icon}>{startIcon}</div>
        <div className={classes.title}>{title}</div>
        <div className={classes.icon}>{endIcon}</div>
      </Button>
    </ListItem>
  )
}

export default NavButton
