import React, { useEffect } from 'react'
import { Route } from 'react-router-dom'
import PropTypes from 'prop-types'

const RouteWithLayout = ({
  layout: Layout,
  component: Component,
  title = 'Dashboard',
  layoutProps = {},
  ...rest
}) => {
  useEffect(() => {
    document.title = 'Callmeduy Admin | ' + title
  }, [title])
  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <Layout {...layoutProps}>
          <Component {...matchProps} />
        </Layout>
      )}
    />
  )
}

RouteWithLayout.propTypes = {
  component: PropTypes.any.isRequired,
  layout: PropTypes.any.isRequired,
  path: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ])
}

export default RouteWithLayout
