import React, { useState } from "react";
import { TextField, Button, makeStyles } from "@material-ui/core";
import slugify from "slugify";
import axios from "axios";
import _ from "lodash";
import { STRAPI_URI } from "../../../../../const";
const useStyles = makeStyles({
  addIngs: {
    float: "right",
    marginTop: "20px",
  },
});
export default function IngredientInput({ ingredients, onChange }) {
  const classes = useStyles();
  const [ingsString, setIngsString] = useState("");
  const handleClick = async () => {
    if (ingsString === "") return;

    let ingsList = ingsString.split(",");
    ingsList = _.map(ingsList, (v) => {
      if (_.includes(v, "/")) {
        return _.map(v.split("/"), (v_) => _.trim(v_));
      }
      return _.trim(v);
    });

    const ingsSlug = _.map(ingsList, (v) => {
      if (_.isArray(v)) {
        return _.map(v, (v_) =>
          slugify(v_.toLowerCase(), { remove: /[*+~.()'"!:@]/g })
        );
      }
      return slugify(v.toLowerCase(), { remove: /[*+~.()'"!:@]/g });
    });

    // console.log(ingsSlug)

    const resultFromDb = (
      await axios.get(
        `${STRAPI_URI}/chemical-names?${_.map(
          _.flattenDeep(ingsSlug),
          (v) => `name_slug=${v}`
        ).join("&")}`
      )
    ).data;

    let ingsNew = _.map(ingsSlug, (v, i) => {
      if (_.isArray(v)) {
        return _.map(v, (v_, i_) => {
          const dt = _.find(resultFromDb, { name_slug: v_ });
          if (dt) return dt;
          return {
            "name-slug": v_,
            name: ingsList[i][i_],
            // productId
          };
        });
      }
      const dt = _.find(resultFromDb, { name_slug: v });
      if (dt) return dt;
      return {
        "name-slug": v,
        name: ingsList[i],
        // productId
      };
    });

    const temp = [];
    _.forEach(ingsNew, (v) => {
      if (_.isArray(v)) {
        let add = true;
        for (let i = 0; i < v.length; i++) {
          const ing = v[i];
          if (
            _.includes(Object.keys(ing), "chemical") &&
            _.find(ingredients, {
              id: ing.chemical.id,
            })
          ) {
            add = false;
            break;
          }
        }
        if (add) {
          let tp = v[0];
          for (let i = 0; i < v.length; i++) {
            const ing = v[i];
            if (_.includes(Object.keys(ing), "chemical")) {
              tp = ing;
              break;
            }
          }
          if (_.includes(Object.keys(tp), "chemical")) {
            temp.push(tp.chemical);
          } else {
            temp.push({ names: [{ name: v[0].name }], ...v[0] });
          }
        }
      } else {
        if (
          !_.find(ingredients, {
            id: v.chemical?.id || "",
          })
        ) {
          if (_.includes(Object.keys(v), "chemical")) {
            temp.push(v.chemical);
          } else {
            temp.push({ names: [{ name: v.name }], ...v });
          }
        }
      }
    });
    ingsNew = [...ingredients, ...temp];

    // ingsNew = _.uniqBy([...ingredients, ...temp], function (e) {
    //   return e['name-slug'];
    // });
    onChange(ingsNew);
  };
  const handleChange = (event) => {
    const value = event.target.value;
    setIngsString(value);
  };
  return (
    <>
      <TextField
        label="Ingredients"
        placeholder="Ingredients"
        multiline
        fullWidth
        value={ingsString}
        onChange={handleChange}
      />
      <Button
        variant="contained"
        className={classes.addIngs}
        onClick={handleClick}
      >
        Add ingredients
      </Button>
    </>
  );
}
