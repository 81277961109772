import { useState, useEffect } from 'react'
import { countIngredientTags } from './http'

export const useIngredientTagCount = () => {
  const [count, setCount] = useState(0)
  useEffect(() => {
    countIngredientTags().then(setCount)
  }, [])
  return count
}
