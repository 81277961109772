import React from 'react'
import { makeStyles } from '@material-ui/styles'

import {
  IngredientTagToolbar,
  columns,
  ingredientTagToRow
} from './components'
import { useHistory } from 'react-router-dom'
import DataTable from '@/components/DataTable/DataTable'
import api from '@/apis'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}))

const IngredientTagList = () => {
  const classes = useStyles()
  const history = useHistory()
  const options = {
    download: false,
    print: false,
    viewColumns: false,
    onRowClick: (rowData) => {
      return history.push('/ingredient-tags/update/' + rowData[0])
    },
    selectableRows: 'none',
    selectableRowsHeader: false
  }

  const handleCount = async ({ query }) => {
    return api.ingredientTag.http.countIngredientTags({ title: query })
  }

  const handleSearch = async ({ query }) => {
    return api.ingredientTag.http.searchTagsContainTitle({ title: query })
  }

  return (
    <div className={classes.root}>
      <IngredientTagToolbar />
      <div className={classes.content}>
        <DataTable
          title="Ingredient Tags"
          columns={columns}
          options={options}
          elementToRow={ingredientTagToRow}
          handleCount={handleCount}
          handleFetchPage={api.ingredientTag.http.getAllIngredientTags}
          handleSearch={handleSearch}
        />
      </div>
    </div>
  )
}

export default IngredientTagList
