import { ValidateError } from '../error'

export const validatePost = (data) => {
  if (!data.title) {
    throw new ValidateError('No title', 'title')
  }
  if (!data.slug) {
    throw new ValidateError('No slug', 'slug')
  }
  if (!data.content) {
    throw new ValidateError('No content', 'content')
  }
}
