import _ from 'lodash'
const parseCategory = (rawCategories) => {
  const categoriesMap = {}
  const rootCategories = []
  rawCategories.forEach((category) => {
    categoriesMap[category.id] = category
    if (_.isNull(category.parent_category)) {
      rootCategories.push(category.id)
    }
  })
  const parseCategoryTree = (id) => {
    const category = categoriesMap[id]
    if (!category) {
      return null
    }
    delete categoriesMap[id]
    category.subcategories = category.subcategories.map(({ id }) =>
      parseCategoryTree(id)
    )
    return category
  }
  return rootCategories.map(parseCategoryTree)
}
export default parseCategory
