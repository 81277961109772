const trimText = (text, wordTaken = 10) => {
  if (!text) {
    return ''
  }
  const words = text.split(/\s+/)
  if (words.length > wordTaken) {
    return words.slice(0, wordTaken).join(' ') + ' ...'
  }
  return text
}

export default trimText
