import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import { makeStyles, Modal } from '@material-ui/core'
import React from 'react'
import Fade from '@material-ui/core/Fade'

const useStyles = makeStyles((theme) => ({
  button: {
    margin: '10px'
  },
  noButton: {
    color: 'red'
  },
  paper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%);',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    fontFamily: 'Roboto, sans-serif',
    textAlign: 'center',
    padding: theme.spacing(4, 2),
    outline: 'none'
  },
  message: {
    margin: 20
  }
}))

const ConfirmModal = ({ show, handleClose, handleConfirm, message }) => {
  const classes = useStyles()
  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={show}
      onClose={handleClose}
    >
      <Fade in={show} exit={!show}>
        <Paper className={classes.paper}>
          <h3 className={classes.message}>{message}</h3>
          <Button
            variant="contained"
            color="primary"
            onClick={handleConfirm}
            className={classes.button}
          >
            Yes
          </Button>
          <Button
            variant="contained"
            className={classes.button + ' ' + classes.noButton}
            onClick={handleClose}
          >
            No
          </Button>
        </Paper>
      </Fade>
    </Modal>
  )
}

export default ConfirmModal
