import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField
} from '@material-ui/core'
import { createErrorProp } from '@/helpers'
import api from '@/apis'

const useStyles = makeStyles((theme) => ({
  root: {},
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  deleteButton: {
    color: 'red'
  },
  button: {
    margin: 10
  }
}))

const eventToValue = (callback) => (event) => callback(event.target.value)

const ProductCategoryDetail = ({
  productCategory: { name, description },
  parent,
  className,
  handleSubmit,
  handleDelete,
  updateField,
  loading,
  error,
  mode,
  ...rest
}) => {
  const classes = useStyles()
  const [parentName, setParentName] = useState(`${parent}`)

  useEffect(() => {
    if (parent) {
      api.productCategory.http
        .getProductCategoryById(parent)
        .then(({ name }) => setParentName(name))
    }
  }, [parent])

  let header = ''
  if (mode === 'create') {
    if (parent) {
      header = (
        <span>
          Create a subcategory for <b>{parentName}</b>
        </span>
      )
    } else {
      header = 'Create a new product category'
    }
  } else {
    header = 'Update this product category'
  }
  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <form
        autoComplete="off"
        noValidate
        onSubmit={(event) => {
          event.preventDefault()
          handleSubmit()
        }}
      >
        <CardHeader subheader={header} title="Product Category" />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <TextField
                disabled={loading}
                fullWidth
                label="Name"
                name="name"
                onChange={eventToValue(updateField('name'))}
                required
                value={name}
                variant="outlined"
                {...createErrorProp(
                  error,
                  ['name'],
                  "Set this product category's name"
                )}
              />
            </Grid>
            <Grid item md={9} xs={12}>
              <TextField
                disabled={loading}
                fullWidth
                label="Description"
                name="description"
                onChange={eventToValue(updateField('description'))}
                required
                multiline
                rows={10}
                rowsMax={10}
                value={description}
                variant="outlined"
                {...createErrorProp(
                  error,
                  ['description'],
                  "This product category's description"
                )}
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            disabled={loading}
            color="primary"
            variant="contained"
            onClick={handleSubmit}
            className={classes.button}
          >
            Save
          </Button>
          {mode === 'update' && (
            <Button
              disabled={loading}
              className={classes.button + ' ' + classes.deleteButton}
              onClick={handleDelete}
              variant="contained"
            >
              Delete
            </Button>
          )}
        </CardActions>
      </form>
    </Card>
  )
}

ProductCategoryDetail.propTypes = {
  className: PropTypes.string
}

export default ProductCategoryDetail
