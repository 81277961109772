import requester from '@/apis/requester'
import { upload } from '@/apis/file/http'
import Axios from 'axios'
import {
  extractHomeImage,
  extractOtherImage,
  unExtractHomeImage,
  unExtractOtherImage
} from './utils'
import { pullErrorMessage } from '@/apis/error'

export const updateHomeLayout = async (layout) => {
  const images = extractHomeImage(layout)
  if (images.length > 0) {
    const uploadedImages = await upload(images)
    unExtractHomeImage(layout, uploadedImages)
  }
  const { data } = await requester()
    .put('layouts/home', layout)
    .catch(pullErrorMessage)
  return data
}

export const updateOtherLayout = async (layout) => {
  const images = extractOtherImage(layout)
  if (images.length > 0) {
    const uploadedImages = await upload(images)
    unExtractOtherImage(layout, uploadedImages)
  }
  const { data } = await requester()
    .put('layouts/other', layout)
    .catch(pullErrorMessage)
  return data
}

export const updateInstaLayout = async (layout) => {
  const images = extractOtherImage(layout)
  if (images.length > 0) {
    const uploadedImages = await upload(images)
    unExtractOtherImage(layout, uploadedImages)
  }
  const { data } = await requester()
    .put('layouts/insta', layout)
    .catch(pullErrorMessage)
  return data
}

export const getHomeLayout = async () => {
  const { data } = await requester().get('layouts/home')
  return data
}

export const getInstaLayout = async () => {
  const { data } = await requester().get('layouts/insta')
  return data
}

export const getOtherLayout = async () => {
  const { data } = await requester().get('layouts/other')
  return data
}

export const getInstaPost = async (url) => {
  const { data } = await Axios.get(
    `https://api.instagram.com/oembed?url=${url}`
  )
  return data
}
