import { Editor } from "@/components";
import React from "react";
import {
  Button,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  InputAdornment,
  makeStyles,
  TextField,
  Grid,
  Paper,
  IconButton,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import { Link, LinkOff } from "@material-ui/icons";
import FormGroup from "@material-ui/core/FormGroup";
import ImagePicker from "@/components/ImagePicker/ImagePicker";
import { createErrorProp } from "@/helpers";
import { ServerSideTagInput } from "@/components/TagInput";
import api from "@/apis";
import UserContainer from "@/containers/UserContainer";
import ROLES from "@/containers/UserContainer/roles";
import controls from "@/components/Editor/controls";

const useStyle = makeStyles(() => ({
  thumbnailPicker: {
    height: 300,
  },
  description: {
    height: 300,
  },
  innerDescription: {
    height: "100%",
  },
}));

const eventToValue = (cb) => (event) => cb(event.target.value);

const PostDetail = ({
  data,
  setField,
  mode,
  error,
  autoSlug,
  toggleAutoSlug,
  loading,
  handleSubmit,
  handleDelete,
  onChangePublished,
}) => {
  const {
    content,
    title,
    slug,
    description,
    thumbnail,
    tags,
    published,
    level,
    priority,
  } = data;

  const classes = useStyle();
  return (
    <div>
      <form onSubmit={(event) => event.preventDefault()}>
        <CardHeader
          subheader={
            mode === "create" ? "Create a new post" : "Change this post"
          }
          title={mode === "create" ? "Create" : "Update"}
        />
        <Divider />

        <CardContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={published || false}
                      disabled={
                        loading ||
                        UserContainer.getRole() === ROLES.collaborator
                      }
                      onChange={() => {
                        onChangePublished();
                        setField("published")(!published);
                      }}
                      value="published"
                      color="primary"
                    />
                  }
                  label="Publish"
                />
              </FormGroup>
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                label="Title"
                variant="outlined"
                disabled={loading}
                value={title}
                {...createErrorProp(error, ["title"])}
                fullWidth
                onChange={eventToValue(setField("title"))}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                label="Slug"
                variant="outlined"
                disabled={autoSlug}
                value={slug}
                {...createErrorProp(error, ["slug"])}
                fullWidth
                onChange={eventToValue(setField("slug"))}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position={"start"}>
                      <IconButton
                        aria-label="Auto generate slug"
                        onClick={toggleAutoSlug}
                      >
                        {autoSlug ? <LinkOff /> : <Link />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                label="Description"
                variant="outlined"
                disabled={loading}
                value={description}
                {...createErrorProp(error, ["description"])}
                fullWidth
                multiline
                rows={5}
                rowsMax={16}
                onChange={eventToValue(setField("description"))}
                className={classes.description}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <Paper>
                <ImagePicker
                  title="Drag or select an image for thumbnail"
                  alt={slug + "-thumbnail"}
                  image={thumbnail}
                  disabled={loading}
                  setImage={setField("thumbnail")}
                  className={classes.thumbnailPicker}
                />
              </Paper>
            </Grid>
            <Grid item md={6} xs={12}>
              <ServerSideTagInput
                value={tags}
                getOptionLabel={(tag) => tag.name}
                disabled={loading}
                label="Tag"
                multiple
                fetchOptions={api.postTag.http.searchTagsContainName}
                onChange={setField("tags")}
              />
            </Grid>
            <Grid item md={6} xs={12}></Grid>
            <Grid item md={6} xs={12}>
              <TextField
                label={`Level - ${
                  parseInt(level) > 5 ? "Nâng cao" : "Cơ bản"
                }`}
                variant="outlined"
                disabled={loading}
                value={level ? level : ""}
                {...createErrorProp(error, ["level"])}
                fullWidth
                onChange={(e) => {
                  if (e.target.value === "") {
                    setField("level")("");
                  }
                  const levelInp = parseInt(e.target.value || 0);
                  if (0 < levelInp && 11 > levelInp) {
                    eventToValue(setField("level"))(e);
                  }
                }}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                label="Priority"
                variant="outlined"
                disabled={loading}
                value={priority ? priority : ""}
                {...createErrorProp(error, ["priority"])}
                fullWidth
                onChange={(e) => {
                  const priorityInp = parseInt(e.target.value || 0);
                  if (
                    (0 < priorityInp && 6 > priorityInp) ||
                    e.target.value === ""
                  ) {
                    eventToValue(setField("priority"))(e);
                  }
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Editor
                value={content}
                disabled={loading}
                controls={controls}
                onChange={setField("content")}
                paperProps={{ elevation: 5 }}
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            disabled={loading}
            color="primary"
            variant="contained"
            onClick={handleSubmit}
            className={classes.button}
          >
            Save
          </Button>
          {mode === "update" && (
            <Button
              disabled={loading}
              className={classes.button + " " + classes.deleteButton}
              onClick={handleDelete}
              variant="contained"
            >
              Delete
            </Button>
          )}
        </CardActions>
      </form>
    </div>
  );
};
export default PostDetail;
