import React from 'react'
import { makeStyles } from '@material-ui/styles'

import { columns, PostsToolbar, postToRow } from './components'
import { useHistory } from 'react-router-dom'
import DataTable from '@/components/DataTable/DataTable'
import api from '@/apis'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}))

const PostList = () => {
  const classes = useStyles()
  const history = useHistory()
  const options = {
    download: false,
    print: false,
    viewColumns: false,
    onRowClick: (rowData) => {
      return history.push('/posts/update/' + rowData[0])
    },
    selectableRows: 'none',
    selectableRowsHeader: false
  }

  const handleCount = async ({ query }) => {
    return api.post.http.countPost({ title: query })
  }

  const handleSearch = async ({ query }) => {
    return api.post.http.searchPostByTitle({ title: query })
  }

  return (
    <div className={classes.root}>
      <PostsToolbar />
      <div className={classes.content}>
        <DataTable
          title="Post"
          columns={columns}
          options={options}
          elementToRow={postToRow}
          handleCount={handleCount}
          handleFetchPage={api.post.http.getAllPosts}
          handleSearch={handleSearch}
        />
      </div>
    </div>
  )
}

export default PostList
