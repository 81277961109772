import { resolveURL } from '@/apis/utils'

export const filesToFinderListing = (files) =>
  files.map(({ url, id, name, mime }) => {
    url = resolveURL(url)
    return {
      thumbnail: url,
      id: id,
      url: url,
      name: name,
      type: mime.split('/')[0].toUpperCase(),
      uploading: false,
      uploadProgress: 1,
      selected: false
    }
  })

export const patchRemoveFunc = (finder, snackbar) => {
  const showSnackbar = async (id) => {
    return snackbar.showMessage(
      'We are currently not allowing you to delete image, since other things might depend on these images',
      'info'
    )
  }
  finder.removeMediaItem = showSnackbar

  finder.removeItems = showSnackbar

  finder.removeSelectedItems = showSnackbar

  finder.removeErrorItems = showSnackbar

  finder.removeAllItems = showSnackbar
}
