let STRAPI_URI = process.env.REACT_APP_STRAPI_URI

if (!STRAPI_URI) {
  // STRAPI_URI = "http://localhost:1337";
  STRAPI_URI = 'https://callmeduy.com/_api'
  console.log(
    `No REACT_APP_STRAPI_URI env variable found. Using fallback ${STRAPI_URI}`
  )
}

export { STRAPI_URI }
