import React from 'react'
import { Grid, Hidden, Paper, Typography, makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  paper: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },
  title: {
    display: 'flex',
    justifyContent: 'center'
  }
}))

const MockContent = () => {
  const classes = useStyles()
  return (
    <Hidden mdDown>
      <Grid xs={8} item>
        <Paper className={classes.paper}>
          <div className={classes.title}>
            <Typography align="center" variant="h1">
              Content
            </Typography>
          </div>
        </Paper>
      </Grid>
    </Hidden>
  )
}

export default MockContent
