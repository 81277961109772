import * as file from './file'
import * as post from './post'
import * as user from './user'
import * as ingredient from './ingredient'
import * as ingredientTag from './ingredientTag'
import * as product from './product'
import * as productCategory from './productCategory'
import * as postTag from './postTag'
import * as brand from './brand'
import * as layout from './layout'
import * as error from './error'

const api = {
  file,
  post,
  user,
  ingredient,
  error,
  product,
  productCategory,
  layout,
  brand,
  postTag,
  ingredientTag
}
export default api
