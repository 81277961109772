import React from 'react'
import { Box, Grid, IconButton, Paper } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import { makeStyles } from '@material-ui/styles'

import api from '@/apis'
import  { ServerSideTagInput } from '@/components/TagInput'
import IngredientList from './IngredientList.js'
const useStyle = makeStyles((theme) => ({
  options: {
    textAlign: 'center'
  },
  tagInput: {
    flex: 10
  },
  tagInputWithButton: {
    display: 'flex',
    flexDirection: 'row',
    alignContent: 'flex-start'
  },
  paper: {
    padding: theme.spacing(2)
  }
}))

const Options = ({ loading, value, updateField }) => {

  const classes = useStyle()
  return (
    <Paper className={classes.paper}>
      <Grid
        container
        spacing={3}
        direction="column"
        alignItems="stretch"
        className={classes.options}
      >
        <Grid item xs={12}>
          <Box className={classes.tagInputWithButton}>
            <ServerSideTagInput
              fetchOptions={api.brand.http.searchBrandsByName}
              value={value.brand}
              className={classes.tagInput}
              disabled={loading}
              getOptionLabel={(item) => item.name}
              onChange={updateField('brand')}
              label="Brands"
            />
            <a href="/brands/create" target="_blank" rel="noreferrer noopener">
              <IconButton color="primary" aria-label="Add a new brand">
                <AddIcon />
              </IconButton>
            </a>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <IngredientList
            ingredients={value.ingredients}
            onChange={updateField('ingredients')}
            productId={value.id}
          />
        </Grid>
        <Grid item xs={12}>
          <Box className={classes.tagInputWithButton}>
            <ServerSideTagInput
              fetchOptions={
                api.productCategory.http.searchProductCategoriesContainName
              }
              value={value.productCategory}
              className={classes.tagInput}
              disabled={loading}
              getOptionLabel={(item) => item.name}
              onChange={updateField('product_category')}
              label="Product Categories"
            />
            <a
              href="/product-categories/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <IconButton
                color="primary"
                aria-label="Add a new product category"
              >
                <AddIcon />
              </IconButton>
            </a>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box className={classes.tagInputWithButton}>
            <ServerSideTagInput
                fetchOptions={
                  api.productCategory.http.searchSkinProblems
                }
                value={value.skin_problems}
                className={classes.tagInput}
                multiple={true}
                getOptionLabel={(item) => item.name}
                onChange={updateField('skin_problems')}
                label="Filter vấn đề da"
            />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box className={classes.tagInputWithButton}>
            <ServerSideTagInput
                fetchOptions={
                  api.ingredient.http.searchIngredientContainNames
                }
                value={value.highlight_ingredients }
                className={classes.tagInput}
                multiple={true}
                getOptionLabel={(item) => item.names[0].name}
                onChange={updateField('highlight_ingredients')}
                label="Hoạt chất nổi bật"
            />
          </Box>
        </Grid>

      </Grid>
    </Paper>
  )
}

export default Options
