import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/styles";
import { useParams, useHistory } from "react-router-dom";
import { IngredientDetail } from "./components";
import { withSnackbar } from "@/containers/SnackbarContainer";
import { NotFoundError, ValidateError } from "@/apis/error";
import api from "@/apis";
import ConfirmModal from "@/components/ConfirmModal";
import BraftEditor from "callmeduy-braft-editor";
import { prepareData } from "./utils";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
}));

const IngredientSingle = ({ snackbar }) => {
  const classes = useStyles();
  const history = useHistory();
  const { id, mode } = useParams();

  const [ingredient, setIngredient] = useState({
    id,
    main_name: "",
    tags: [],
    aliases: [],
    description: BraftEditor.createEditorState(""),
    rating: 5,
    safetyLevel: 0,
    isAncol: false,
    isFlavor: false,
    isHighlighting: false,
    highlightingName: "",
    includeMainName: false,
  });
  const [error, setError] = useState({ field: "", message: "" });
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [tagList, setTagList] = useState([]);

  const updateField = (field) => (value) => {
    if (field === "rating") {
      value = parseFloat(value);
    }
    setIngredient({ ...ingredient, [field]: value });
    if (error.message !== "") {
      setError({
        message: "",
        field: "",
      });
    }
  };
  const handleSubmit = async () => {
    const submitData = prepareData(ingredient);
    if (mode === "update") {
      return handleUpdate(submitData);
    }
    return handleCreate(submitData);
  };

  const handleCreate = async (_ingredient) => {
    try {
      api.ingredient.validate.validateIngredientData(_ingredient);
      const res = await api.ingredient.http.createIngredient(_ingredient);
      await snackbar.showMessage("Created new ingredient", "success");
      history.replace(`/ingredient/update/${res.id}`);
    } catch (err) {
      if (err instanceof ValidateError) {
        setError(err);
        return snackbar.showMessage(err.message, "error");
      }
      throw err;
    }
  };

  const handleUpdate = async (_ingredient) => {
    try {
      api.ingredient.validate.validateIngredientData(_ingredient);
      await api.ingredient.http.updateIngredient(_ingredient);
      await snackbar.showMessage("Updated ingredient", "success");
    } catch (err) {
      if (err instanceof ValidateError) {
        setError(err);
        await snackbar.showMessage(err.message, "error");
        history.push("/ingredients");
      } else if (err instanceof NotFoundError) {
        history.replace("/not-found");
        await snackbar.showMessage("That ingredient doesn't exist", "error");
      } else {
        throw err;
      }
    }
  };

  const handleDelete = async () => {
    try {
      await api.ingredient.http.deleteIngredient(id);
      await snackbar.showMessage("Deleted ingredient", "success");
    } catch (err) {
      if (err instanceof NotFoundError) {
        await snackbar.showMessage("That ingredient doesn't exist", "error");
      } else {
        throw err;
      }
    } finally {
      history.replace("/ingredients");
    }
  };

  const handleSaveAll = async () => {
    try {
      const submitData = prepareData(ingredient);
      if (mode === "update") {
        await handleUpdate(submitData);
      }
      await api.ingredient.http.refreshIngredient(id);
      await snackbar.showMessage("Refreshed ingredient", "success");
    } catch (err) {
      if (err instanceof NotFoundError) {
        await snackbar.showMessage("Something went wrong", "error");
      } else {
        throw err;
      }
    } finally {
    }
  };

  useEffect(() => {
    if (mode === "create") {
      api.ingredientTag.http.getAllIngredientTags().then((tagList) => {
        setTagList(tagList);
        setLoading(false);
      });
      return;
    }
    const promiseList = [
      api.ingredient.http.getIngredientById(id),
      api.ingredientTag.http.getAllIngredientTags(),
    ];
    Promise.all(promiseList)
      .then(([ingredient, tagList]) => {
        console.log(ingredient.names);
        setIngredient({
          id: ingredient.id,
          main_name: ingredient.names?.[0]?.name || "",
          aliases:
            ingredient.names && ingredient.names.length > 1
              ? ingredient.names.slice(1).map((n) => n.name)
              : [],
          tags: ingredient.tags,
          description: BraftEditor.createEditorState(ingredient.description),
          rating: parseFloat(ingredient.rating),
          safetyLevel: parseFloat(ingredient.safetyLevel),
          isAncol: ingredient.isAncol,
          isFlavor: ingredient.isFlavor,
          isHighlighting: ingredient.isHighlighting,
          includeMainName: ingredient.includeMainName,
          highlightingName: ingredient.highlightingName,
        });
        setTagList(tagList);
        setLoading(false);
      })
      .catch((err) => {
        if (err instanceof NotFoundError) {
          history.replace("/not-found");
          return snackbar.showMessage("There's no such ingredient", "error");
        }
        throw err;
      });
  }, [id, mode, history, snackbar]);

  return (
    <div className={classes.root}>
      <ConfirmModal
        show={showModal}
        handleClose={() => setShowModal(false)}
        handleConfirm={handleDelete}
        message="Are you sure you want to delete this ingredient?"
      />
      <IngredientDetail
        handleSaveAll={handleSaveAll}
        mode={mode}
        loading={loading}
        ingredient={ingredient}
        ingredientTagList={tagList}
        handleSubmit={handleSubmit}
        handleDelete={() => setShowModal(true)}
        updateField={updateField}
        error={error}
      />
    </div>
  );
};

export default withSnackbar(IngredientSingle);
