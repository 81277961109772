import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'

import { UsersToolbar, UsersTable, AddUserModal } from './components'
import api from '@/apis'
import { withSnackbar } from '@/containers/SnackbarContainer'
import { createCanceller } from '@/apis/requester'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}))

const UserList = ({ snackbar }) => {
  const classes = useStyles()

  const [users, setUsers] = useState([])
  const [isOpenModal, setOpenModal] = useState(false)
  function handleCloseModal () {
    setOpenModal(false)
  }
  function handleBtnAddNewUser () {
    setOpenModal(true)
  }

  function userToRow (userData) {
    return [userData.id, userData.name, userData.username, userData.role.name]
  }
  function addUserToTable (userData) {
    setUsers([...users, userToRow(userData)])
  }

  useEffect(() => {
    let canceller = createCanceller()
    api.user.http.getAllUser(canceller.token).then((data) => {
      setUsers(data.map((v) => userToRow(v)))
      canceller = null
    })
    return () => {
      canceller &&
        canceller.cancel('GetAllUser cancelled because of route change')
    }
  }, [snackbar])

  return (
    <div className={classes.root}>
      <UsersToolbar
        handleBtnAddNewUser={handleBtnAddNewUser}
        disabled={users?.length >= 100}
      />
      <AddUserModal
        isOpenModal={isOpenModal}
        handleClose={handleCloseModal}
        addUserToTable={addUserToTable}
      />
      <div className={classes.content}>
        {users && <UsersTable users={users} />}
      </div>
    </div>
  )
}
export default withSnackbar(UserList)
