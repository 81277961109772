import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField
} from '@material-ui/core'
import MenuItem from '@material-ui/core/MenuItem'
import { PasswordInput } from '@/components'
import { createErrorProp } from '@/helpers'

const useStyles = makeStyles((theme) => ({
  root: {},
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  deleteButton: {
    color: 'red'
  },
  button: {
    margin: 10
  }
}))

const UserDetail = (props) => {
  const {
    className,
    user,
    listRoles,
    handleSubmit,
    handleDelete,
    updateField,
    loading,
    error,
    ...rest
  } = props
  const classes = useStyles()
  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <form autoComplete="off" noValidate>
        <CardHeader subheader="The information can be edited" title="Profile" />
        <Divider />
        <CardContent>
          <Grid container spacing={3}>
            <Grid item md={6} xs={12}>
              <TextField
                disabled={loading}
                fullWidth
                {...createErrorProp(error, [name], 'Choose a display name')}
                label="Name"
                margin="dense"
                name="name"
                onChange={updateField('name')}
                required
                value={user.name}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                disabled={loading}
                fullWidth
                label="Username"
                margin="dense"
                name="username"
                onChange={updateField('username')}
                required
                {...createErrorProp(error, ['username'], 'Choose an username')}
                value={user.username}
                variant="outlined"
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <PasswordInput
                disabled={loading}
                fullWidth
                label="Password"
                margin="dense"
                name="country"
                onChange={updateField('password')}
                autoComplete="off"
                value={user.password}
                variant="outlined"
                {...createErrorProp(
                  error,
                  ['password'],
                  'Input password here to change it'
                )}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <PasswordInput
                disabled={loading}
                fullWidth
                label="Password confirm"
                margin="dense"
                name="country"
                onChange={updateField('passwordConfirm')}
                autoComplete="off"
                value={user.passwordConfirm}
                variant="outlined"
                {...createErrorProp(
                  error,
                  ['password'],
                  'Confirm the password'
                )}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                disabled={loading}
                select
                variant="outlined"
                labelid="role-select-label"
                label="Roles"
                id="role-select-select"
                {...createErrorProp(
                  error,
                  ['role'],
                  'Choose a role for this user'
                )}
                value={user.role}
                onChange={updateField('role')}
              >
                {(listRoles || []).map(
                  (v) =>
                    v.name !== 'Public' && (
                      <MenuItem value={v.id} key={v.type}>
                        {v.name}
                      </MenuItem>
                    )
                )}
              </TextField>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            disabled={loading}
            color="primary"
            variant="contained"
            onClick={handleSubmit}
            className={classes.button}
          >
            Save
          </Button>
          <Button
            disabled={loading}
            className={classes.button + ' ' + classes.deleteButton}
            onClick={handleDelete}
            variant="contained"
          >
            Delete
          </Button>
        </CardActions>
      </form>
    </Card>
  )
}

UserDetail.propTypes = {
  className: PropTypes.string
}

export default UserDetail
