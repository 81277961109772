import requester from '../requester'
import { pullErrorMessage } from '../error'

export const getAllBrands = async (
  { page, sort, limit = 10 } = { page: 0 }
) => {
  const offset = page * limit
  const config = { params: { _start: offset, _limit: limit, _sort: sort } }
  const { data } = await requester()
    .get('brands/', config)
    .catch(pullErrorMessage)
  return data
}

export const getBrandById = async (id) => {
  const { data } = await requester()
    .get(`brands/${id}`)
    .catch(pullErrorMessage)
  return data
}

export const updateBrand = async (data) => {
  const { id } = data

  await requester().put(`brands/${id}`, data).catch(pullErrorMessage)
}

export const createBrand = async (brandData) => {
  const { data } = await requester()
    .post('brands/', brandData)
    .catch(pullErrorMessage)
  return data
}

export const deleteBrand = async (id) => {
  const { data } = requester().delete(`brands/${id}`).catch(pullErrorMessage)
  return data
}

export const searchBrandsByName = async ({ name }) => {
  if (!name) {
    name = null
  }
  const body = { _limit: 50, name_contains: name }
  const { data } = await requester()
    .get('brands/', { params: body })
    .catch(pullErrorMessage)
  return data
}

export const countBrands = async ({ name }) => {
  if (!name) {
    name = null
  }
  const body = { name_contains: name }
  const { data } = await requester()
    .get('brands/count', { params: body })
    .catch(pullErrorMessage)
  return data
}
