import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import { Button } from '@material-ui/core'
import { Link } from 'react-router-dom'
import UserContainer from '@/containers/UserContainer/'

const useStyles = makeStyles((theme) => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  searchInput: {
    marginRight: theme.spacing(1)
  }
}))

const ProductToolbar = (props) => {
  const { className, handleBtnAddNewUser, ...rest } = props

  const classes = useStyles()

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <div className={classes.row}>
        <span className={classes.spacer} />
        {['owner', 'moderator', 'product_manager'].includes(
          UserContainer.getRole()
        ) && (
          <Button
            color="primary"
            variant="contained"
            component={Link}
            to="/products/create"
          >
            Add Products
          </Button>
        )}
      </div>
    </div>
  )
}

ProductToolbar.propTypes = {
  className: PropTypes.string
}

export default ProductToolbar
