export const columns = [
  {
    name: 'id',
    label: 'ID',
    options: {
      filter: false,
      sort: true
    }
  },
  {
    name: 'name',
    label: 'Name',
    options: {
      filter: false,
      sort: true
    }
  },
  {
    name: 'slug',
    label: 'Slug',
    options: {
      filter: false,
      sort: true
    }
  }
]

export function postTagToRow (data) {
  return [data.id, data.name, data.slug]
}
