import React, { useState } from 'react'
import { Fab, makeStyles, CircularProgress } from '@material-ui/core'
import Save from '@material-ui/icons/Save'

const useStyles = makeStyles((theme) => ({
  fab: {
    position: 'fixed',
    bottom: 50,
    right: 100,
    width: 50,
    height: 50,
    zIndex: 10000
  },
  progress: {
    position: 'absolute',
    zIndex: 10001,
    top: 0,
    left: 0,
    color: 'white'
  }
}))

const SaveButton = ({ onSave }) => {
  const classes = useStyles()
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false)
  const onClick = async () => {
    setLoading(true)
    await onSave()
    setLoading(false)
  }
  return (
    <Fab
      onClick={onClick}
      color="primary"
      aria-label="save"
      className={classes.fab}
    >
      {loading && (
        <CircularProgress
          className={classes.progress}
          style={{ width: 50, height: 50 }}
        />
      )}
      <Save />
    </Fab>
  )
}
export default SaveButton
