import requester from '../requester'
import { pullErrorMessage } from '../error'

export const getAllProductCategories = async () => {
  const { data } = await requester()
    .get('product-categories')
    .catch(pullErrorMessage)
  return data
}

export const countProductCategories = async () => {
  const { data } = await requester()
    .get('product-categories/count')
    .catch(pullErrorMessage)
  return data
}

export const searchProductCategoriesContainName = async ({ name }) => {
  if (!name) {
    name = null
  }
  const body = { _limit: 50, name_contains: name }
  const { data } = await requester()
    .get('product-categories', { params: body })
    .catch(pullErrorMessage)
  return data
}

export const searchSkinProblems = async () => {

  const { data } = await requester()
      .get('skin-problems', {  })
      .catch(pullErrorMessage)
  return data
}

export const getProductCategoryById = async (id) => {
  const { data } = await requester()
    .get(`product-categories/${id}`)
    .catch(pullErrorMessage)
  return data
}

export const createProductCategory = async (productCategoryData) => {
  const { data } = await requester()
    .post('product-categories/', productCategoryData)
    .catch(pullErrorMessage)
  return data
}

export const deleteProductCategory = async (id) => {
  const { data } = await requester()
    .delete(`product-categories/${id}`)
    .catch(pullErrorMessage)
  return data
}

export const updateProductCategory = async (productCategoryData) => {
  const { data } = await requester()
    .put(`product-categories/${productCategoryData.id}`, productCategoryData)
    .catch(pullErrorMessage)
  return data
}
