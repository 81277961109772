import { useState, useEffect } from 'react'
import { countProductCategories } from './http'

export const useProductCategoriesCount = () => {
  const [count, setCount] = useState(0)
  useEffect(() => {
    countProductCategories().then(setCount)
  })
  return count
}
