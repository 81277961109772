import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField
} from '@material-ui/core'
import { createErrorProp } from '@/helpers'

const useStyles = makeStyles((theme) => ({
  root: {},
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  deleteButton: {
    color: 'red'
  },
  button: {
    margin: 10
  }
}))

const eventToValue = (callback) => (event) => callback(event.target.value)

const BrandDetail = (props) => {
  const {
    brand: { name, description, url },
    className,
    handleSubmit,
    handleDelete,
    updateField,
    loading,
    error,
    mode,
    ...rest
  } = props

  const classes = useStyles()
  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <form autoComplete="off" noValidate>
        <CardHeader
          subheader={
            mode === 'create' ? 'Create a new brand' : 'Update this brand'
          }
          title="Brand"
        />
        <Divider />
        <CardContent>
          <Grid container spacing={3} alignItems={'center'}>
            <Grid item md={6} xs={12}>
              <TextField
                disabled={loading}
                fullWidth
                label="Name"
                name="name"
                onChange={eventToValue(updateField('name'))}
                required
                value={name}
                variant="outlined"
                {...createErrorProp(error, ['name'], 'Brand name')}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                disabled={loading}
                fullWidth
                label="URL"
                name="url"
                onChange={eventToValue(updateField('url'))}
                value={url}
                variant="outlined"
                {...createErrorProp(error, ['url'], 'Brand URL')}
              />
            </Grid>
            <Grid item md={9} xs={12}>
              <TextField
                disabled={loading}
                fullWidth
                label="Description"
                name="description"
                onChange={eventToValue(updateField('description'))}
                required
                multiline
                rows={5}
                rowsMax={10}
                value={description}
                variant="outlined"
                {...createErrorProp(error, ['description'], 'Description')}
              />
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            disabled={loading}
            color="primary"
            variant="contained"
            onClick={handleSubmit}
            className={classes.button}
          >
            Save
          </Button>
          {mode === 'update' && (
            <Button
              disabled={loading}
              className={classes.button + ' ' + classes.deleteButton}
              onClick={handleDelete}
              variant="contained"
            >
              Delete
            </Button>
          )}
        </CardActions>
      </form>
    </Card>
  )
}

BrandDetail.propTypes = {
  className: PropTypes.string
}

export default BrandDetail
