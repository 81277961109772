import { ValidateError } from '../error'

export const validatePostTagData = ({ name, slug }) => {
  if (!name) {
    throw new ValidateError('A name is required', 'name')
  }
  if (!slug) {
    throw new ValidateError('A slug is required', 'slug')
  }
}
