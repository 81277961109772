import React, { useState, useEffect } from 'react'
import {
  Card,
  CardActions,
  CardContent,
  Grid,
  Button,
  makeStyles
} from '@material-ui/core'
import api from '@/apis'
import { withSnackbar } from '@/containers/SnackbarContainer'
import { HTTPError } from '@/apis/error'
import {
  MockContent,
  OwnerDescription,
  TopBanner
} from '@/views/LayoutSetting/OtherLayoutSetting/components'
import SaveButton from '@/components/SaveButton'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4)
  },
  paper: {
    margin: theme.spacing(3),
    padding: theme.spacing(1)
  }
}))

const OtherLayoutSetting = ({ snackbar }) => {
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const [topBanner, setTopBanner] = useState(null)
  const [avatar, setAvatar] = useState(null)
  const [description, setDescription] = useState('')

  const setData = (data) => {
    setTopBanner(data.topBanner)
    setAvatar(data.avatar)
    setDescription(data.description)
  }

  const onSubmit = async () => {
    setLoading(true)
    try {
      const res = await api.layout.http.updateOtherLayout({
        topBanner,
        avatar,
        description
      })
      await snackbar.showMessage('Update layout successfully', 'success')
      setData(res)
    } catch (err) {
      if (err instanceof HTTPError) {
        await snackbar.showMessage(err.message, 'error')
      }
      throw err
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    setLoading(true)
    api.layout.http
      .getOtherLayout()
      .then(setData)
      .then(() => setLoading(false))
  }, [])
  return (
    <div className={classes.root}>
      <Card className={classes.paper}>
        <CardContent>
          <Grid container spacing={3}>
            <TopBanner
              loading={loading}
              banner={topBanner}
              setBanner={setTopBanner}
            />
            <MockContent />
            <OwnerDescription
              loading={loading}
              avatar={avatar}
              description={description}
              setAvatar={setAvatar}
              setDescription={setDescription}
            />
          </Grid>
        </CardContent>
        <CardActions>
          <Button
            variant="contained"
            color="primary"
            onClick={onSubmit}
            disabled={loading}
          >
            Save
          </Button>
        </CardActions>
        <SaveButton onSave={onSubmit} />
      </Card>
    </div>
  )
}

export default withSnackbar(OtherLayoutSetting)
