const ROUTES = {
  main: "main",
  user: "user",
  postList: "postList",
  postEdit: "postEdit",
  postTagList: "postTagList",
  postTagEdit: "postTagEdit",
  ingredientList: "ingredientList",
  IngredientListPending: "ingredientPending",
  ingredientEdit: "ingredientEdit",
  ingredientTagList: "ingredientTagList",
  ingredientTagEdit: "ingredientTagEdit",
  productList: "productList",
  productEdit: "productEdit",
  productCategoryList: "productCategoryList",
  productCategoryEdit: "productCategoryEdit",
  brandList: "brandList",
  brandEdit: "brandEdit",
  bannerList: "bannerList",
  bannerEdit: "bannerEdit",
  layout: "layout",
  notification: "notification",
};

export default ROUTES;
