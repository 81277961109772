import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Grid,
  Button,
  TextField,
  MenuItem,
  Chip,
  Switch,
  FormControlLabel,
  FormGroup,
} from '@material-ui/core'
import { createErrorProp } from '@/helpers'
import { dangerLevels, useDangerLevelStyle } from '@/apis/ingredientTag/color'

const useStyles = makeStyles((theme) => ({
  root: {},
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  deleteButton: {
    color: 'red'
  },
  button: {
    margin: 10
  }
}))

const eventToValue = (callback) => (event) => callback(event.target.value)

const IngredientTagDetail = ({
  ingredientTag: { title, danger, isShowOnProduct},
  className,
  handleSubmit,
  handleDelete,
  updateField,
  autoSlug,
  loading,
  error,
  toggleAutoSlug,
  mode,
  ...rest
}) => {
  const classes = useStyles()
  const dangerClasses = useDangerLevelStyle()
  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <form
        autoComplete="off"
        noValidate
        onSubmit={(event) => {
          event.preventDefault()
          handleSubmit()
        }}
      >
        <CardHeader
          subheader={
            mode === 'create'
              ? 'Create a new ingredient tag'
              : 'Update this ingredient tag'
          }
          title="Ingredient Tag"
        />
        <Divider />
        <CardContent>
          <Grid container spacing={3} alignItems={'center'}>
            <Grid item md={6} xs={12}>
              <TextField
                disabled={loading}
                fullWidth
                label="Title"
                name="title"
                onChange={eventToValue(updateField('title'))}
                required
                value={title}
                variant="outlined"
                {...createErrorProp(
                  error,
                  ['title'],
                  'Specify a title for this tag'
                )}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <TextField
                select
                label="Danger level"
                disabled={loading}
                fullWidth
                required
                variant="outlined"
                value={danger}
                onChange={eventToValue(updateField('danger'))}
                {...createErrorProp(
                  error,
                  ['danger'],
                  'Specify a danger level for this tag'
                )}
              >
                {dangerLevels.map((level, idx) => (
                  <MenuItem key={idx} value={level}>
                    <Chip label={level} className={dangerClasses[level]} />
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item md={6} xs={12}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={isShowOnProduct}
                      disabled={loading}
                      onChange={() => {
                        updateField('isShowOnProduct')(!isShowOnProduct)
                      }}
                      value="published"
                      color="primary"
                    />
                  }
                  label="Show on website"
                />
              </FormGroup>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <CardActions>
          <Button
            disabled={loading}
            color="primary"
            variant="contained"
            onClick={handleSubmit}
            className={classes.button}
          >
            Save
          </Button>
          {mode === 'update' && (
            <Button
              disabled={loading}
              className={classes.button + ' ' + classes.deleteButton}
              onClick={handleDelete}
              variant="contained"
            >
              Delete
            </Button>
          )}
        </CardActions>
      </form>
    </Card>
  )
}

IngredientTagDetail.propTypes = {
  className: PropTypes.string
}

export default IngredientTagDetail
