import React from 'react'
import {
  Grid,
  Paper,
  Typography,
  TextField,
  makeStyles
} from '@material-ui/core'
import ImagePicker from '@/components/ImagePicker/ImagePicker'

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(3)
  },
  avatar: {
    height: 300
  }
}))
const eventToValue = (callback) => (event) => callback(event.target.value)
const OwnerDescription = ({
  setAvatar,
  avatar,
  description,
  setDescription,
  loading
}) => {
  const classes = useStyles()
  return (
    <Grid xs={12} md={4} item>
      <Paper className={classes.paper}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h2" align="center">
              Owner description
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <ImagePicker
              className={classes.avatar}
              image={avatar}
              setImage={setAvatar}
              loading={loading}
              title="Upload an avatar"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              disabled={loading}
              fullWidth
              label="Description"
              name="description"
              onChange={eventToValue(setDescription)}
              value={description}
              variant="outlined"
              multiline
              rows={20}
              rowsMax={25}
            />
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  )
}

export default OwnerDescription
