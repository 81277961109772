import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import { useParams, useHistory } from 'react-router-dom'
import { BrandDetail } from './components'
import { withSnackbar } from '@/containers/SnackbarContainer'
import { NotFoundError, ValidateError } from '@/apis/error'
import api from '@/apis'
import ConfirmModal from '@/components/ConfirmModal'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4)
  }
}))

const BrandSingle = ({ snackbar }) => {
  const classes = useStyles()
  const history = useHistory()
  const { id, mode } = useParams()

  const [brand, setBrand] = useState({
    id,
    name: '',
    description: '',
    url: ''
  })
  const [error, setError] = useState({ field: '', message: '' })
  const [loading, setLoading] = useState(true)
  const [showModal, setShowModal] = useState(false)

  const updateField = (field) => (value) => {
    setBrand({ ...brand, [field]: value })
    if (error.message !== '') {
      setError({
        message: '',
        field: ''
      })
    }
  }

  const handleSubmit = async () => {
    if (mode === 'update') {
      return handleUpdate()
    }
    return handleCreate()
  }

  const handleCreate = async () => {
    try {
      api.brand.validate.validateBrandData(brand)
      const res = await api.brand.http.createBrand(brand)
      await snackbar.showMessage('Created new brand', 'success')
      history.replace(`/brands/update/${res.id}`)
    } catch (err) {
      if (err instanceof ValidateError) {
        setError(err)
        return snackbar.showMessage(err.message, 'error')
      }
      throw err
    }
  }

  const handleUpdate = async () => {
    try {
      api.brand.validate.validateBrandData(brand)
      await api.brand.http.updateBrand(brand)
      await snackbar.showMessage('Updated brand', 'success')
    } catch (err) {
      if (err instanceof ValidateError) {
        setError(err)
        await snackbar.showMessage(err.message, 'error')
      } else if (err instanceof NotFoundError) {
        await snackbar.showMessage("That brand doesn't exist", 'error')
      } else {
        throw err
      }
    }
  }

  const handleDelete = async () => {
    try {
      await api.brand.http.deleteBrand(id)
      await snackbar.showMessage('Deleted brand', 'success')
    } catch (err) {
      if (err instanceof NotFoundError) {
        await snackbar.showMessage("That brand doesn't exist", 'error')
      } else {
        throw err
      }
    } finally {
      history.replace('/brands')
    }
  }

  useEffect(() => {
    if (mode === 'create') {
      setLoading(false)
      return
    }
    api.brand.http
      .getBrandById(id)
      .then((brand) => {
        setBrand(brand)
        setLoading(false)
      })
      .catch((err) => {
        if (err instanceof NotFoundError) {
          history.replace('/not-found')
          return snackbar.showMessage("There's no such brand", 'error')
        }
        throw err
      })
  }, [id, mode, history, snackbar])

  return (
    <div className={classes.root}>
      <ConfirmModal
        show={showModal}
        handleClose={() => setShowModal(false)}
        handleConfirm={handleDelete}
        message="Are you sure you want to delete this brand?"
      />
      <BrandDetail
        mode={mode}
        loading={loading}
        brand={brand}
        handleSubmit={handleSubmit}
        handleDelete={() => setShowModal(true)}
        updateField={updateField}
        error={error}
      />
    </div>
  )
}

export default withSnackbar(BrandSingle)
