import React from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import {makeStyles} from "@material-ui/styles";
import {
    Card,
    CardHeader,
    CardContent,
    CardActions,
    Divider,
    Grid,
    Button,
    TextField,
    List,
    ListItem,
    ListItemIcon,
    Checkbox,
    ListItemText,
} from "@material-ui/core";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";
import {createErrorProp} from "@/helpers";
import ImagePicker from "@/components/ImagePicker/ImagePicker";
import Options from "./Options";
import URLInput from "./URLInput";
import SaveButton from "@/components/SaveButton";
import {Editor} from "@/components";
import {minimalControls} from "@/components/Editor/controls";

const useStyles = makeStyles((theme) => ({
    root: {},
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    deleteButton: {
        color: "red",
    },
    button: {
        margin: 10,
    },

    thumbnailPicker: {
        height: 300,
    },
}));

const eventToValue = (callback) => (event) => callback(event.target.value);
const eventToChecked = (callback) => (event) => callback(event.target.checked);

const ProductDetail = ({
                           product: {
                               id,
                               name,
                               product_category: productCategory,
                               brand,
                               description,
                               price,
                               ingredients,
                               url,
                               thumbnail,
                               isRX,
                               isHot,
                               isForPregnancy,
                               isNoAncol,
                               isNoFlavoring,
                               isNoFungalAcne,
                               IsNotForPegnancy,
                               skin_problems,
                               highlight_ingredients
                           },
                           className,
                           handleSubmit,
                           handleDelete,
                           updateField,
                           loading,
                           error,
                           mode,
                           ...rest
                       }) => {
    const classes = useStyles();
    return (
        <Card {...rest} className={clsx(classes.root, className)}>
            <form autoComplete="off" noValidate>
                <CardHeader
                    subheader={
                        mode === "create" ? "Create a new product" : "Update this product"
                    }
                    title="Product"
                />
                <Divider/>
                <CardContent>
                    <Grid container spacing={3}>
                        <Grid item md={6} xs={12}>
                            <TextField
                                disabled={loading}
                                fullWidth
                                label="Name"
                                name="name"
                                onChange={eventToValue(updateField("name"))}
                                required
                                value={name}
                                variant="outlined"
                                {...createErrorProp(error, ["name"], "Set this product's name")}
                            />
                        </Grid>
                        <Grid item md={3} xs={12}>
                            <CurrencyTextField
                                label="Price"
                                fullWidth
                                variant="outlined"
                                value={price}
                                currencySymbol="đ"
                                disabled={loading}
                                decimalPlaces={0}
                                digitGroupSeparator=" "
                                onChange={(event, value) => updateField("price")(value)}
                                {...createErrorProp(
                                    error,
                                    ["price"],
                                    "Set this product's price"
                                )}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <ImagePicker
                                className={classes.thumbnailPicker}
                                title="Upload a thumbnail for the product"
                                image={thumbnail}
                                setImage={updateField("thumbnail")}
                                loading={loading}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Editor
                                value={description}
                                disabled={loading}
                                controls={minimalControls}
                                onChange={updateField("description")}
                                paperProps={{elevation: 5}}
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <Options
                                value={{productCategory, ingredients, brand, id, skin_problems, highlight_ingredients}}
                                loading={loading}
                                updateField={updateField}
                            />
                        </Grid>

                        <Grid item md={6} xs={12}>
                            <URLInput
                                disabled={loading}
                                fullWidth
                                label="Link"
                                name="url"
                                onChange={updateField("url")}
                                error={error}
                                required
                                value={url}
                                variant="outlined"
                            />
                            <List>
                                <ListItem>
                                    <ListItemIcon>
                                        <Checkbox
                                            edge="start"
                                            disableRipple
                                            checked={isRX}
                                            onChange={eventToChecked(updateField("isRX"))}
                                        />
                                    </ListItemIcon>
                                    <ListItemText primary="Sản phẩm là dạng thuốc"/>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <Checkbox
                                            disabled
                                            edge="start"
                                            disableRipple
                                            checked={IsNotForPegnancy}
                                        />
                                    </ListItemIcon>
                                    <ListItemText primary="Sản phẩm hạn chế với mẹ bầu"/>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <Checkbox
                                            disabled
                                            edge="start"
                                            disableRipple
                                            checked={isForPregnancy}
                                        />
                                    </ListItemIcon>
                                    <ListItemText primary="Sản phẩm phù hợp cho bà bầu lúc mang thai và cho con bú"/>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <Checkbox
                                            edge="start"
                                            disabled={true}
                                            disableRipple
                                            checked={isNoAncol}
                                            onChange={eventToChecked(updateField("isNoAncol"))}
                                        />
                                    </ListItemIcon>
                                    <ListItemText primary="Sản phẩm không cồn"/>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <Checkbox
                                            edge="start"
                                            disableRipple
                                            disabled={true}
                                            checked={isNoFlavoring}
                                            onChange={eventToChecked(updateField("isNoFlavoring"))}
                                        />
                                    </ListItemIcon>
                                    <ListItemText primary="Sản phẩm không hương liệu"/>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <Checkbox
                                            edge="start"
                                            disableRipple
                                            checked={isNoFungalAcne}
                                            onChange={eventToChecked(updateField("isNoFungalAcne"))}
                                        />
                                    </ListItemIcon>
                                    <ListItemText primary="Sản phẩm không kích thích mụn nấm"/>
                                </ListItem>
                                <ListItem>
                                    <ListItemIcon>
                                        <Checkbox
                                            edge="start"
                                            disableRipple
                                            checked={isHot}
                                            onChange={eventToChecked(updateField("isHot"))}
                                        />
                                    </ListItemIcon>
                                    <ListItemText primary="Sản phẩm đang hot"/>
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item md={3} xs={12}>
                        </Grid>
                    </Grid>
                </CardContent>
                <Divider/>
                <CardActions>
                    <Button
                        disabled={loading}
                        color="primary"
                        variant="contained"
                        onClick={handleSubmit}
                        className={classes.button}
                    >
                        Save
                    </Button>
                    {mode === "update" && (
                        <Button
                            disabled={loading}
                            className={classes.button + " " + classes.deleteButton}
                            onClick={handleDelete}
                            variant="contained"
                        >
                            Delete
                        </Button>
                    )}
                </CardActions>
            </form>
            <SaveButton onSave={handleSubmit}/>
        </Card>
    );
};

ProductDetail.propTypes = {
    className: PropTypes.string,
};

export default ProductDetail;
