import requester from "../requester";
import { pullErrorMessage } from "../error";
import { checkSameNameError } from "./validate";
import api from "@/apis";

export const countIngredients = async (tag, { names = null } = {}) => {
  const config = { params: { names } };
  const { data } = await requester()
    .get(`chemicals/count${tag ? "?tags.id=" + tag : ""}`, config)
    .catch(pullErrorMessage);
  return data;
};
export const searchIngredientsByNames = async ({ tag, names, page, limit }) => {
  const offset = page * limit;
  const body = { _start: offset, _limit: limit, names };
  if (tag) {
    body.tag = tag;
  }
  const { data } = await requester()
    .post("chemicals/search", body)
    .catch(pullErrorMessage);
  return data.filter((data) => data.id);
};

export const searchIngredientContainNames = async ({ name = null } = {}) => {
  console.log(name);
  if (!name) {
    name = null;
  }
  const body = { _limit: 50, "names.name_contains": name };
  const { data } = await requester()
    .get("chemicals/", { params: body })
    .catch(pullErrorMessage);
  return data;
};

export const getIngredients = async (
  tag,
  { page, sort, limit = 10 } = { page: 0 }
) => {
  const offset = page * limit;
  const config = { params: { _start: offset, _limit: limit, _sort: sort } };
  const { data } = await requester()
    .get(`chemicals/${tag ? "?tags.id=" + tag : ""}`, config)
    .catch(pullErrorMessage);
  return data;
};

export const getIngredientsByType = async (type, value) => {
  const config = { params: { [type]: value } };

  const { data } = await requester()
    .get(`chemicals/`, config)
    .catch(pullErrorMessage);
  return data;
};

export const getIngredientById = async (id) => {
  const { data } = await requester()
    .get(`chemicals/${id}`)
    .catch(pullErrorMessage);
  return data;
};

const buildSubmitData = (data) => ({
  names: [data.main_name, ...data.aliases],
  rating: parseInt(data.rating),
  description: data.description,
  tags: data.tags,
  safetyLevel: parseInt(data.safetyLevel),
  isAncol: data.isAncol,
  isFlavor: data.isFlavor,
  isHighlighting: data.isHighlighting,
  highlightingName: data.highlightingName,
  includeMainName:
    data.highlightingName !== "" && data.includeMainName === true
      ? true
      : false,
});

export const updateIngredient = async (data) => {
  const { id } = data;
  const submitData = buildSubmitData(data);
  if (submitData.tags) {
    submitData.tags = await api.ingredientTag.http.createIngredientTag(
      data.tags
    );
  }
  // Reduce Data
  submitData.tags = submitData.tags.map((v) => {
    delete v.chemicals;
    return v;
  });
  await requester()
    .put(`chemicals/${id}`, submitData)
    .catch(pullErrorMessage)
    .catch(checkSameNameError);
};

export const createIngredient = async (ingredientData) => {
  const submitData = buildSubmitData(ingredientData);
  if (submitData.tags?.length) {
    submitData.tags = await api.ingredientTag.http.createIngredientTag(
      submitData.tags
    );
  }

  const { data } = await requester()
    .post("chemicals/", submitData)
    .catch(pullErrorMessage)
    .catch(checkSameNameError);
  return data;
};

export const deleteIngredient = async (id) => {
  const { data } = requester()
    .delete(`chemicals/${id}`)
    .catch(pullErrorMessage);
  return data;
};

export const refreshIngredient = async (id) => {
  const { data } = requester()
    .post(`chemicals/refreshProduct`,{
      "chemicalId": id
    })
    .catch(pullErrorMessage);
  return data;
};
