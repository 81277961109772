import React, { useState, useEffect } from 'react'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import MenuItem from '@material-ui/core/MenuItem'

import Grid from '@material-ui/core/Grid'
import { withSnackbar } from '@/containers/SnackbarContainer'
import { PasswordInput } from '@/components'
import api from '@/apis'

function FormDialog ({ isOpenModal, handleClose, addUserToTable, snackbar }) {
  const [roles, setRoles] = useState([])
  const [error, setError] = useState({ message: '', field: '' })
  const [userInput, setUserInput] = useState({
    name: '',
    username: '',
    password: '',
    role: ''
  })

  const updateField = (field) => ({ target: { value } }) => {
    if (error.message !== '') {
      setError({ message: '', field: '' })
    }
    setUserInput({ ...userInput, [field]: value })
  }

  const handleAddUser = async () => {
    try {
      api.user.validate.validateNewUser(userInput)
      const data = await api.user.http.addUser(userInput)
      addUserToTable(data)
      await snackbar.showMessage('Add a new user successfully', 'success')
      handleClose()
    } catch (err) {
      const { message } = err
      if (err instanceof api.error.ValidateError) {
        setError({ message, field: err.field })
      }
      await snackbar.showMessage(message, 'error')
    }
  }

  useEffect(() => {
    api.user.http.getRoles().then((data) => {
      setRoles(data.roles)
    })
  }, [])
  const { message, field } = error

  return (
    <Dialog
      open={isOpenModal}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">Add new User</DialogTitle>
      <DialogContent>
        <DialogContentText>
          To add a new user, please enter username, name and password here.
        </DialogContentText>
        <Grid container spacing={3}>
          <Grid item md={12} xs={12}>
            <TextField
              margin="dense"
              id="name"
              label="Name"
              error={field === 'name'}
              helperText={field === 'name' ? message : 'Display name for user'}
              required
              fullWidth
              onChange={updateField('name')}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <TextField
              margin="dense"
              id="username"
              label="Username"
              helperText={
                field === 'username' ? message : 'Login name for user'
              }
              error={field === 'username'}
              required
              fullWidth
              onChange={updateField('username')}
              autoComplete="new-password"
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <PasswordInput
              required
              margin="dense"
              id="password"
              label="Password"
              type="password"
              helperText={
                field === 'password'
                  ? message
                  : 'Password must be at least 8 characters long'
              }
              error={field === 'password'}
              fullWidth
              onChange={updateField('password')}
              autoComplete="new-password"
            />
          </Grid>
          <Grid item md={6}>
            <TextField
              select
              label="Select role"
              labelid="role-select-label"
              id="role-select-select"
              helperText={field === 'role' ? message : 'User role'}
              error={field === 'role'}
              disabled={roles.length === 0}
              fullWidth
              required
              value={userInput.role}
              onChange={updateField('role')}
            >
              {roles.map(
                (v) =>
                  v.name !== 'Public' && (
                    <MenuItem value={v.id} key={v.type}>
                      {v.name}
                    </MenuItem>
                  )
              )}
            </TextField>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleAddUser} color="primary">
          Add
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default withSnackbar(FormDialog)
