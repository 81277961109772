import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/styles'
import { useParams, useHistory } from 'react-router-dom'
import { IngredientTagDetail } from './components'
import { withSnackbar } from '@/containers/SnackbarContainer'
import { NotFoundError, ValidateError } from '@/apis/error'
import api from '@/apis'
import ConfirmModal from '@/components/ConfirmModal'
import { useIngredientTagCount } from '@/apis/ingredientTag/hook'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4)
  }
}))

const IngredientTagSingle = ({ snackbar }) => {
  const classes = useStyles()
  const history = useHistory()
  const { id, mode } = useParams()
  const totalCount = useIngredientTagCount()
  const [ingredientTag, setIngredientTag] = useState({
    title: '',
    danger: 'unknown',
    isShowOnProduct: false
  })
  const [error, setError] = useState({ field: '', message: '' })
  const [loading, setLoading] = useState(true)
  const [showModal, setShowModal] = useState(false)

  const updateField = (field) => (value) => {
    setIngredientTag({ ...ingredientTag, [field]: value })
    if (error.message !== '') {
      setError({
        message: '',
        field: ''
      })
    }
  }

  const handleSubmit = async () => {
    if (mode === 'update') {
      return handleUpdate()
    }
    return handleCreate()
  }

  const handleCreate = async () => {
    try {
      api.ingredientTag.validate.validateIngredientTagData(ingredientTag)
      await api.ingredientTag.http.createIngredientTag(ingredientTag)
      await snackbar.showMessage('Created new ingredient tag', 'success')
      history.replace('/ingredient-tags')
    } catch (err) {
      if (err instanceof ValidateError) {
        setError(err)
        return snackbar.showMessage(err.message, 'error')
      }
      throw err
    }
  }

  const handleUpdate = async () => {
    try {
      api.ingredientTag.validate.validateIngredientTagData(ingredientTag)
      await api.ingredientTag.http.updateIngredientTag(ingredientTag)
      await snackbar.showMessage('Updated ingredient tag', 'success')
      history.push('/ingredient-tags')
    } catch (err) {
      if (err instanceof ValidateError) {
        setError(err)
        await snackbar.showMessage(err.message, 'error')
      } else if (err instanceof NotFoundError) {
        await snackbar.showMessage(
          "That ingredient tag doesn't exist",
          'error'
        )
      } else {
        throw err
      }
    }
  }

  const handleDelete = async () => {
    try {
      await api.ingredientTag.http.deleteIngredientTag(ingredientTag.id)
      await snackbar.showMessage('Deleted ingredient tag', 'success')
    } catch (err) {
      if (err instanceof NotFoundError) {
        await snackbar.showMessage(
          "That ingredient tag doesn't exist",
          'error'
        )
      } else {
        throw err
      }
    } finally {
      history.replace('/ingredient-tags')
    }
  }

  useEffect(() => {
    if (mode === 'update') {
      api.ingredientTag.http
        .getIngredientTagByID(id)
        .then((data) => {
          setIngredientTag(data)
          setLoading(false)
        })
        .catch((err) => {
          if (err instanceof NotFoundError) {
            history.replace('/not-found')
            return snackbar.showMessage(
              "There's no such ingredient tag",
              'error'
            )
          }
          throw err
        })
    } else if (totalCount >= 100) {
      snackbar
        .showMessage('You can only create at most 100 ingredient tag', 'error')
        .then(() => history.replace('/ingredient-tags'))
    } else {
      setLoading(false)
    }
  }, [id, mode, history, snackbar, totalCount])

  return (
    <div className={classes.root}>
      <ConfirmModal
        show={showModal}
        handleClose={() => setShowModal(false)}
        handleConfirm={handleDelete}
        message="Are you sure you want to delete this ingredient tag?"
      />
      <IngredientTagDetail
        mode={mode}
        loading={loading}
        ingredientTag={ingredientTag}
        handleSubmit={handleSubmit}
        handleDelete={() => setShowModal(true)}
        updateField={updateField}
        error={error}
      />
    </div>
  )
}

export default withSnackbar(IngredientTagSingle)
