import React, { useState, useEffect, useMemo } from "react";
import { makeStyles } from "@material-ui/styles";
import { useParams, useHistory } from "react-router-dom";
import { ProductDetail } from "./components";
import { withSnackbar } from "@/containers/SnackbarContainer";
import { NotFoundError, ValidateError } from "@/apis/error";
import api from "@/apis";
import ConfirmModal from "@/components/ConfirmModal";
import BraftEditor from "callmeduy-braft-editor";
import { prepareData } from "./utils";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
}));
// const acolIng = [96, 2138, 1399, 1904, 97, 1967, 2245, 1969, 3850, 2399];
// const flavIng = [2004, 1179, 1198, 1199, 1181, 1182, 3490, 3572, 914];
const ProductSingle = ({ snackbar }) => {
  const classes = useStyles();
  const history = useHistory();
  const { id, mode } = useParams();
  const [product, setProduct] = useState({
    id,
    name: "",
    product_category: null,
    brand: null,
    description: BraftEditor.createEditorState(""),
    price: 1,
    ingredients: [],
    url: [],
    thumbnail: null,
    isRX: null,
    isForPregnancy: null,
    isNoAncol: null,
    isNoFlavoring: null,
    isNoFungalAcne: null,
    IsNotForPegnancy: null,
    isHot: null,
    skin_problems: [],
    highlight_ingredients: [],
  });


  const [error, setError] = useState({ field: "", message: "" });
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const updateField = (field) => (value) => {
    setProduct({ ...product, [field]: value });
    if (error.message !== "") {
      setError({
        message: "",
        field: "",
      });
    }
  };

  React.useEffect(() => {
    if (product.ingredients.length > 0) {
      const _low = product.ingredients.filter(
        (v) => v.id && v.safetyLevel === 1
      ).length;
      const _med = product.ingredients.filter(
        (v) => v.id && v.safetyLevel === 2
      ).length;
      const _high = product.ingredients.filter(
        (v) => v.id && v.safetyLevel === 3
      ).length;
      if (
        _high > 0 ||
        (_high === 0 && _med === 1 && _low >= 4) ||
        (_high === 0 && _med === 2 && _low >= 3) ||
        (_high === 0 && _med > 2 && _low === 0) ||
        (_high === 0 && _med === 0 && _low >= 4) ||
        (_high === 0 &&
          _med === 0 &&
          _low >= 4 &&
          product.product_category &&
          product.product_category.id === 32)
      ) {
        setProduct({
          ...product,
          isForPregnancy: false,
          IsNotForPegnancy: false,
          ...checkFavorAndAncol(),
        });
      } else if (
        (_high === 0 && _med === 0 && _low === 0) ||
        (_high === 0 &&
          _med === 0 &&
          0 < _low &&
          _low < 4 &&
          product.product_category &&
          product.product_category.id === 32)
      ) {
        setProduct({
          ...product,
          isForPregnancy: true,
          IsNotForPegnancy: false,
          ...checkFavorAndAncol(),
        });
      } else {
        setProduct({
          ...product,
          isForPregnancy: false,
          IsNotForPegnancy: true,
          ...checkFavorAndAncol(),
        });
      }
    }
  }, [product.ingredients, product.product_category]);

  const checkFavorAndAncol = () => {
    let isNoAncol = !_.find(product.ingredients, { isAncol: true });
    let isNoFlavoring = !_.find(product.ingredients, { isFlavor: true });;
    return { isNoAncol, isNoFlavoring };
  };

  const handleSubmit = async () => {
    const submitData = prepareData(product);
    if (mode === "update") {
      return handleUpdate(submitData);
    }
    return handleCreate(submitData);
  };

  const handleCreate = async (_product) => {
    try {
      api.product.validate.validateProductData(_product);
      const res = await api.product.http.createProduct(_product);
      await snackbar.showMessage("Created new product", "success");
      history.replace(`/products/update/${res.id}`);
    } catch (err) {
      if (err instanceof ValidateError) {
        setError(err);
        return snackbar.showMessage(err.message, "error");
      }
      throw err;
    }
  };

  const handleUpdate = async (_product) => {
    try {
      api.product.validate.validateProductData(_product);
      await api.product.http.updateProduct(_product);
      await snackbar.showMessage("Updated product", "success");
      const currentRoute = history.location;
      history.replace("/");
      history.replace(currentRoute);
    } catch (err) {
      if (err instanceof ValidateError) {
        setError(err);
        await snackbar.showMessage(err.message, "error");
      } else if (err instanceof NotFoundError) {
        await snackbar.showMessage("That product doesn't exist", "error");
      } else {
        throw err;
      }
    }
  };

  const handleDelete = async () => {
    try {
      await api.product.http.deleteProduct(id);
      await snackbar.showMessage("Deleted product", "success");
    } catch (err) {
      if (err instanceof NotFoundError) {
        await snackbar.showMessage("That product doesn't exist", "error");
      } else {
        throw err;
      }
    } finally {
      history.replace("/products");
    }
  };
  useEffect(() => {
    if (mode === "create") {
      return;
    }
    setLoading(true);
    api.product.http
      .getProductById(id)
      .then((product) => {
        product.description = BraftEditor.createEditorState(
          product.description
        );
        if (product.skin_problems === null) {
          product.skin_problems = [];
        }
        if (product.highlight_ingredients === null) {
          product.highlight_ingredients = [];
        }
        setProduct(product);
        setLoading(false);
      })
      .catch((err) => {
        if (err instanceof NotFoundError) {
          history.replace("/not-found");
          return snackbar.showMessage("There's no such product", "error");
        }
        throw err;
      });
  }, [id, mode, history, snackbar]);

  return (
    <div className={classes.root}>
      <ConfirmModal
        show={showModal}
        handleClose={() => setShowModal(false)}
        handleConfirm={handleDelete}
        message="Are you sure you want to delete this product?"
      />
      <ProductDetail
        mode={mode}
        loading={loading}
        product={product}
        handleSubmit={handleSubmit}
        handleDelete={() => setShowModal(true)}
        updateField={updateField}
        error={error}
      />
    </div>
  );
};

export default withSnackbar(ProductSingle);
