import React from "react";
import PeopleIcon from "@material-ui/icons/People";
import ListIcon from "@material-ui/icons/List";
import OpacityIcon from "@material-ui/icons/Opacity";
import ShoppingBasketIcon from "@material-ui/icons/ShoppingBasket";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import BusinessIcon from "@material-ui/icons/Business";
import BrandingWatermarkIcon from "@material-ui/icons/BrandingWatermark";
import HomeIcon from "@material-ui/icons/Home";
import AppsIcon from "@material-ui/icons/Apps";
import InstagramIcon from "@material-ui/icons/Instagram";
import BookIcon from "@material-ui/icons/Book";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import NotificationsIcon from "@material-ui/icons/Notifications";

const pages = [
  {
    title: "Users",
    href: "/users",
    icon: <PeopleIcon />,
  },
  {
    title: "Layout",
    icon: <BrandingWatermarkIcon />,
    children: [
      {
        title: "Home Layout",
        href: "/layouts/home",
        icon: <HomeIcon />,
      },
      {
        title: "Other Layout",
        href: "/layouts/other",
        icon: <AppsIcon />,
      },
      {
        title: "Instagram Layout",
        href: "/layouts/insta",
        icon: <InstagramIcon />,
      },
    ],
  },
  {
    title: "Blog Posts",
    icon: <BookIcon />,
    children: [
      {
        title: "Post List",
        href: "/posts",
        icon: <ListIcon />,
      },
      {
        title: "Post Tags",
        href: "/post-tags",
        icon: <LocalOfferIcon />,
      },
    ],
  },
  {
    title: "Ingredients",
    icon: <OpacityIcon />,
    children: [
      {
        title: "Ingredient List",
        href: "/ingredients",
        icon: <ListIcon />,
      },
      {
        title: "Ingredient Pending",
        href: "/ingredientPending",
        icon: <AccessTimeIcon />,
      },
      {
        title: "Ingredient Tags",
        href: "/ingredient-tags",
        icon: <LocalOfferIcon />,
      },
    ],
  },
  {
    title: "Products",
    icon: <ShoppingBasketIcon />,
    children: [
      {
        title: "Product List",
        href: "/products",
        icon: <ListIcon />,
      },
      {
        title: "Products Categories",
        href: "/product-categories",
        icon: <AccountTreeIcon />,
      },
      {
        title: "Brands",
        href: "/brands",
        icon: <BusinessIcon />,
      },
    ],
  },
  {
    title: "Notifications",
    href: "/notifications",
    icon: <NotificationsIcon />,
  },
];

export default pages;
