import React from 'react'
import Router from '@/helpers/router'
import UserContainer from '@/containers/UserContainer/'
import { CircularProgress } from '@material-ui/core'

const withAuthSync = (Children) => {
  return class WithAuthSyncComponent extends React.Component {
    state = {
      isLoading: true
    };

    render () {
      if (this.state.isLoading) {
        return <CircularProgress />
      }
      return <Children {...this.props} />
    }

    handleRouter = async () => {
      const pathName = window.location.pathname
      try {
        const allowed = UserContainer.isAllowed(pathName)
        if (!allowed) {
          return Router.replace('/not-found')
        }
      } catch (e) {
        await UserContainer.logout()
        Router.replace('/login')
      } finally {
        this.setState({ isLoading: false })
      }
    };

    checkAuth = async () => {
      this.setState({ isLoading: true })
      const pathName = window.location.pathname
      const isAuth = await UserContainer.isAuth()
      if (isAuth) {
        if (pathName === '/login') {
          return Router.replace('/')
        }
        return this.handleRouter()
      } else {
        if (pathName === '/login') {
          return this.setState({ isLoading: false })
        }
        return Router.replace('/login')
      }
    };

    componentDidMount () {
      this.checkAuth()
    }

    componentDidUpdate (prevProps, prevState, snapshot) {
      if (prevProps !== this.props) {
        this.checkAuth()
      }
    }
  }
}

export default withAuthSync
