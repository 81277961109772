import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import { Button, Tooltip } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {},
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  searchInput: {
    marginRight: theme.spacing(1)
  }
}))

const UserToolbar = (props) => {
  const { className, disabled, handleBtnAddNewUser, ...rest } = props

  const classes = useStyles()

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <div className={classes.row}>
        <span className={classes.spacer} />
        <Tooltip title="You can create at most 100 users">
          <Button
            color="primary"
            variant="contained"
            disabled={disabled}
            onClick={handleBtnAddNewUser}
          >
            Add user
          </Button>
        </Tooltip>
      </div>
    </div>
  )
}

UserToolbar.propTypes = {
  className: PropTypes.string
}

export default UserToolbar
