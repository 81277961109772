import requester from '../requester'
import * as file from '../file/'
import {pullErrorMessage} from '../error'

export const getAllPosts = async (tag, {page, sort, limit = 10} = {page: 0}) => {
    const offset = page * limit
    const config = {params: {_start: offset, _limit: limit, _sort: sort}}
    const {data} = await requester()
        .get('posts/', config)
        .catch(pullErrorMessage)
    return data
}

export const searchPostByTitle = async ({title}) => {
    if (!title) {
        title = null
    }
    const body = {_limit: 50, title_contains: title}
    const {data} = await requester()
        .get('posts/', {params: body})
        .catch(pullErrorMessage)
    return data
}

export const countPost = async ({title}) => {
    if (!title) {
        title = null
    }
    const body = {title_contains: title}
    const {data} = await requester()
        .get('posts/count', {params: body})
        .catch(pullErrorMessage)
    return data
}

export const getPostBySlug = async (slug) => {
    const {data} = await requester()
        .get(`posts/${slug}`)
        .catch(pullErrorMessage)
    return data
}

export const createPost = async (postData) => {
    if (postData.thumbnail && postData.thumbnail instanceof File) {
        postData.thumbnail = await file.http.uploadOne(postData.thumbnail)
    }
    if (postData.level === null || postData.level === "") {
        postData.level = 1
    }
    if (postData.priority === null || postData.priority === "") {
        postData.priority = 1
    }
    const {data} = await requester()
        .post('posts/', postData)
        .catch(pullErrorMessage)
    return data
}

export const deletePost = async (id) => {
    const {data} = await requester()
        .delete(`posts/${id}`)
        .catch(pullErrorMessage)
    return data
}

export const updatePost = async (postData, changePublic) => {
    if (postData.thumbnail && postData.thumbnail instanceof File) {
        postData.thumbnail = await file.http.uploadOne(postData.thumbnail)
    }
    if (postData.published && changePublic) {
        postData.date_public = new Date().toISOString()
    }
    if (postData.level === null || postData.level === "") {
        postData.level = 1
    }
    if (postData.priority === null || postData.priority === "") {
        postData.priority = 1
    }

    const {data} = await requester()
        .put(`posts/${postData.id}`, postData)
        .catch(pullErrorMessage)
    return data
}
