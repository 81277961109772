const eligibleImage = (image) => image && image instanceof File

export const extractHomeImage = (data) => {
  const images = []
  data.main.forEach((e) => e && eligibleImage(e.image) && images.push(e.image))
  data.sub.forEach((e) => e && eligibleImage(e.image) && images.push(e.image))
  data.sponsors.forEach((e) => eligibleImage(e) && images.push(e))
  eligibleImage(data.promotion?.image) && images.push(data.promotion.image)
  return images
}

export const unExtractHomeImage = (data, imageList) => {
  let idx = 0
  data.main = data.main.map((e) => {
    if (eligibleImage(e?.image)) return { ...e, image: imageList[idx++] }
    return e
  })
  data.sub = data.sub.map((e) => {
    if (eligibleImage(e?.image)) return { ...e, image: imageList[idx++] }
    return e
  })
  data.sponsors = data.sponsors.map((e) => {
    if (eligibleImage(e)) return imageList[idx++]
    return e
  })
  if (eligibleImage(data.promotion?.image)) {
    data.promotion.image = imageList[idx++]
  }
}

export const extractOtherImage = (data) => {
  const images = []
  if (eligibleImage(data.avatar)) images.push(data.avatar)
  if (eligibleImage(data.topBanner)) images.push(data.topBanner)
  return images
}

export const unExtractOtherImage = (data, images) => {
  let idx = 0
  if (eligibleImage(data.avatar)) data.avatar = images[idx++]
  if (eligibleImage(data.topBanner)) data.topBanner = images[idx++]
}
