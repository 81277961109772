import React, { useState, useEffect } from "react";
import {
  Card,
  CardActions,
  CardContent,
  Button,
  makeStyles,
} from "@material-ui/core";
import MainBannerList from "./components/MainBannerList";
import SubBannerList from "./components/SubBannerList";
import SponsorList from "./components/SponsorList";
import Promotion from "./components/Promotion";
import FeatureProduct from "./components/FeatureProduct";
import {
  createDefaultBannerArray,
  createArray,
  nullifyEmptyBannerArray,
  unNullifyBannerArray,
  createDefaultBanner,
  unNullifyBanner,
  nullifyEmptyBanner,
  formatFeatureProduct,
} from "./utils";
import api from "@/apis";
import { withSnackbar } from "@/containers/SnackbarContainer";
import { HTTPError } from "@/apis/error";
import SaveButton from "@/components/SaveButton";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(4),
  },
  paper: {
    margin: theme.spacing(3),
    padding: theme.spacing(1),
  },
}));

const HomeLayoutSetting = ({ snackbar }) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [mainBanners, setMainBanners] = useState(createDefaultBannerArray(3));
  const [subBanners, setSubBanners] = useState(createDefaultBannerArray(3));
  const [sponsors, setSponsors] = useState(createArray(6, null));
  const [promotion, setPromotion] = useState(createDefaultBanner());
  const [featureProduct, setFeatureProduct] = useState([]);

  const setData = (data) => {
    setSubBanners(unNullifyBannerArray(data.sub));
    setMainBanners(unNullifyBannerArray(data.main));
    setPromotion(unNullifyBanner(data.promotion));
    setSponsors(data.sponsors);
    setFeatureProduct(data?.featureProduct || [{}]);
  };
  const onSubmit = async () => {
    setLoading(true);
    try {
      const res = await api.layout.http.updateHomeLayout({
        main: nullifyEmptyBannerArray(mainBanners),
        sub: nullifyEmptyBannerArray(subBanners),
        promotion: nullifyEmptyBanner(promotion),
        sponsors,
        featureProduct: formatFeatureProduct(featureProduct),
      });
      await snackbar.showMessage("Update layout successfully", "success");
      setData(res);
    } catch (err) {
      if (err instanceof HTTPError) {
        await snackbar.showMessage(err.message, "error");
      }
      throw err;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(true);
    api.layout.http
      .getHomeLayout()
      .then(setData)
      .then(() => setLoading(false));
  }, []);
  return (
    <div className={classes.root}>
      <Card className={classes.paper}>
        <CardContent>
          <MainBannerList
            banners={mainBanners}
            loading={loading}
            setBanners={(val) => {
              setMainBanners(val);
            }}
          />
          <SubBannerList
            banners={subBanners}
            setBanners={setSubBanners}
            loading={loading}
          />
          <Promotion
            loading={loading}
            setBanner={setPromotion}
            banner={promotion}
          />
          <SponsorList
            sponsors={sponsors}
            setSponsors={setSponsors}
            loading={loading}
          />
          <FeatureProduct
            loading={loading}
            featureProduct={featureProduct}
            setFeatureProduct={setFeatureProduct}
          />
        </CardContent>
        <CardActions>
          <Button
            variant="contained"
            color="primary"
            onClick={onSubmit}
            disabled={loading}
          >
            Save
          </Button>
        </CardActions>
        <SaveButton onSave={onSubmit} />
      </Card>
    </div>
  );
};

export default withSnackbar(HomeLayoutSetting);
